import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getCompantId } from "../../utils";
import { key } from "../../config";
// import { verifyToken } from "../../utils";

const ProtectRoute = ({ children, per, operationFlag }) => {
  const { userPermissions, userRole } = useSelector((state) => state.Auth);

  const checkPermission = () => {
    // hide create and update pages from manager
    // if (userRole === key.defaultRoles?.Manager && operationFlag) return false

    //hide pages based on permissions

    if (getCompantId() && per) {
      return userPermissions?.includes(per);
    } else {
      return userPermissions?.includes(per);
    }
  };

  return checkPermission() ? children : <Navigate to="/forb" />;
};

export default ProtectRoute;

// const location = useLocation(); // <-- get current location being accessed
// const { Authenticate, status } = useSelector((state) => state.Auth);
//
// return !Authenticate && status !== "loading" ? (
// <Navigate
// to="/login"
// state={{ from: location }} // <-- pass in route state
// replace
// />
// ) : Authenticate && status !== "loading" && location.pathname === "/login" ? (
// <Navigate to="/" />
// ) : (
// <Outlet />
// );
