import { configureStore } from "@reduxjs/toolkit";
import inspectionPlanReducer from "./features/InspectionPlanSlice";
import BuildAreaReducer from "./features/BuildAreaSlice";
import stepSliceReducer from "./features/StepSlice";
import userPermissionReducer from "./features/PermissionsSlice";
import QCPointSliceReducer from "./features/QCPointSlice";
import AppUtilsReducer from "./features/AppUtilsSlice";
import plantReducer from "./features/PlantSlice";
import MaterialMasterReducer from "./features/MaterialMasterSlice";
import WorkStationReducer from "./features/WorkStaionSlice";
import AuthReducer from "./features/AuthSlice";
import SubscriptionPlanReducer from "./features/SubscriptionPlanSlice";
import PlanReducer from "./features/PlansSlice";
import RoleSlice from "./features/RoleSlice";
import MaterialNoConfigSlice from "./features/MaterialNoConfigSlice";
import InspectionLotSlice from "./features/InspectionLotSlice";
import UsersSlice from "./features/UsersSlice";
import StripeSlice from "./features/StripeSlice";
import CompanyManagementSlice from "./features/CompanyManagementSlice";
import RejectedReasonsSlice from "./features/RejectedReasonsSlice";
import GroupSlice from "./features/GroupSlice";
import MeasurementUnitSlice from "./features/MeasurementUnitSlice";
import SensorTypeSlice from "./features/SensorTypeSlice";
import SensorSlice from "./features/SensorSlice";
import WorkFlowFormBuilderSlice from "./features/WorkFlowFormBuilderSlice";
import RegistrationConsentSlice from "./features/RegistrationConsentSlice";
import WorkFlowGroupSlice from "./features/WorkFlowGroupSlice";
import FormSlice from "./features/FormSlice";
import CategorySlice from "./features/CategorySlice";
import AdminLogsSlice from "./features/AdminLogsSlice";
import GeneralFormSlice from "./features/GeneralFormSlice";
import InspectionLotReportSlice from "./features/InspectionLotReportSlice";
import inspectionLotReportReducer from "./features/InspectionLotReportSlice";
import GraphsSlice from "./features/GraphsSlice";
import ReportSlice from "./features/ReportSlice";
import TransactionHistorySlice from "./features/TransactionHistorySlice";
import CompanyRolesSlice from "./features/CompanyRolesSlice";
import CompanyPlansSlice from "./features/CompanyPlansSlice";
import FileSlice from "./features/FileSlice";
import CommonSlice from "./features/CommonSlice";
import ReleaseInspectionLogSlice from "./features/ReleaseInspectionLogSlice";

const store = configureStore({
  reducer: {
    AdminLogs: AdminLogsSlice,
    AppUtils: AppUtilsReducer,
    Auth: AuthReducer,
    BuildArea: BuildAreaReducer,
    CompanyManagement: CompanyManagementSlice,
    CompanyRoles: CompanyRolesSlice,
    Catogries: CategorySlice,
    CompanyPlans: CompanyPlansSlice,
    Form: FormSlice,
    FixedFieldsFile: FileSlice,
    Groups: GroupSlice,
    GeneralForm: GeneralFormSlice,
    Graphs: GraphsSlice,
    InspectionPlan: inspectionPlanReducer,
    InspectionLotReport: InspectionLotReportSlice,
    inspectionLotReport: inspectionLotReportReducer,
    InspectionLot: InspectionLotSlice,
    MaterialNoConfig: MaterialNoConfigSlice,
    MaterialMaster: MaterialMasterReducer,
    Measurement: MeasurementUnitSlice,
    Plan: PlanReducer,
    Plant: plantReducer,
    QCPoint: QCPointSliceReducer,
    RegistrationConsent: RegistrationConsentSlice,
    RejectedReasons: RejectedReasonsSlice,
    ReleaseInspectionLog: ReleaseInspectionLogSlice,
    Reports: ReportSlice,
    Step: stepSliceReducer,
    Stripe: StripeSlice,
    SensorType: SensorTypeSlice,
    Sensors: SensorSlice,
    SubscriptionPlan: SubscriptionPlanReducer,
    TransactionHistory: TransactionHistorySlice,
    userRoles: RoleSlice,
    Users: UsersSlice,
    UserPermission: userPermissionReducer,
    WorkStation: WorkStationReducer,
    WorkBuilder: WorkFlowFormBuilderSlice,
    WorkFlowGroup: WorkFlowGroupSlice,
    CommonOperations: CommonSlice,
  },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
