import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowUpgradePopup: false,
  paginations: {},
};

const CommonSlice = createSlice({
  name: "Common Operations",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase("SHOW_UPGRADE_TO_STANDARD_POPUP", (state, action) => {
        state.isShowUpgradePopup = true;
      })
      .addCase("HIDE_UPGRADE_TO_STANDARD_POPUP", (state, action) => {
        state.isShowUpgradePopup = false;
      })
      .addCase("CHANGE_PER_PAGE_RECORD", (state, action) => {
        state.paginations = {
          ...state.paginations,
          [action?.payload?.page]: action?.payload?.perPageRecord,
        };
      });
  },
});

export default CommonSlice.reducer;
