import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className='w-100'>
          <br />
          <br />

          <h2 className='text-center mt-5'>Oops App Crashed!</h2>
          <br />
          <br />
          <div className='d-flex justify-content-center'>
            <button
              className='btn btn-primary mx-auto'
              style={{ width: '100px' }}
              onClick={() => window?.location.reload()}
            >Refresh</button>

          </div>

        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
