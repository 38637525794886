import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./expired.css";
import moment from "moment/moment";
import { key } from "../../../../config";

const ExpiredMessage = () => {
  const { currentUser } = useSelector((state) => state.Auth);
  const {
    CompanyManagement: { company },
  } = useSelector((state) => state);
  let arr;
  if (company?.plans?.length > 0) {
    arr = [...company?.plans]?.reverse();
  }
  const navigate = useNavigate();
  React.useEffect(() => {
    let todayDate = moment();
    let expiryDate = moment(arr?.[0]?.renewSubscriptionAt, "DD-MM-YYYY");
    if (expiryDate < todayDate) {
      navigate("/plan");
    }

    return () => {};
  }, []);

  if (currentUser?.userRole === key.defaultRoles?.Admin) {
    return (
      <div className="renewBody">
        <div className="renewCard">
          <h1>Uh-oh, your plan has expired!</h1>
          <p>
            We're sorry, but it looks like your subscription has expired. You'll
            need to renew your subscription to continue using our services.
            Don't worry, renewing is easy and you can be back up and running in
            no time!
          </p>
          <Link to="/renew-plan" className="renewBtn">
            Renew Now
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className="renewBody">
        <div className="renewCard">
          <h1>Uh-oh, your plan has expired!</h1>
          <p>
            We're sorry, but it looks like your subscription has expired. You'll
            need to renew your subscription to continue using our services. Ask
            your Org_Admin to renew your subscription.
          </p>
        </div>
      </div>
    );
  }
};

export default ExpiredMessage;
