import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { BiEditAlt } from "react-icons/bi";
import { MdTableView } from "react-icons/md";

import TableLoading from "../../../common/SkeltonLoading/TableLoading";
import { useDispatch, useSelector } from "react-redux";
import MainBreadcrum from "../../../layout/MainBreadcrum";
import {
  deleteCategory,
  getAllCatogries,
} from "../../../../Redux/features/CategorySlice";
import DataTableWithExcel from "../../../common/DataTableWithExcel";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeletePopup from "../../../common/DeletePopup";
import IsAuthorize from "../../../common/IsAuthorize";
import EditIcon from "@mui/icons-material/Edit";
import UpdateApprovalCategories from "./UpdateApprovalCategories";
import { Button } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const ApprovalCategories = () => {
  // const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [getId, setId] = useState(null);
  const [isEditPopup, setIsEditPopup] = useState(false);
  const [editData, setEditData] = useState({});
  const [count, setCount] = useState(0);
  const [classVal,setClassVal] = useState("");

  const [approvalCategories, setApprovalCategories] = useState([]);

  const dispatch = useDispatch();

  const {
    Catogries: { allCategories, status },
  } = useSelector((state) => state);
  // const { permissions } = useSelector((state) => state.Auth);

  useEffect(() => {
    dispatch(getAllCatogries());
    //for handling parent and child category creation
    dispatch({ type: "CLEAR_PARENT_CATEGORY_ID" });
  }, [dispatch, count]);

  const ref = useRef();
  const makeFullscreen = () => {
    let elem = ref?.current;
    console.log("hello",elem);
    
    if (elem?.requestFullscreen) {
        elem?.requestFullscreen();
        setClassVal("card-body full-screen")
    } else if (elem?.webkitRequestFullscreen) {
        /* Safari */
        elem?.webkitRequestFullscreen();
        setClassVal("card-body full-screen")
    } else if (elem?.msRequestFullscreen) {
        /* IE11 */
        elem?.msRequestFullscreen();
        setClassVal("card-body full-screen")
    }
    else
    {
      setClassVal("card-body");
    }
  }

  useEffect(() => {
    setApprovalCategories(allCategories?.filter((cat) => cat.parentId == null));
  }, [allCategories]);

  const categoriesData = React.useMemo(
    () => approvalCategories,
    [approvalCategories]
  );
  const columnsData = React.useMemo(
    () => [
      {
        Header: "ID",
        disableFilters: true,
        accessor: "id",
      },
      {
        Header: "Name",
        disableFilters: true,
        accessor: "name",
      },
      {
        Header: "Created At",
        disableFilters: true,
        accessor: "createdAt",
      },
      {
        Header: "Updated At",
        disableFilters: true,
        accessor: "updatedAt",
      },
      {
        id: "Action",
        Header: "Action",
        disableFilters: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center justify-content-center">
            <IsAuthorize>
              <span
                className="dropdown-item hover-danger p-4 text-center me-4 rounded pointer"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => {
                  handleOpen();
                  setId(row.original.id);
                }}
              >
                <RiDeleteBin6Line size={"1rem"} />
              </span>
            </IsAuthorize>
          </div>
        ),
      },
      {
        id: "Edit",
        Header: "Action",
        disableFilters: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center justify-content-center">
            <Link
              className="dropdown-item hover-blue p-4 text-center me-4 rounded pointer"
              to={`/approval-categories/child/${row.original.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Table View"
              onClick={() =>
                dispatch({
                  type: "SET_PARENT_CATEGORY_ID",
                  payload: row?.original?.id,
                })
              }
            >
              <MdTableView size={"1rem"} />
            </Link>
            <IsAuthorize>
              <span
                className="dropdown-item hover-danger p-4 text-center me-4 rounded pointer"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => {
                  // handleOpen();

                  setEditData(row?.original);

                  setIsEditPopup(true);

                  // setId({
                  //   payload: row?.original?.id,
                  //   payload1: row?.original?.id,
                  // });
                }}
              >
                <EditIcon size={"1rem"} />
              </span>
            </IsAuthorize>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="row">
        <MainBreadcrum
          nav={true}
          label="create"
          path="/approval-categories/create"
          name="Approval Category"
          subName=""
          links={[
            { path: "", title: "Forms and Flows", isParentLabel: true },
            { path: "", title: "Approval Category", activeLink: true },
          ]}
        />
        {/* <div className="col-4 align-self-center text-end">
          <Link to="/approval-categories/create">
            <button type="button" className="btn btn-primary">
              Create
            </button>
          </Link>
        </div> */}
      </div>
      <div className="mt-3">
        <div className="mb-5">
          {status === "loading" ? (
            <>
              <TableLoading />
            </>
          ) : (
            <div className="card">
                <Button
                  sx={{
                    width: "1px !important",
                    height: "1% !important",
                    marginLeft: "93% !important",
                    marginBottom: "1% !important",
                    marginTop: "1% !important",
                    color: "#0d488b !important",
                    // backgroundColor: "#0d488b !important",
                    minWidth : "30px"
                  }}
                  onClick={makeFullscreen}
                  >
                    <OpenInFullIcon />
              </Button>
                <div ref={ref} className={ref ? classVal : "card-body"}>
                <div className="">
                    <DataTableWithExcel
                      columns={columnsData}
                      data={categoriesData}
                      status={status}
                      pageName="approvalCategories"
                    />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <DeletePopup
        handleClose={handleClose}
        open={open}
        deleteFun={deleteCategory}
        id={getId}
      />
      <UpdateApprovalCategories
        editData={editData}
        mainCat={"mainCat"}
        isEditPopup={isEditPopup}
        closePopup={() => setIsEditPopup(false)}
        dispatch={dispatch}
        setCount={setCount}
      />
    </>
  );
};

export default ApprovalCategories;
