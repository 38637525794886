// import { useLocation } from "react-router-dom";
import AppRoutes from "./routes";

// import StaticSiteRoutes from "./site/routes";

function App() {
  // const { pathname } = useLocation();

  // if (pathname.includes("/register")) {
  //   return <StaticSiteRoutes />;
  // } else {
    return <AppRoutes />;
  // }
}

export default App;
