import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API } from "../../config";
import { SHOW_ERROR } from "../../utils/toastMessages";

const initialState = {
  lotReport: [],
  stepsReport: [],
  qcPointReport: [],
  status: null,
  error: null,
};

export const getInspectionLotReports = createAsyncThunk(
  "get/inspectionLotReports",
  async () => {
    try {
      let status = "loading";
      const response = await API.inspectionLotReport.getInspectionLotReport();
      status = "end";
      console.log("acpauscu0aca-8a-8c-as8c-", response.data);
      return response.data.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const inspectionLotReportSlice = createSlice({
  name: "inspectionLotReport",
  initialState: {
    data: [],
    status: "idle", // Initial status is set to 'idle'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInspectionLotReports.pending, (state) => {
        state.status = "loading"; // Set status to 'loading' when the API call is pending
      })
      .addCase(getInspectionLotReports.fulfilled, (state, action) => {
        state.status = "end"; // Set status to 'end' when the API call is fulfilled
        state.data = action.payload; // Update the data with the fetched data
      })
      .addCase(getInspectionLotReports.rejected, (state, action) => {
        state.status = "error"; // Set status to 'error' when the API call is rejected
        state.error = action.error.message; // Update the error state with the error message
      });
  },
});
export const getStepsReports = createAsyncThunk(
  "get/stepsReports",
  async (data) => {
    try {
      // // //console.log(flag);
      const response = await API.inspectionLotReport.getStepsReport(
        data.id,
        data.flag
      );
      if (response.data.success) {
        // //console.log("steps passes !", response.data.success);
        return { data: response.data.data };
      }
    } catch (error) {
      // //console.log("steps not  passes !", error);

      return { data: [], error };
    }
  }
);

export const getQcPointReports = createAsyncThunk(
  "get/qcPointReport",
  async ({ id, stepId }) => {
    try {
      const response = await API.inspectionLotReport.getQcPointReport(
        id,
        stepId
      );

      const { success, data } = response.data;
      // //console.log("from success => ", response);

      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      const { status } = error.response;
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else {
        SHOW_ERROR(true, "Something Went Wrong ! :( ");
      }
      return {
        success: false,
        code: status,
        data: [],
      };
    }
  }
);

const InspectionLotReportSlice = createSlice({
  name: "Inspection Lot Report",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      //Inspection Lot Reports
      .addCase(getInspectionLotReports.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getInspectionLotReports.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.lotReport = action.payload;
      })
      .addCase(getInspectionLotReports.rejected, (state, action) => {
        state.status = "failed";
      })
      // Steps Reports
      .addCase(getStepsReports.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getStepsReports.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stepsReport = action.payload.data;
      })
      .addCase(getStepsReports.rejected, (state, action) => {
        state.status = "failed";
      })
      //QC Point Reports
      .addCase(getQcPointReports.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getQcPointReports.fulfilled, (state, action) => {
        const { success, code, data } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.qcPointReport = data;
        } else {
          state.status = "fail";
          state.qcPointReport = [];
          state.error = code;
        }
      })
      .addCase(getQcPointReports.rejected, (state, action) => {
        state.status = "failed";
        const { success, code, data } = action.payload;
        state.error = code;
        state.qcPointReport = data;
      });
  },
});

export default InspectionLotReportSlice.reducer;
