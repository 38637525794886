import { lazy } from "react";
import ProtectRoute from "../components/common/ProtectRoute";
import ApprovalCategories from "../components/Pages/company/approvalCategories/ApprovalCategories";
import CreateApprovalCategories from "../components/Pages/company/approvalCategories/CreateApprovalCategories";
import ApprovalCategoriesChild from "../components/Pages/company/approvalCategories/ApprovalCategoriesChild";
import Playground from "../components/Pages/Playground";
import { key } from "../config";
import AddCard from "../site/pages/companyPlans/AddCard";
import ExpiredMessage from "../components/Pages/company/planExpired/ExpiredMessage";
import RenewPlan from "../components/Pages/company/planExpired/RenewPlan";
import Plans from "../components/Pages/company/Plans/Plans";
import { Navigate } from "react-router-dom";
import RestrictedAcess from "../components/Forbiden/RestrictedAcess";
import USerFilledForm from "../components/UserFilledForms/USerFilledForm";
import EnhancedTable from "../components/ExampleFolder/EnhancedTable";
import FEMAreport from "../components/Pages/FormBank/FEMAReport/FEMAreport";
import FiveWhyReport from "../components/Pages/FormBank/FEMAReport/FiveWhyReport";
import Dashboard from "../components/Pages/company/reports/graphs/Dashboard";
import InspectionLog from "../components/Pages/company/ReleaseInspectionLog/InspectionLog";
const MainLyout = lazy(() => import("../components/layout/MainLyout"));
const AuthPage = lazy(() => import("../components/Auth/AuthUpdated"));

const ViewMaterialPage = lazy(() =>
  import("../components/Pages/company/Material/ViewMaterial")
);
const InspectionPlanPage = lazy(() =>
  import("../components/Pages/company/InspectionPlan/InspectionPlan")
);

const ViewCompanyPage = lazy(() =>
  import("../components/Pages/saas/CompanyManagement/ViewComapny")
);

const ViewInspectionLotPage = lazy(() =>
  import("../components/Pages/company/InspectionLot/ViewInspectionLot")
);

const ViewWorkStationPage = lazy(() =>
  import("../components/Pages/company/Workstation/ViewWorkStationUpdated")
);

const SensorTypePage = lazy(() =>
  import("../components/Pages/company/Sensors/SensorType/SensorType")
);

const CreateSensorTypePage = lazy(() =>
  import("../components/Pages/company/Sensors/SensorType/CreateSensorType")
);

const ViewSensorTypePage = lazy(() =>
  import("../components/Pages/company/Sensors/SensorType/ViewSensorType")
);

const ViewSensorPage = lazy(() =>
  import("../components/Pages/company/Sensors/ViewSensor")
);
const DashboardPage = lazy(() => import("../components/Pages/Playground"));
const NoMatchPage = lazy(() => import("../components/common/Error404"));
const ForbPage = lazy(() => import("../components/common/Error403"));
const UnAuthPage = lazy(() => import("../components/common/Error401"));
const ForgotPasswordPage = lazy(() =>
  import("../components/Auth/ForgotPasswordUpdated")
);
const CreateNewPass = lazy(() => import("../components/Auth/CreateNewPass"));
const RegisterPage = lazy(() => import("../components/Auth/RegisterUpdated"));
const CompanyRegistrationForm = lazy(() =>
  import("../site/pages/companyPlans/CompanyRegistrationForm")
);
const CompanyPlansListPage = lazy(() =>
  import("../site/pages/companyPlans/CompanyPlansList")
);
const CompanyManagmentPage = lazy(() =>
  import("../components/Pages/saas/CompanyManagement/CompanyManagment")
);
const CreateComapnyPage = lazy(() =>
  import("../components/Pages/saas/CompanyManagement/CreateComapny")
);
const CreateSubscriptionPage = lazy(() =>
  import("../components/Pages/saas/subscription/CreateSubscription")
);
const EditSubscriptionPage = lazy(() =>
  import("../components/Pages/saas/subscription/EditSubscription")
);
const AdminLogPage = lazy(() =>
  import("../components/Pages/company/adminLogs/AdminLog")
);

const ManageSubscriptionPage = lazy(() =>
  import("../components/Pages/saas/subscription/ManageSubscription")
);
const TransactionPage = lazy(() =>
  import("../components/Pages/saas/transaction/Transaction")
);
const ConsentPagePage = lazy(() =>
  import("../components/Pages/saas/registrationConsent/Consent")
);
const AccountInfoPage = lazy(() =>
  import("../components/Pages/company/AccountInfo")
);
const WorkistationPage = lazy(() =>
  import("../components/Pages/company/Workstation/Workistation")
);
const CreateWorkstationPage = lazy(() =>
  import("../components/Pages/company/Workstation/CreateWorkstation")
);
const PlantPage = lazy(() => import("../components/Pages/company/Plant/Plant"));

const GraphsPage = lazy(() =>
  import("../components/Pages/company/reports/graphs")
);

const CreatePlantPage = lazy(() =>
  import("../components/Pages/company/Plant/CreatePlant")
);
const UpdatePlantPage = lazy(() =>
  import("../components/Pages/company/Plant/UpdatePlant")
);

const UserPage = lazy(() => import("../components/Pages/company/User/User"));
const CreateUserPage = lazy(() =>
  import("../components/Pages/company/User/CreateUser")
);
const ViewUserPage = lazy(() =>
  import("../components/Pages/company/User/ViewUser")
);
const MaterialNoConfigPage = lazy(() =>
  import("../components/Pages/company/MaterialNoConfig/MaterialNoConfig")
);
const NoConfigCreatePage = lazy(() =>
  import("../components/Pages/company/MaterialNoConfig/NoConfigCreate")
);
const NoConfigUpdatePage = lazy(() =>
  import("../components/Pages/company/MaterialNoConfig/NoConfigUpdate")
);
const AdminRoleManagment = lazy(() =>
  import("../components/Pages/saas/AdminRoleManagment/AdminRoleManagment")
);
const CreateAdminRole = lazy(() =>
  import("../components/Pages/saas/AdminRoleManagment/CreateAdminRole")
);

const MaterialPage = lazy(() =>
  import("../components/Pages/company/Material/Material")
);
const CreateMaterialPage = lazy(() =>
  import("../components/Pages/company/Material/CreateMaterial")
);
const RejectionReasonPage = lazy(() =>
  import("../components/Pages/company/Rejection/RejectionReason")
);
const CreateRejectionReasonPage = lazy(() =>
  import("../components/Pages/company/Rejection/CreateRejectionReason")
);
const CreateMeasurementUnitsPage = lazy(() =>
  import("../components/Pages/company/MeasurementUnits/CreateMeasurementUnits")
);
const MeasurementUnitsPage = lazy(() =>
  import("../components/Pages/company/MeasurementUnits/MeasurementUnits")
);
const CreateInspectionPlanPage = lazy(() =>
  import("../components/Pages/company/InspectionPlan/CreateInspectionPlan")
);
const SensorConfigurationsPage = lazy(() =>
  import("../components/Pages/company/Sensors/Sensors")
);
const CreateSensorPage = lazy(() =>
  import("../components/Pages/company/Sensors/CreateSensor")
);
const InspectionPlanLotPage = lazy(() =>
  import("../components/Pages/company/InspectionLot/InspectionLot")
);
const CreateInspectionPlanLotPage = lazy(() =>
  import("../components/Pages/company/InspectionLot/CreateInspectionLot")
);
const BuildAreaPage = lazy(() =>
  import("../components/Pages/company/BuildArea/BuildArea")
);
const ViewBuildAreaPage = lazy(() =>
  import("../components/Pages/company/BuildArea/ViewBuildArea")
);
const UpdateMeasurementUnitPage = lazy(() =>
  import("../components/Pages/company/MeasurementUnits/UpdateMeasurementUnit")
);
const ViewRejectionReasonPage = lazy(() =>
  import("../components/Pages/company/Rejection/ViewRejectionReasonChild")
);

const ViewRejectionReason = lazy(() =>
  import("../components/Pages/company/Rejection/ViewRejectionReason")
);

const RejectionReasonChildPage = lazy(() =>
  import("../components/Pages/company/Rejection/RejectionReasonChilds")
);

const GroupCreatePage = lazy(() =>
  import("../components/WorkFlow/groupCreate")
);

const GroupListingPage = lazy(() =>
  import("../components/WorkFlow/GroupListing")
);

const MyGroupListingPage = lazy(() =>
  import("../components/WorkFlow/MyGroups")
);

// const EditGroupPage = lazy(() =>
//   import("../components/WorkFlow/editGroup")
// );
const EditGroupPage = lazy(() => import("../components/WorkFlow/EditGroup"));
const FormBuilderPage = lazy(() =>
  import("../components/Pages/FormBank/AllFormsUpdated")
);
const ShowFormDetailPage = lazy(() =>
  import("../components/Pages/FormBank/ShowFormDetail")
);
const FixedFormDetailPage = lazy(() =>
  import("../components/Pages/FormBank/FixedFormTrial")
);
const UpdateFormPage = lazy(() =>
  import("../components/Pages/FormBank/UpdateFormUpdatedVersion")
);
const CreatGroupPage = lazy(() => import("../components/WorkFlow/CreatGroup"));

const EditFormBuilderPage = lazy(() =>
  import("../components/Pages/FormBank/EditFormBuilder")
);
const CreateFormPage = lazy(() =>
  import("../components/Pages/FormBank/FormCreator")
);
const FormInitiatorPage = lazy(() =>
  import("../components/Pages/company/FutureUseForms/FormInitiator")
);
const CustomFormCrudPage = lazy(() =>
  import("../components/Pages/company/FutureUseForms/CustomFormCrud")
);
const MainInspectionPlanTreePage = lazy(() =>
  import("../components/Pages/company/InspectionPlan/MainInspectionPlanTree")
);
// const StepPage = lazy(() =>
//   import("../components/Pages/company/InspectionPlan/forms/Step")
// );
const FixedFieldsPage = lazy(() =>
  import("../components/Pages/company/FutureUseForms/FixedFields")
);
const ViewFixedFormPage = lazy(() =>
  import("../components/Pages/company/FutureUseForms/ViewFixedForm")
);
const ViewCustomFormPage = lazy(() =>
  import("../components/Pages/company/FutureUseForms/ViewCustomForm")
);
const CompReg = lazy(() => import("../site/pages/modal/companyReg.jsx"));
const PaymentMethodPage = lazy(() =>
  // import('../components/Pages/company/FutureUseForms/PaymentMethod')
  import("../site/pages/companyPlans/PaymentMethod")
);
const PlanSubscriptionPage = lazy(() =>
  import("../components/Pages/company/FutureUseForms/PlanSubscription")
);
const PlanUpgradePage = lazy(() =>
  import("../components/Pages/company/FutureUseForms/Plan-upgrade")
);
const AssignAdminPermissionsPage = lazy(() =>
  import(
    "../components/Pages/saas/AdminRoleManagment/Permissions/AssignAdminPermissions"
  )
);
const ViewAdminRole = lazy(() =>
  import("../components/Pages/saas/AdminRoleManagment/ViewAdminRole")
);
const FMEAPage = lazy(() =>
  import("../components/Pages/company/FutureUseForms/FMEA")
);
const FemaReportPage = lazy(() =>
  import("../components/Pages/company/reports/Fema")
);
const GroupPage = lazy(() => import("../components/Pages/company/Group/Group"));
const CreateGroupsPage = lazy(() =>
  import("../components/Pages/company/Group/CreateGroups")
);
const ViewGroupPage = lazy(() =>
  import("../components/Pages/company/Group/ViewGroup")
);

const FishbonePage = lazy(() =>
  import("../components/Pages/company/reports/fishbone")
);
const FiveWhy = lazy(() => import("../components/Pages/company/reports/5Y"));
const InspectionLotReport = lazy(() =>
  import("../components/Pages/company/reports/InspectionLotReport")
);

const DataEntryFormPage = lazy(() =>
  import("../components/Pages/company/reports/generalForm/DataEntryForm")
);

const GeneralFormFilledByPage = lazy(() =>
  import("../components/Pages/company/reports/generalForm/GeneralFormFilledBy")
);
const CompanyRole = lazy(() =>
  import("../components/Pages/company/RoleManagment/CompanyRoleManagment")
);
const ViewCompanyRole = lazy(() =>
  import("../components/Pages/company/RoleManagment/ViewCompanyRole")
);
const ViewCompanyPermissions = lazy(() =>
  import(
    "../components/Pages/company/RoleManagment/Permissions/AssignCompanyPermissions"
  )
);
const CreateCompanyRole = lazy(() =>
  import("../components/Pages/company/RoleManagment/CreateCompanyRole")
);
const StepReport = lazy(() =>
  import("../components/Pages/company/reports/StepsReport")
);
const QcReport = lazy(() =>
  import("../components/Pages/company/reports/QcPointReport")
);
const CompanyPlans = lazy(() =>
  import("../components/Pages/saas/CompanyPlans/CompanyPlans")
);

let PublicRoutes = [
  {
    exact: true,
    path: "/",
    element: <AuthPage />,
  },
  {
    exact: true,
    path: "/login",
    element: <AuthPage />,
  },
  {
    exact: true,
    path: "/compReg",
    element: <CompReg />,
  },
  // { path: '/regPage', element: <RegisterPage /> },
  {
    path: "/register",
    exact: true,
    element: <CompanyRegistrationForm />,
  },
  {
    path: "/register/standard",
    exact: true,
    element: <CompanyRegistrationForm />,
  },
  {
    exact: true,
    path: "/register/plans",
    element: <CompanyPlansListPage />,
  },
  {
    exact: true,

    path: "/forgot",
    element: <ForgotPasswordPage />,
  },
  {
    exact: true,

    path: "/newPass",
    element: <CreateNewPass />,
  },
  // { path: '*',  element:<Navigate to="/login" replace /> },

  // {
  //   path: "/plan-subscription",
  //   element: (
  //     // <ProtectRoute>
  //     <PlanSubscriptionPage />
  //     // </ProtectRoute>
  //   ),
  // },
  // { path: "*", element: <NoMatchPage /> },
];

let PrivateRoutes = [
  {
    path: "/newPass",
    element: <CreateNewPass />,
  },
  {
    path: "/",
    element: <MainLyout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "newdash",
        element: <GraphsPage />,
      },
      {
        path: "company",
        element: <CompanyManagmentPage />,
      },
      {
        path: "company/create",
        element: <CreateComapnyPage />,
      },
      {
        path: "company/:Id",
        element: <ViewCompanyPage />,
      },
      {
        path: "subscription",
        element: (
          // <ProtectRoute per={'Plan-View'}>
          <ManageSubscriptionPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "subscription/create",
        element: (
          // <ProtectRoute>
          <CreateSubscriptionPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "edit-subscription/:id",
        element: (
          // <ProtectRoute>
          <EditSubscriptionPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "transaction",
        element: <TransactionPage />,
      },
      {
        path: "consent",
        element: <ConsentPagePage />,
      },

      {
        path: "admin-log",
        element: (
          // <ProtectRoute>
          <AdminLogPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "adminRoles",
        element: <AdminRoleManagment />,
      },
      {
        path: "adminRoles/create",
        element: <CreateAdminRole />,
      },
      {
        path: "adminRoles/:roleId",
        element: <ViewAdminRole />,
      },
      {
        path: "companyRoles",
        element: (
          <ProtectRoute per={key.permissions.Settings}>
            <CompanyRole />
          </ProtectRoute>
        ),
      },
      {
        path: "companyRoles/:roleId",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <ViewCompanyRole />
          </ProtectRoute>
        ),
      },
      {
        path: "companyRoles/create",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <CreateCompanyRole />
          </ProtectRoute>
        ),
      },
      {
        path: "view-company-permissions/:roleId",

        element: (
          // <ProtectRoute>
          <ViewCompanyPermissions />
          // </ProtectRoute>
        ),
      },
      {
        path: "companyPlans",
        element: <CompanyPlans />,
      },
      {
        path: "plan-subscription",
        element: (
          // <ProtectRoute>
          <PlanSubscriptionPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "plan-upgrade",
        element: (
          // <ProtectRoute>
          <PlanUpgradePage />
          // </ProtectRoute>
        ),
      },
      {
        path: "payment-method",
        element: (
          // <ProtectRoute>
          <PaymentMethodPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "/plan-expired",
        element: (
          // <ProtectRoute>
          <ExpiredMessage />
          // </ProtectRoute>
        ),
      },
      {
        path: "/renew-plan",
        element: (
          // <ProtectRoute>
          <RenewPlan />
          // </ProtectRoute>
        ),
      },
      {
        path: "/plan",
        element: (
          // <ProtectRoute>
          <Plans />
          // </ProtectRoute>
        ),
      },

      // ************************************************************ saas ends here

      // com
      {
        path: "editGroup/:Id",
        element: (
          <ProtectRoute
            per={key.permissions.ApprovalGroups}
            operationFlag={true}
          >
            <EditGroupPage />
          </ProtectRoute>
        ),
      },
      {
        path: "group/create",
        element: (
          <ProtectRoute
            per={key.permissions.ApprovalGroups}
            operationFlag={true}
          >
            <CreatGroupPage />
          </ProtectRoute>
        ),
      },
      {
        path: "form-builder",
        element: (
          <ProtectRoute per={key.permissions.Forms}>
            <FormBuilderPage />
          </ProtectRoute>
        ),
      },
      {
        // path: "/creatForm",
        path: "form-builder/create",
        element: (
          <ProtectRoute per={key.permissions.Forms} operationFlag={true}>
            <CreateFormPage />
          </ProtectRoute>
        ),
      },
      {
        path: "updateForm",
        element: (
          <ProtectRoute per={key.permissions.Forms} operationFlag={true}>
            <UpdateFormPage />
          </ProtectRoute>
        ),
      },
      {
        path: "userEnableForm",
        element: (
          <ProtectRoute per={key.permissions.Report}>
            <ShowFormDetailPage />
          </ProtectRoute>
        ),
      },
      {
        path: "FixedFormDetail",
        element: (
          <ProtectRoute per={key.permissions.Report}>
            <FixedFormDetailPage />
          </ProtectRoute>
        ),
      },
      {
        path: "account",
        element: (
          // <ProtectRoute>
          <AccountInfoPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "station",
        element: (
          <ProtectRoute per={key.permissions.WorkStation}>
            <WorkistationPage />
          </ProtectRoute>
        ),
      },
      {
        path: "station/create",
        element: (
          <ProtectRoute per={key.permissions.WorkStation} operationFlag={true}>
            <CreateWorkstationPage />
          </ProtectRoute>
        ),
      },
      {
        path: "station/:Id",
        element: (
          <ProtectRoute per={key.permissions.WorkStation} operationFlag={true}>
            <ViewWorkStationPage />
          </ProtectRoute>
        ),
      },
      // {
      //   path: "/payment-method",
      //   element: (
      //     // <ProtectRoute per={key.permissions.WorkStation.UPDATE}>
      //       <AddCard />
      //     // </ProtectRoute>
      //   ),
      // },
      {
        path: "plant",
        element: (
          <ProtectRoute per={key.permissions.Plants}>
            <PlantPage />
          </ProtectRoute>
        ),
      },
      {
        path: "plant/create",
        element: (
          <ProtectRoute per={key.permissions.Plants} operationFlag={true}>
            <CreatePlantPage />
          </ProtectRoute>
        ),
      },
      {
        path: "view-plant/edit/:id",
        element: (
          <ProtectRoute per={key.permissions.Plants} operationFlag={true}>
            <UpdatePlantPage />
          </ProtectRoute>
        ),
      },
      {
        path: "user",
        element: (
          <ProtectRoute per={key.permissions.Settings}>
            <UserPage />
          </ProtectRoute>
        ),
      },
      {
        path: "user/create",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <CreateUserPage />
          </ProtectRoute>
        ),
      },
      {
        path: "user/edit/:id",
        element: <ViewUserPage />,
      },
      {
        path: "material-no-config",
        element: (
          <ProtectRoute per={key.permissions.Settings}>
            <MaterialNoConfigPage />
          </ProtectRoute>
        ),
      },

      {
        path: "material",
        element: (
          <ProtectRoute per={key.permissions.MaterialMaster}>
            <MaterialPage />
          </ProtectRoute>
        ),
      },
      {
        path: "material/create",
        element: (
          <ProtectRoute
            per={key.permissions.MaterialMaster}
            operationFlag={true}
          >
            <CreateMaterialPage />
          </ProtectRoute>
        ),
      },
      {
        path: "material/:id",
        element: (
          <ProtectRoute
            per={key.permissions.MaterialMaster}
            operationFlag={true}
          >
            <ViewMaterialPage />
          </ProtectRoute>
        ),
      },
      {
        path: "inspection-lot",
        element: (
          <ProtectRoute per={key.permissions.InspectionLot}>
            <InspectionPlanLotPage />
          </ProtectRoute>
        ),
      },
      {
        path: "inspection-lot/create",
        element: (
          <ProtectRoute
            per={key.permissions.InspectionLot}
            operationFlag={true}
          >
            <CreateInspectionPlanLotPage />
          </ProtectRoute>
        ),
      },
      {
        path: "inspection-lot/:id",
        element: (
          <ProtectRoute
            per={key.permissions.InspectionLot}
            operationFlag={true}
          >
            <ViewInspectionLotPage />
          </ProtectRoute>
        ),
      },
      {
        path: "measurement-units",
        element: (
          <ProtectRoute per={key.permissions.Settings}>
            <MeasurementUnitsPage />
          </ProtectRoute>
        ),
      },
      {
        path: "measurement-units/create",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <CreateMeasurementUnitsPage />
          </ProtectRoute>
        ),
      },
      {
        path: "measurement-unit/:id",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <UpdateMeasurementUnitPage />
          </ProtectRoute>
        ),
      },
      {
        path: "inspection-plan",
        element: (
          <ProtectRoute per={key.permissions.InspectionPlan}>
            <InspectionPlanPage />
          </ProtectRoute>
        ),
      },
      {
        path: "inspection-plan/view/:id",
        element: (
          <ProtectRoute per={key.permissions.InspectionPlan}>
            <MainInspectionPlanTreePage />
          </ProtectRoute>
        ),
      },
      {
        path: "inspection-plan/create",
        element: (
          <ProtectRoute
            per={key.permissions.InspectionPlan}
            operationFlag={true}
          >
            <CreateInspectionPlanPage />
          </ProtectRoute>
        ),
      },

      // {
      //   path: "/inspection-plan/step",
      //   element: (
      //     <ProtectRoute>
      //       <StepPage />
      //     </ProtectRoute>
      //   ),
      // },
      {
        path: "sensor",
        element: (
          <ProtectRoute per={key.permissions.Sensors.VIEW}>
            <SensorConfigurationsPage />
          </ProtectRoute>
        ),
      },

      {
        path: "sensor/create",
        element: (
          <ProtectRoute per={key.permissions.Sensors.CREATE}>
            <CreateSensorPage />
          </ProtectRoute>
        ),
      },
      {
        path: "sensor/:id",
        element: (
          <ProtectRoute per={key.permissions.Sensors.VIEW}>
            <ViewSensorPage />
          </ProtectRoute>
        ),
      },
      {
        path: "sensor-type",
        element: (
          <ProtectRoute per={key.permissions.SensorType.VIEW}>
            <SensorTypePage />
          </ProtectRoute>
        ),
      },
      {
        path: "create-sensor-type",
        element: (
          <ProtectRoute per={key.permissions.SensorType.CREATE}>
            <CreateSensorTypePage />
          </ProtectRoute>
        ),
      },
      {
        path: "sensor-type/:id",
        element: (
          <ProtectRoute per={key.permissions.SensorType.VIEW}>
            {" "}
            <ViewSensorTypePage />
          </ProtectRoute>
        ),
      },
      {
        path: "form-initiator",
        element: (
          // <ProtectRoute>
          <FormInitiatorPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "custom-form",
        element: (
          // <ProtectRoute>
          <CustomFormCrudPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "fixed-fields",
        element: <FixedFieldsPage />,
      },
      {
        path: "fixed-form/view",
        element: (
          // <ProtectRoute>
          <ViewFixedFormPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "custom-form/view",
        element: (
          // <ProtectRoute>
          <ViewCustomFormPage />
          // </ProtectRoute>
        ),
      },

      // {
      //   path: "/groups",
      //   element: (
      //     <ProtectRoute per={key.permissions.Groups.VIEW}>
      //       <GroupPage />
      //     </ProtectRoute>
      //   ),
      // },
      // {
      //   path: "/groups/create",
      //   element: (
      //     <ProtectRoute per={key.permissions.Groups.CREATE}>
      //       <CreateGroupsPage />
      //     </ProtectRoute>
      //   ),
      // },
      // {
      //   path: "/groups/:Id",
      //   element: (
      //     <ProtectRoute per={key.permissions.Groups.VIEW}>
      //       <ViewGroupPage />
      //     </ProtectRoute>
      //   ),
      // },
      {
        path: "FMEA",
        element: (
          <ProtectRoute per={key.permissions.FMEA.VIEW}>
            <FMEAPage />
          </ProtectRoute>
        ),
      },
      {
        path: "report/fema/:id",
        element: (
          // <ProtectRoute per={key.permissions.FMEAReport.VIEW}>
          <FemaReportPage />
          // </ProtectRoute>
        ),
      },

      {
        path: "report/fishbone",
        element: (
          <ProtectRoute per={key.permissions.Report}>
            <FishbonePage />
          </ProtectRoute>
        ),
      },
      {
        path: "report/5why/:id",
        element: (
          // <ProtectRoute per={key.permissions.Report2.VIEW}>
          <FiveWhy />
          // </ProtectRoute>
        ),
      },
      {
        path: "build-area",
        element: (
          <ProtectRoute per={key.permissions.BuildArea}>
            <BuildAreaPage />
          </ProtectRoute>
        ),
      },
      {
        path: "build-area/:id",
        element: (
          <ProtectRoute per={key.permissions.BuildArea}>
            <ViewBuildAreaPage />
          </ProtectRoute>
        ),
      },
      {
        path: "inspection-lot-report",
        element: (
          // <ProtectRoute per={key.permissions.Report}>
          <InspectionLotReport />
          // </ProtectRoute>
        ),
      },
      // {
      //   path: "step-report/:id/:flag",
      //   element: (
      //     // <ProtectRoute>
      //     <StepReport />
      //     // </ProtectRoute>
      //   ),
      // },
      {
        path: "inspection-lot-report/:id/:flag",
        element: (
          // <ProtectRoute>
          <StepReport />
          // </ProtectRoute>
        ),
      },
      {
        path: "qc-report/:id/steps/:stepId",
        element: (
          // <ProtectRoute>
          <QcReport />
          // </ProtectRoute>
        ),
      },
      {
        path: "material-no-config/create",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <NoConfigCreatePage />
          </ProtectRoute>
        ),
      },
      {
        path: "material-no-config/:id",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <NoConfigUpdatePage />
          </ProtectRoute>
        ),
      },
      {
        path: "assign-admin-permissions/:rolId",
        element: (
          <ProtectRoute per={key.permissions.Permissions.VIEW}>
            {" "}
            <AssignAdminPermissionsPage />
          </ProtectRoute>
        ),
      },
      {
        path: "rejection-reasons",
        element: (
          <ProtectRoute per={key.permissions.Settings}>
            <RejectionReasonPage />
          </ProtectRoute>
        ),
      },
      {
        path: "rejection-reasons/:Id/parentId/:parentId",
        element: (
          <ProtectRoute per={key.permissions.Settings}>
            <ViewRejectionReasonPage />
          </ProtectRoute>
        ),
      },
      {
        path: "rejection-reasons/:Id/parentId/:parentId/:reason",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <ViewRejectionReasonPage />
          </ProtectRoute>
        ),
      },
      {
        path: "rejection-reasons/:Id",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <ViewRejectionReason />
          </ProtectRoute>
        ),
      },
      {
        path: "rejection-reasons/child/:parentId",
        element: (
          <ProtectRoute per={key.permissions.Settings}>
            <RejectionReasonChildPage />
          </ProtectRoute>
        ),
      },
      {
        path: "rejection-reasons/child/:parentId/:reason",
        element: (
          <ProtectRoute per={key.permissions.Settings}>
            <RejectionReasonChildPage />
          </ProtectRoute>
        ),
      },

      {
        path: "rejection-reasons/create",
        element: (
          <ProtectRoute per={key.permissions.Settings} operationFlag={true}>
            <CreateRejectionReasonPage />
          </ProtectRoute>
        ),
      },
      {
        path: "release-inspection-log",
        element: (
          <ProtectRoute per={key.permissions.Settings}>
            <InspectionLog />
          </ProtectRoute>
        ),
      },
      {
        path: "approval-categories",
        element: (
          <ProtectRoute per={key.permissions.ApprovalCategory}>
            <ApprovalCategories />
          </ProtectRoute>
        ),
      },
      {
        path: "approval-categories/create",
        element: (
          <ProtectRoute
            per={key.permissions.ApprovalCategory}
            operationFlag={true}
          >
            <CreateApprovalCategories />
          </ProtectRoute>
        ),
      },
      {
        path: "approval-categories/child/:Id",
        element: (
          <ProtectRoute per={key.permissions.Forms}>
            <ApprovalCategoriesChild />
          </ProtectRoute>
        ),
      },
      {
        path: "group/list",
        element: (
          <ProtectRoute per={key.permissions.Forms}>
            <GroupListingPage />
          </ProtectRoute>
        ),
      },
      {
        path: "my/group/list",
        element: (
          <ProtectRoute per={key.permissions.Forms}>
            <MyGroupListingPage />
          </ProtectRoute>
        ),
      },
      {
        path: "custom-form/view",
        element: (
          // <ProtectRoute>
          <ViewCustomFormPage />
          // </ProtectRoute>
        ),
      },
      {
        path: "reportsDashboard",
        element: (
          <ProtectRoute per={key.permissions.Dashboard.VIEW}>
            <GraphsPage />
          </ProtectRoute>
        ),
      },
      {
        path: "report/data-entry-form",
        element: (
          <ProtectRoute per={key.permissions.Report}>
            <DataEntryFormPage />
          </ProtectRoute>
        ),
      },
      {
        path: "report/data-entry-form/filled/:Id",
        element: (
          <ProtectRoute per={key.permissions.Report}>
            <GeneralFormFilledByPage />
          </ProtectRoute>
        ),
      },
      {
        path: "restricted",
        element: (
          <ProtectRoute per={key.permissions.Report}>
            <RestrictedAcess />
          </ProtectRoute>
        ),
      },
      {
        path: "userFilledForm",
        element: (
          <ProtectRoute per={key.permissions.Report}>
            <USerFilledForm />
          </ProtectRoute>
        ),
      },
      {
        path: "FEMAReport",
        element: (
          <ProtectRoute per={key.permissions.Report}>
            <FEMAreport />
          </ProtectRoute>
        ),
      },
      {
        path: "FiveWhyReport",
        element: (
          <ProtectRoute per={key.permissions.Report}>
            <FiveWhyReport />
          </ProtectRoute>
        ),
      },
      {
        path: "ExampleData",
        element: <EnhancedTable />,
      },
      // { path: 'forb', element: <UnAuthPage /> },
      // { path: 'unAuth', element: <ForbPage /> },
      // { path: '*', element: <NoMatchPage /> },
    ],
  },
];

export { PrivateRoutes, PublicRoutes };
