import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { toast } from "react-toastify";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";
import {
  ERROR_MESS,
  WORK_STATION_CREATE,
  WORK_STATION_DELETE,
  WORK_STATION_UPDATE,
} from "../../constant";
// import { reset } from "nodemon";

const initialState = {
  workStations: [],
  workStation: {},
  error: null,
  status: null,
};

export const fetchWorkStations = createAsyncThunk(
  "get/WorkStations",
  async () => {
    try {
      const response = await API.workstations.getAll();
      // // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data.message);
      return error.response.data;
    }
  }
);

export const addWorkStationData = createAsyncThunk(
  "post/workStation",
  async (formdata) => {
    try {
      const response = await API.workstations.add(formdata.finalData);
      const { success, data } = response.data;
      if (success) {
        formdata.mat.clearValue();
        formdata.plant1.clearValue();
        formdata.resetForm();
        formdata.navigate("/station");
      }
      SHOW_SUCCESS(success, WORK_STATION_CREATE);

      // // // //console.log(response.data);
      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      // // //console.log(error.response.data.message);
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(error, SHOW_ERROR);
      }
      // // // //console.log(error.response.data.message);
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateOneWorkStationData = createAsyncThunk(
  "update/workStation",
  async (data) => {
    try {
      // // // //console.log("from slice :", data);
      const response = await API.workstations.update(data.Id, data.finalData);
      if (response.data.success) {
        SHOW_SUCCESS(response.data.success, WORK_STATION_UPDATE);
        data.setEditToggle(true);
        data.navigate("/station");
      }
      return [];
    } catch (error) {
      // // // //console.log("FROM SLICE API ERROR", error);
      const { code, success, message, errors } = error.response.data;
      // // //console.log(error.response.data.message);
      if (!success && code === 404) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(error, SHOW_ERROR);
      }
      // // // //console.log(error.response.data.message);
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const deleteWorkStation = createAsyncThunk(
  "delete/workStation",
  async (data) => {
    try {
      const response = await API.workstations.delete(data);
      // // // //console.log(response.data);
      SHOW_SUCCESS(response.data.success, WORK_STATION_DELETE);
      return data;
    } catch (error) {
      // // // //console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      // console.log("error => ", error.response.data);
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 404) {
        SHOW_ERROR(true, message);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return [];
    }
  }
);

export const fetchOneWorkStationData = createAsyncThunk(
  "get/workStation",
  async (id) => {
    try {
      const response = await API.workstations.getOne(id);
      //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data.message);
      return error.response.data;
    }
  }
);

const workStationSlice = createSlice({
  name: "workStation",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWorkStations.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchWorkStations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.workStations = action.payload;
        // delete action.payload.workStations;
        // state.paginationData = action.payload;
      })
      .addCase(fetchWorkStations.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      .addCase(fetchOneWorkStationData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOneWorkStationData.fulfilled, (state, action) => {
        const tempPlant = action?.payload?.plant?.map((p) => {
          return { value: p.id, label: p.plantName };
        });
        const tempMaterial = action.payload?.materialMaster?.map((m) => {
          return { value: m.id, label: m.materialText };
        });
        const tempPlantId = action?.payload?.plant?.map((p) => {
          return p.id;
        });
        const tempMaterialId = action?.payload?.materialMaster?.map((m) => {
          return m.id;
        });
        state.workStation = action.payload;
        state.workStation.plant = tempPlant;
        state.workStation.materialMaster = tempMaterial;
        state.workStation.plantIds = tempPlantId;
        state.workStation.materialIds = tempMaterialId;
        state.status = "succeeded";
      })
      .addCase(fetchOneWorkStationData.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      .addCase(deleteWorkStation.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteWorkStation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.workStations = state.workStations.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteWorkStation.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      .addCase(addWorkStationData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addWorkStationData.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.status = "succeeded";
          state.workStations.unshift(action.payload.data);
        } else {
          state.status = "failed";
        }
      })
      .addCase(addWorkStationData.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(updateOneWorkStationData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateOneWorkStationData.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateOneWorkStationData.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase("CLEAR_WORKSTATION", (state, action) => {
        state.workStation = action.payload;
      });
  },
});

// export const { postAdded, reactionAdded } = workStationSlice.actions;

export default workStationSlice.reducer;
