import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { addDefectInspectionPlanData } from '../../../../../Redux/features/GraphsSlice';
import DataFilters from './components/DateFilters';
import DefectInspectionModal from './components/DefectInspectionModal';

const DefectInspection = (props) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
  const [barData, setBarData] = useState({
    series: [
      {
        name: '',
        data: [],
      },
    ],
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        // text: "Defected Rejected Lots",
        align: 'center',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', '#D3D3D3'], // takes an array which will be repeated on columns
          opacity: 1,
        },
      },
      labels: {
        style: {
          display: 'contents',

          fontSize: '12px',
        },
      },

      xaxis: {
        labels: {
          show: true,
        },
        categories: [],
      },
    },
  });

  const [createGraphData, setcreateGraphData] = useState({
    startDate: '2022-01-01',
    endDate: '2022-08-01',
    topDefect: '10',
    inspectorId: null,
  });

  const {
    Graphs: { defectInspectionPlan },
  } = useSelector((state) => state);

  const handleSubmit = () => {
    const finalData = {
      ...createGraphData,
      startDate: to.current.value,
      endDate: from.current.value,
    };
    dispatch(addDefectInspectionPlanData(finalData));
  };

  useEffect(() => {
    setBarData((prev) => {
      return {
        ...prev,
        series: [
          {
            name: 'Rejected Qty',
            data: defectInspectionPlan?.rejectedQtys,
          },
        ],
        options: { xaxis: { categories: defectInspectionPlan?.titles } },
      };
    });
    {
      // console.log('ApexData', barData);
    }
    return () => {};
  }, [defectInspectionPlan?.rejectedQtys, defectInspectionPlan?.titles]);

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  const todayDate = yyyy + '-' + mm + '-' + dd;

  const yesterday = new Date();
  const ydd = String(today.getDate()).padStart(2, '0');
  const ymm = String(today.getMonth()).padStart(2, '0'); //January is 0!
  const Yyyyy = yesterday.getFullYear();
  const YesterdayDate = Yyyyy + '-' + ymm + '-' + ydd;
  const [startDate, setStartDate] = useState('');
  const firstMDate = Yyyyy + '-' + mm + '-' + '01';
  const firstYDate = Yyyyy + '-' + '01' + '-' + '01';
  useEffect(() => {
    setStartDate(!props.MTD ? firstYDate : firstMDate);
  }, [props.MTD]);

  useEffect(() => {
    if (startDate !== '') {
      dispatch(
        addDefectInspectionPlanData({
          startDate: startDate,
          endDate: todayDate,
          topDefect: '10',
          inspectorId: '',
        })
      );
    }
  }, [startDate]);

  const from = useRef();
  const to = useRef();

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-4">
          <div className="d-flex justify-content-around">
            <h2
              className="mb-4 fs-3"
              style={{ fontFamily: 'Rubik, sans-serif' }}
            >
              Rejected Lots
            </h2>
            <button
              style={{ height: '40px', marginRight: '-40px' }}
              className="btn btn-primary p-2"
              onClick={handleOpen}
            >
              Enlarge
            </button>
          </div>
          <DataFilters
            startDate={startDate}
            dates={createGraphData}
            setDates={setcreateGraphData}
            inspector={true}
            tDate={to}
            yDate={from}
          />
          <div className={`col-12`}>
            <button onClick={handleSubmit} className="btn btn-primary">
              Apply
            </button>
          </div>
        </div>
        {barData?.series[0]?.data?.length > 0 ? (
          <div className="col-md-8 text-center" id="chart">
            <ReactApexChart
              style={{ overflowX: 'auto', overflowY: 'auto' }}
              options={barData?.options}
              series={barData?.series}
              type="line"
              height={300}
              width="100%"
            />
          </div>
        ) : (
          null
        )}
      </div>
      {/* <div
            style={{ height: "100%", overflow: "auto" }}
            className="card p-3 col-md-6 mt-4"
          >
            <table className="table table-responsive text-nowrap ">
              <thead>
                <th className="ps-4">#</th>
                <th className="ps-4">Label</th>
                <th className="ps-4">Value</th>
              </thead>
              <tbody className="">
                {defectInspectionPlan?.comData?.map(
                  ({ inspectionPlanId, inspectionTitle, rejectedQty }) => (
                    <tr key={inspectionPlanId}>
                      <td>{inspectionPlanId}</td>
                      <td>{inspectionTitle}</td>
                      <td>{rejectedQty}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div> */}
      {barData?.series[0]?.data?.length > 0 ? (
        <DefectInspectionModal
          barData={barData}
          handleClose={handleClose}
          open={open}
        />
      ) : (
        null
      )}

      {/* <span>{JSON.stringify(inspectionLot)}</span> */}
    </>
  );
};

export default DefectInspection;
