import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import MainBreadcrum from "../../../layout/MainBreadcrum";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";

const Plans = () => {
  const { company, status } = useSelector((state) => state.CompanyManagement);
  let arr;
  if (company?.plans?.length > 0) {
    arr = [...company?.plans]?.reverse();
  }

  let todayDate = moment().format("DD-MM-YYYY");

  console.log(
    arr?.[0]?.renewSubscriptionAt > todayDate && arr?.[0]?.planPrice === 0
  );

  return (
    <div>
      <div className="row">
        <MainBreadcrum
          name="Subscribed Plans"
          subName=""
          links={[
            { path: "/", title: "" },
            { path: "", title: "Subscribed Plans", activeLink: true },
          ]}
        />
        <div className="col-4 align-self-center text-end">
          <div className="d-flex gap-1">
            <Link to="/renew-plan">
              <button type="button" className="btn btn-primary">
                Upgrade Plan
              </button>
            </Link>
            {arr?.[0]?.planPrice !== 0 ? (
              <Link to="/renew-plan?seats=1">
                <button type="button" className="btn btn-primary">
                  Purchase seats
                </button>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="mb-5">
          <div className="card mt-2 overflow-scroll">
            <div className="card-body">
              <>
                <table className="table">
                  <thead>
                    <tr className="text-nowrap text-center">
                      <th>Name</th>
                      <th>Type</th>
                      <th>Users</th>
                      <th>/User Price</th>
                      {/* <th>Sensors</th> */}
                      {/* <th>Sensor Price</th> */}
                      {/* <th>Storage</th> */}
                      {/* <th>Storage Price</th> */}
                      <th>Plan Price</th>
                      <th>Subscripton Date</th>
                      <th>Renew Subscription</th>
                      <th>Cancel Subscription</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0 text-center">
                    {arr?.map((p) => (
                      <tr key={p.id}>
                        <td className="d-flex align-items-center">
                          {p?.planName}
                          {arr?.[0]?.id === p?.id && (
                            <CheckCircleOutlineIcon
                              className="text-success"
                              fontSize="small"
                            />
                          )}
                        </td>
                        <td>{p.planType}</td>
                        <td>{p?.noOfUsers}</td>
                        <td>{p?.perUserPrice}</td>
                        {/* <td>{p?.noOfSensors}</td> */}
                        {/* <td>{p?.perSensorPrice}</td> */}
                        {/* <td>{p?.storage}</td> */}
                        {/* <td>{p?.perGBPrice}</td> */}
                        <td>{p?.planPrice}</td>
                        <td>{p?.subscriptionDate}</td>
                        <td>{p?.renewSubscriptionAt}</td>
                        <td>{p?.cancelSubscriptionAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
