import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { SHOW_ERROR } from "../../utils/toastMessages";

const initialState = {
  logs: [],
  paginationData: {},
  error: null,
  status: null,
};

export const getAllAdminLogs = createAsyncThunk(
  "get/adminLogs",
  async (formData) => {
    try {
      const response = await API.AdminLogs.getAll(formData.id, formData.page);
      const { success, message, data } = response.data;

      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      // //console.log(error.response.data );
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, errors.code[0]);
      } else {
        SHOW_ERROR(true, "Something Went Wrong !");
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const AdminLogsSlice = createSlice({
  name: "Admin Logs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllAdminLogs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllAdminLogs.fulfilled, (state, action) => {
        state.status = "succeeded";
        let { code, data, success } = action.payload;

        if (success && code === 200) {
          state.logs = data.loginLogs;
          delete data.loginLogs;
          state.paginationData = data;
        }
      })
      .addCase(getAllAdminLogs.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      });
  },
});

export default AdminLogsSlice.reducer;
