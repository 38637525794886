import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { API } from '../../config';
import { SHOW_ERROR, SHOW_INFO, SHOW_SUCCESS } from "../../utils/toastMessages";
import { fetchOneCompanyData } from "./CompanyManagementSlice";
const initialState = {
  intent: '',
  charges: false,
  registeredCompany: {
    name: '',
    contactPersonFirstName: '',
    contactPersonLastName: '',
    contactPersonPhoneNo: '',
    contactPersonEmail: '',
    test: '',
  },
  paymentMethods: [],
  error: null,
  PPlan_id: {},
  status: null,
};

export const getIntent = createAsyncThunk('get/intent', async () => {
  try {
    const response = await API.Stripe.getIntent();
    // //console.log(response.data);
    const {
      success,
      data: { client_secret },
    } = response.data;

    return { success, client_secret, code: 200 };
  } catch (error) {
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
    } else {
      SHOW_ERROR(true, 'Something Went Wrong !');
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const createStripeCharge = createAsyncThunk(
  'STRIPE_CHARGE',
  async (formData, thunkAPI) => {
    try {
      const response = await API.Stripe.StripeCharge(formData?.payData);
      const { success, data } = response.data;
      // formData?.navigate('/login');
      formData?.handleSuccess()
      // SHOW_INFO(success, 'Payment Successful');
      // formData?.hide();

      thunkAPI.dispatch(resetCharge());
      return {
        success,
        data,
        code: 201,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);
export const upgradeStripeCharge = createAsyncThunk(
  'UPGRADE_STRIPE_CHARGE',
  async (formData, thunkAPI) => {
    try {
      const response = await API.Stripe.StripeChargeUpgrade(formData?.payData);
      const { success, data } = response.data;
      // formData?.navigate('/login');
      formData?.handleSuccess()
      SHOW_INFO(success, 'Upgraded Successfully!');
      // formData?.hide();

      thunkAPI.dispatch(resetCharge());
      return {
        success,
        data,
        code: 201,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);
export const createFreeUsers = createAsyncThunk(
  'STRIPE_CHARGE',
  async (formData, thunkAPI) => {
    try {
      const response = await API.Stripe.CreateFreeUser(formData?.payData);
      const { success, data } = response.data;
      formData?.navigate('/login');
      SHOW_INFO(success, 'Registration Successful');
      // formData?.hide();

      thunkAPI.dispatch(resetCharge());
      return {
        success,
        data,
        code: 201,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getPaymentMethods = createAsyncThunk(
  "get/paymentMethods",
  async (navigate) => {
    try {
      const response = await API.Stripe.getPaymentMethods();
      // //console.log(response.data);
      const { success, data } = response.data;
      if (success) {
        if (data.length == 0) {
          navigate("/payment-method");
        }
      }

      return { success, data, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else if (!success && code === 404) {
        SHOW_ERROR(true, message);
      } else {
        SHOW_ERROR(true, "Something Went Wrong !");
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const addPaymentMethod = createAsyncThunk(
  "add/paymentMethod",
  async (formData, thunkAPI) => {
    try {
      const response = await API.Stripe.addPaymentMethod(formData);
      // //console.log(response.data);
      const { success, data } = response.data;
      if (success) {
        thunkAPI.dispatch(getPaymentMethods());
        SHOW_SUCCESS(success, "Payment method added");
      }
      return { success, data, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, "Something Went Wrong !");
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const setDefaultPaymentMethod = createAsyncThunk(
  "set/defaultPaymentMethod",
  async (formData, thunkAPI) => {
    try {
      const response = await API.Stripe.defaultPaymentMethod(formData);
      // //console.log(response.data);
      const { success, data } = response.data;
      if (success) {
        thunkAPI.dispatch(getPaymentMethods());
        SHOW_SUCCESS(success, "Default payment method set");
      }

      return { success, data, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, "Something Went Wrong !");
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const renewPlan = createAsyncThunk(
  "renew/plan",
  async (formData, thunkAPI) => {
    try {
      const response = await API.Stripe.renewSubscription(formData.data);
      // //console.log(response.data);
      const { success, data } = response.data;
      if (success) {
        thunkAPI.dispatch(fetchOneCompanyData(formData.Id));
        SHOW_SUCCESS(success, "Plan renewed");
      }
      return { success, data, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, "Something Went Wrong !");
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const buyExtraSeats = createAsyncThunk(
  "buy/extraSeats",
  async (formData, thunkAPI) => {
    try {
      const response = await API.Stripe.buySeats(formData);
      // //console.log(response.data);
      const { success, data } = response.data;
      if (success) {
        // thunkAPI.dispatch(getPaymentMethods());
        SHOW_SUCCESS(success, "Extra seats bought");
      }
      return { success, data, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, "Something Went Wrong !");
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const StripeSlice = createSlice({
  name: 'stripeCharges',
  initialState,
  reducers: {
    setPlanId: (state, action) => {
      state.PPlan_id = action.payload;
    },
    registerCompany: (state, action) => {
      const { companyData, setIsConfirmed, setFormNo } = action.payload;
      if (companyData) {
        state.status = 'succeeded';
        state.registeredCompany = companyData;
        setFormNo(2)
        // setIsConfirmed(true);
      } else {
        state.status = 'failed';
      }
    },
    resetCharge: (state, action) => {
      state.registeredCompany = {};
      // state.charges = false;
    },
  },
  extraReducers(builder) {
    builder
      // renew plan reduces
      .addCase("ON_LOADING", (state, action) => {
        state.status = "loading";
      })
      .addCase("OFF_LOADING", (state, action) => {
        state.status = "";
      })
      .addCase(renewPlan.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(renewPlan.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.paymentMethods = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(renewPlan.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      // buy extra seats reduces
      .addCase(buyExtraSeats.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(buyExtraSeats.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          // state.paymentMethods = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(buyExtraSeats.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      // post stripe charges data reduces
      // .addCase(getIntent.pending, (state, action) => {
      //   state.status = 'loading';
      // })
      .addCase(getIntent.fulfilled, (state, action) => {
        const { success, client_secret, code } = action.payload;

        if (success && code === 200) {
          state.status = 'succeeded';
          state.intent = client_secret;
          state.registeredCompany.test = 'done';
        } else {
          state.status = 'failed';
        }
      })
      .addCase(getIntent.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      // post stripe charges data reduces
      .addCase(createStripeCharge.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createStripeCharge.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 201) {
          state.status = 'succeeded';
          state.charges = true;
          state.registeredCompany.test = '';
        } else {
          state.status = 'failed';
        }
      })
      .addCase(createStripeCharge.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      // post stripe charges data reduces
      .addCase(upgradeStripeCharge.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(upgradeStripeCharge.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 201) {
          state.status = 'succeeded';
          state.charges = true;
          state.registeredCompany.test = '';
        } else {
          state.status = 'failed';
        }
      })
      .addCase(upgradeStripeCharge.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      // get payment methods reduces
      .addCase(getPaymentMethods.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.paymentMethods = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      // add payment methods reduces
      .addCase(addPaymentMethod.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addPaymentMethod.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          state.paymentMethods.push(data);
        } else {
          state.status = "failed";
        }
      })
      .addCase(addPaymentMethod.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      // set default payment methods reduces
      .addCase(setDefaultPaymentMethod.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = "succeeded";
          // state.paymentMethods = data;
        } else {
          state.status = "failed";
        }
      })
      .addCase(setDefaultPaymentMethod.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      });


  },
});
export const { setPlanId, resetCharge, registerCompany } = StripeSlice.actions;

export default StripeSlice.reducer;
