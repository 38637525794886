import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { handelState } from "../../../../utils/handelStates";
import Spinner from "../../../common/Spinner";
import MainBreadcrum from "../../../layout/MainBreadcrum";
import {
  addCategory,
  getAllCatogries,
  getChilrenCatogries,
  updateCat,
  updateCategory,
} from "../../../../Redux/features/CategorySlice";
import ModalPopup from "../../../common/ModalPopup";
import { Modal } from "react-bootstrap";

// import ToggleButton from "react-toggle-button";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";

const UpdateApprovalCategories = (props) => {
  // const navigate = useNavigate();

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });
  const { id, name } = props?.editData;
  const modifiedData = { id, name };

  return (
    <Modal show={props?.isEditPopup} onHide={props?.closePopup}>
      <Modal.Header closeButton>
        <Modal.Title>Update Approval Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={
            props?.mainCat === "mainCat" ? modifiedData : props?.editData
          }
          validationSchema={validationSchema}
          onSubmit={(values) => {
            props?.mainCat === "mainCat"
              ? dispatch(
                  updateCat({
                    values: values,
                    handleClose: () => {
                      props?.closePopup();
                      props.setCount((prevCount) => prevCount + 1);
                    },
                  })
                )
              : dispatch(
                  updateCategory({
                    values: values,
                    handleClose: () => {
                      props?.closePopup();
                      dispatch(getChilrenCatogries(props?.Id));
                    },
                  })
                );
          }}
        >
          {({ errors, touched, handleBlur }) => (
            <Form>
              <div className="mb-3 row w-100">
                <div
                  className="col-lg-4 col-sm-12 col-md-12"
                  style={{ display: "flex", whiteSpace: "nowrap" }}
                >
                  <label
                    className="col-form-label "
                    htmlFor="name"
                    style={{
                      paddingTop: "0%",
                      paddingRight: "1px",
                      width: "120px",
                    }}
                  >
                    Catogory Name:
                  </label>

                  <div>
                    <span className="text-danger"> *</span>
                  </div>
                </div>

                <div className="col-lg-8 col-sm-12 col-md-12">
                  <Field
                    name="name"
                    type="text"
                    className={`form-control ${
                      errors.name && touched.name ? "is-invalid" : ""
                    }`}
                    onBlur={handleBlur}
                    placeholder="Categories"
                  />
                  <ErrorMessage
                    name="name"
                    component="span"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 align-self-center text-center">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default UpdateApprovalCategories;
