import React from 'react';
import Branch from './Branch';
import './TreeStyle.css'

const Tree = ({ data }) => {
  return (
    <div style={{ width: '100%' }}>
      {data.map((item, index) => (
        <Branch key={item.id} item={item} level={0} index={index} isInitialRender={true}/>
      ))}


    </div>
  );
};

export default Tree;
