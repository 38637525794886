import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { IoIosClose } from "react-icons/io";

const PasswordUpdate = ({ handleClose, passFun, open }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const initState = {
    current_password: "",
    password: "",
    password_confirmation: "",
  };

  const validationSchema = Yup.object().shape({
    current_password: Yup.string()
      .trim()
      .required("Current password is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password doesn't match")
      .required("Password confirmation is required"),
  });

  // useEffect(() => {
  //   dispatch(fetchOneUserData(id));

  //   return () => {};
  // }, [dispatch, id]);

  // const handlePassword = (e) => {
  //   e.preventDefault();
  //   dispatch(passFun(pass));
  //   handleClose();
  // };
  // //console.log(pass);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    bgcolor: "background.paper",
    border: "2px solid #69809a",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={style} className="rounded bg-white">
          <IoIosClose
            className="position-absolute pointer"
            style={{ fontSize: "40px", right: 0, top: 0 }}
            onClick={handleClose}
          />
          <Typography
            className="text-center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Password
          </Typography>
          <br />
          <Formik
            initialValues={initState}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(passFun({ values, handleClose, setIsLoading }));
            }}
            className="d-flex justify-content-between align-items-center flex-nowrap flex-column mt-3"
          >
            {({ errors, touched, handleBlur }) => (
              <Form>
                {/* input field */}
                <div className="mb-3 row text-left">
                  <label
                    className="col-form-label col-sm-3"
                    htmlFor="current_password"
                  >
                    Current Password
                  </label>

                  <div className="col-sm-9 my-auto position-relative">
                    <Field
                      onBlur={handleBlur}
                      type={showCurrentPassword ? "text" : "password"}
                      style={{ paddingRight: "30px" }}
                      className={`form-control ${
                        errors.current_password && touched.current_password
                          ? "is-invalid"
                          : ""
                      }`}
                      id="current_password"
                      name="current_password"
                      placeholder="Current Password"
                    />
                    <i
                      className={`position-absolute pointer fas ${
                        showCurrentPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      style={{ top: "10px", right: "20px" }}
                      onClick={() => setShowCurrentPassword((prev) => !prev)}
                    />
                    <ErrorMessage
                      name="current_password"
                      component="span"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="mb-3 row text-left">
                  <label className="col-form-label col-sm-3" htmlFor="password">
                    New Password
                  </label>

                  <div className="col-sm-9 my-auto position-relative">
                    <Field
                      onBlur={handleBlur}
                      type={showPassword ? "text" : "password"}
                      style={{ paddingRight: "30px" }}
                      className={`form-control ${
                        errors.password && touched.password ? "is-invalid" : ""
                      }`}
                      id="password"
                      name="password"
                      placeholder="New Password"
                    />
                    <i
                      className={`position-absolute pointer fas ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      style={{ top: "10px", right: "20px" }}
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    className="col-form-label col-sm-3"
                    htmlFor="password_confirmation"
                  >
                    Confirm Password
                  </label>

                  <div className="col-sm-9 my-auto position-relative">
                    <Field
                      onBlur={handleBlur}
                      type={showConfPassword ? "text" : "password"}
                      style={{ paddingRight: "30px" }}
                      className={`form-control ${
                        errors.password_confirmation &&
                        touched.password_confirmation
                          ? "is-invalid"
                          : ""
                      }`}
                      id="password_confirmation"
                      name="password_confirmation"
                      placeholder="Confirm New Password"
                    />
                    <i
                      className={`position-absolute pointer fas ${
                        showConfPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      style={{ top: "10px", right: "20px" }}
                      onClick={() => setShowConfPassword((prev) => !prev)}
                    />
                    <ErrorMessage
                      name="password_confirmation"
                      component="span"
                      className="invalid-feedback text-start"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary mt-4"
                    aria-label="submit data"
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PasswordUpdate;
