import BreadcrumLink from "./components/BreadcrumLink";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import IsAuthorize from "../common/IsAuthorize";
import { key } from "../../config";
import { SHOW_ERROR } from "../../utils/toastMessages";
const MainBreadcrum = ({
  links,
  name,
  subName,
  permis,
  inspectionlot,
  path,
  label,
  modal,
  nav,
  isDisableCreateBtn,
  isButtonDisabled,
}) => {
  const navigate = useNavigate();
  const { permissions, userRole } = useSelector((state) => state.Auth);
  return (
    <div>
      <div className="creat d-flex justify-content-between">
        <h2 className="fw-bold" style={{ fontFamily: "Rubik, sans-serif" }}>
          {name} {subName}
          {/* <span className="fs-5 p-abs">{subName && `(${subName})`}</span> */}
        </h2>
        {nav ? (
          <IsAuthorize>
            <div className="col-4 align-self-center text-end">
              {!isButtonDisabled ? (
                <button
                  onClick={() =>
                    navigate({
                      pathname: path,
                    })
                  }
                  type="button"
                  className="btn btn-primary"
                >
                  Create
                </button>
              ) : null}
            </div>
          </IsAuthorize>
        ) : (
          <>
            {inspectionlot === "inspection" ? (
              userRole !== key?.defaultRoles?.FormsAndFlow ? (
                <div>
                  {modal ? (
                    <div className="col-4 align-self-center text-end">
                      <button
                        onClick={path}
                        type="button"
                        className="btn btn-primary"
                      >
                        {label}
                      </button>
                    </div>
                  ) : path ? (
                    isDisableCreateBtn ? (
                      <button
                        type="button"
                        // disabled={true}
                        className="btn btn-primary"
                        onClick={() =>
                          SHOW_ERROR(
                            true,
                            "The maximum number of users has been exceeded. To add more users, you will need to upgrade to the standard plan."
                          )
                        }
                      >
                        {label}
                      </button>
                    ) : (
                      <Link to={path}>
                        <button type="button" className="btn btn-primary">
                          {label}
                        </button>
                      </Link>
                    )
                  ) : null}
                </div>
              ) : null
            ) : (
              <IsAuthorize>
                {userRole !== key?.defaultRoles?.FormsAndFlow ? (
                  <div>
                    {modal ? (
                      <div className="col-4 align-self-center text-end">
                        <button
                          onClick={path}
                          type="button"
                          className="btn btn-primary"
                        >
                          {label}
                        </button>
                      </div>
                    ) : path ? (
                      isDisableCreateBtn ? (
                        <button
                          type="button"
                          // disabled={true}
                          className="btn btn-primary"
                          onClick={() =>
                            SHOW_ERROR(
                              true,
                              "The maximum number of users has been exceeded. To add more users, you will need to upgrade to the standard plan."
                            )
                          }
                        >
                          {label}
                        </button>
                      ) : (
                        <Link to={path}>
                          <button type="button" className="btn btn-primary">
                            {label}
                          </button>
                        </Link>
                      )
                    ) : null}
                  </div>
                ) : null}
              </IsAuthorize>
            )}
          </>
        )}
      </div>

      <div className="res-divider dropdown-divider mb-3" />
      <div className="d-flex">
        {links?.map(({ path, title, activeLink, isParentLabel }) => (
          <BreadcrumLink
            key={path}
            path={path}
            title={title}
            activeLink={activeLink}
            isParentLabel={isParentLabel}
          />
        ))}
      </div>

      <div className="res-divider dropdown-divider mb-3" />
    </div>
  );
};

export default MainBreadcrum;
