const handelState = (date, fun, label, value) => {
  // const propNmae = label
  fun({ ...date, [label]: value });
};

const to_option = (Arr, [Bvalue, Blabel], [value, label]) => {
  return Arr?.map((a) => {
    return { [`${value}`]: a[Bvalue], [`${label}`]: a[Blabel] };
  });
};

// var getKeyValueMap = function(data, keyPropName, valuePropName) {
//     return $.map(data, function(item, i) {
//       return {
//         key:   item[keyPropName],
//         value: item[valuePropName]
//       }
//     });
//   };

const validate_form = (submitData, initErr, error_setter) => {
  let f = [];
  for (const [key, value] of Object.entries(submitData)) {
    const basicKeys = Object.keys(initErr);
    // //console.log(`${key} : ${value}`);

    // //console.log("basicKeys => ", basicKeys);
    if (value === '' || value === ' ' || value?.length === 0 || value === undefined) {
      if (basicKeys.includes(key)) {
        // //console.log(`${key} : ${value}`);
        error_setter((prve) => ({ ...prve, [key]: true }));
        f.push(false);
      }
    }
  }
  //console.log("f", f);
  if (f.length === 0) {
    return true;
  }
  return false;
};

export { handelState, to_option, validate_form }; /*hello  */
