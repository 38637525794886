import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import LinkItem from "./LinkItem";
import { FaAngleRight, FaLock, FaAngleDown } from "react-icons/fa";
import { useSelector } from "react-redux";
const SubMenuLinkItem = ({ link, label, icon, subMenu, permission }) => {
  const [open, setOpen] = useState(false);
  const { isFreeRegister, userPermissions } = useSelector(
    (state) => state.Auth
  );

  // console.log("permission", permission);
  let isShowLockIcon = false;
  let isBlur = false;
  if (isFreeRegister && label !== "Dashboard" && label !== "Foundation") {
    isShowLockIcon = true;
  }

  if (!userPermissions?.includes(permission)) {
    isBlur = true;
  }

  const renderLink = (sub, index) => {
    if (
      isFreeRegister &&
      label === "Settings" &&
      (sub.label === "Users" ||
        sub.label === "Rejection Reason" ||
        sub.label === "Measurement Unit")
    ) {
      return null;
    }
    // In standard case, hide user link from Foundation tab
    if (
      !isFreeRegister &&
      label === "Foundation" &&
      (sub.label === "Users" ||
        sub.label === "Rejection Reason" ||
        sub.label === "Measurement Unit")
    ) {
      return null;
    }

    return (
      <li className="menu-item" key={index}>
        <LinkItem
          link={sub.link}
          label={sub.label}
          icon={sub.icon}
          parentLabel={label}
        />
      </li>
    );
  };

  if (isFreeRegister && label === "Report") {
    isShowLockIcon = false;
  }
  return (
    <>
      <Link
        onClick={() => setOpen(!open)}
        aria-controls={`#${link}`}
        aria-expanded={open}
        to={`#${link}`}
        className={`menu-link align-middle menu-toggle ${
          isBlur ? "blur_text" : null
        }`}
      >
        {icon.includes("svg") ? (
          <img
            className="menu-icon tf-icons svg-icon"
            src={icon}
            alt={`${label} icon`}
          />
        ) : (
          <i className={icon} />
        )}
        <span
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span className="d-flex align-items-center">
            {label}
            {isShowLockIcon ? (
              <FaLock style={{ fontSize: "11px", marginLeft: "5px" }} />
            ) : null}
          </span>
          {open ? <FaAngleDown /> : <FaAngleRight />}{" "}
        </span>
      </Link>
      <Collapse in={open}>
        <ul className="nav flex-column ms-4" id={link}>
          {subMenu.map((sub, index) => renderLink(sub, index))}
        </ul>
      </Collapse>
    </>
  );
};
export default SubMenuLinkItem;
// <>
//   <LinkItem link={link} label={label} icon={icon} />
//   <ul className="menu-sub">
// {subMenu.map((sub) => (
//   <li className="menu-item">
//     <LinkItem link={sub.link} label={sub.label} icon={sub.icon} />
//   </li>
// ))}
//   </ul>
// </>
