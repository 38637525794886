import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectors } from '../../../../../../Redux/features/GraphsSlice';
import { fetchAllMaterials } from '../../../../../../Redux/features/MaterialMasterSlice';
import { getAllUsers } from '../../../../../../Redux/features/UsersSlice';
import { handelState } from '../../../../../../utils/handelStates';

const DataFilters = ({
  startDate,
  dates,
  setDates,
  inspector,
  material,
  tDate,
  yDate,
}) => {
  const dispatch = useDispatch();

  const {
    Users: { users },
    MaterialMaster: { materials },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(fetchAllMaterials());
    return () => { };
  }, [dispatch]);

  const handleDateStates = (e) => {
    handelState(dates, setDates, e.target.name, e.target.value);
  };
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  const todayDate = yyyy + '-' + mm + '-' + dd;

  const yesterday = new Date();
  const ydd = String(today.getDate()).padStart(2, '0');
  const ymm = String(today.getMonth()).padStart(2, '0'); //January is 0!
  const Yyyyy = yesterday.getFullYear();
  const YesterdayDate = Yyyyy + '-' + ymm + '-' + ydd;

  return (
    <>
      <div className="mb-3">
        <label className="col-form-label my-auto" htmlFor="lotMinRange">
          From:
        </label>
        <div>
          <input
            required
            type="date"
            ref={tDate}
            className="form-control"
            id="startDate"
            name="startDate"
            onChange={handleDateStates}
            defaultValue={startDate}
          />
        </div>
      </div>
      <div className="mb-3">
        <label className="col-form-label my-auto" htmlFor="endDate">
          To:
        </label>
        <div>
          <input
            required
            ref={yDate}
            className="form-control"
            type="date"
            id="endDate"
            name="endDate"
            onChange={handleDateStates}
            defaultValue={todayDate}
          />
        </div>
      </div>

      {material && (
        <div className="mb-3">
          <label className="col-form-label my-auto" htmlFor="MaterialId">
            Material:
          </label>
          <div>
            <select
              id="MaterialId"
              name="materialId"
              onChange={handleDateStates}
              className="form-select"
              aria-label="Select material "
            >
              <option value="">Select</option>
              {materials?.map((mat, idx) => (
                <option key={idx} value={mat?.id}>
                  {`${mat?.materialNo} - ${mat?.materialText}`}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {inspector && (
        <div className="mb-3">
          <label className="col-form-label my-auto" htmlFor="inspectorId">
            Inspectors:
          </label>
          <div>
            <select
              required
              id="inspectorId"
              name="inspectorId"
              onChange={handleDateStates}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="">Select</option>
              {users?.map((insU) => (
                <option key={insU?.id} value={insU?.id}>
                  {insU?.username}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default DataFilters;
