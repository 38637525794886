import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { API } from '../../config';
import {
  ERROR_MESS,
  REJECTION_REASON_CREATE,
  REJECTION_REASON_DELETE,
  REJECTION_REASON_UPDATE,
} from '../../constant';
import { SHOW_ERROR, SHOW_SUCCESS } from '../../utils/toastMessages';

const initialState = {
  allReasons: [],
  error: null,
  status: null,
};

export const fetchInspectionReleaseLog = createAsyncThunk(
  'get/release-inspection-log',
  async (page) => {
    try {
      const response = await API.releaseLog.getAll({
        key: page ? 'page' : '',
        value: page,
      });
      // // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data?.message);
      return error.response.data;
    }
  }
);

const ReleaseInspectionLogSlice = createSlice({
  name: 'ReleaseInspectionLog',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchInspectionReleaseLog.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchInspectionReleaseLog.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allReasons = action.payload;
      })
      .addCase(fetchInspectionReleaseLog.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
  },
});

// export const { postAdded, reactionAdded } = RejectedReasons.actions;

export default ReleaseInspectionLogSlice.reducer;
