import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setgCurrentForm,
  setCurrentNode,
  clearFileState,
} from "../../../Redux/features/AppUtilsSlice";
import { truncateText } from "../../../utils";
import { removeItemTree } from "../../../Redux/features/InspectionPlanSlice";

import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { CgAdd } from "react-icons/cg";
import {
  deleteStep,
  fetchOneStep,
  stepCleaner,
} from "../../../Redux/features/StepSlice";
import {
  deleteQCPoint,
  QCPointCleaner,
} from "../../../Redux/features/QCPointSlice";
import IsAuthorize from "../../common/IsAuthorize";

const Node = ({ item, hasChildren, level, onToggle, selected }) => {
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.Auth);

  const handelTreeClick = async (actionType) => {
    const NEW_ITEM = { ...item, actionType };
    // qc point
    if (level === 2) {
      if (actionType === "r") {
        let data = await dispatch(deleteQCPoint(NEW_ITEM));
        if (data?.payload !== undefined) {
          dispatch(removeItemTree(NEW_ITEM));
          dispatch(setgCurrentForm("/inspection-plan/step"));
          dispatch(
            setCurrentNode({
              actionType: "v",
              id: NEW_ITEM?.stepId,
              type: "step",
            })
          );
        }
      } else {
        dispatch(setgCurrentForm("/inspection-plan/qcpoint"));
        dispatch(clearFileState());
        dispatch(setCurrentNode(NEW_ITEM));
      }
    }

    // step
    if (level === 1) {
      if (actionType === "a") {
        dispatch(QCPointCleaner());
        dispatch(stepCleaner());
        dispatch(fetchOneStep(NEW_ITEM.id));
        dispatch(setgCurrentForm("/inspection-plan/qcpoint"));
        dispatch(setCurrentNode(NEW_ITEM));
        dispatch(clearFileState());
      } else if (actionType === "r") {
        dispatch(deleteStep(NEW_ITEM));
        // dispatch(removeItemTree(NEW_ITEM));
        dispatch(setgCurrentForm("/inspection-plan/view/"));
        dispatch(
          setCurrentNode({
            actionType: "v",
            id: NEW_ITEM?.planId,
            type: "plan",
          })
        );
      } else {
        dispatch(clearFileState());
        dispatch(setgCurrentForm("/inspection-plan/step"));
        dispatch(setCurrentNode(NEW_ITEM));
      }
    }
    if (level === 0) {
      if (actionType === "a") {
        dispatch(stepCleaner());
        dispatch(clearFileState());
        dispatch(setgCurrentForm("/inspection-plan/step"));
        dispatch(setCurrentNode(NEW_ITEM));
      } else {
        dispatch(clearFileState());
        dispatch(setgCurrentForm("/inspection-plan/view/"));
        dispatch(setCurrentNode(NEW_ITEM));
      }
    }
  };
  return (
    <div
      className={`pointer mx-n2 mt-n2 mb-2 inspection_plan_tree ${
        level === 0
          ? "tree_top_header"
          : level === 1
          ? "step_header"
          : level === 2
          ? "qc_point"
          : ""
      }`}
      style={{
        paddingLeft: ` ${level <= 1 ? `${0}px` : `${8}px`}`,
        // backgroundColor: `${level === 0 ? '#006AB7' : ''}`,
        // border: `${hasChildren ? 'none' : '1px solid #EEEEEE'}`,
        paddingTop: "7px",
        paddingBottom: "7px",
        overflow: "auto",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center "
        style={{ paddingLeft: `${level * 10}px` }}
      >
        <div className="d-flex align-items-center flex-grow-1">
          <div
            onClick={onToggle}
            className={`${hasChildren ? " " : "d-none"} pointer d-inline`}
          >
            {selected ? (
              <IoIosArrowDown size="1rem" />
            ) : (
              <IoIosArrowForward size="1rem" />
            )}
          </div>
          <div
            style={{
              justifyContent: "flexStart",
              flexWrap: "wrap",
            }}
            className="header w-100 ms-1"
            onClick={() => handelTreeClick("v")}
          >
            <p
              className={`d-inline pointer  ${level === 0 ? "text-white" : ""}`}
              title={item.label}
            >
              {truncateText(item.label, 20)}

              {}
            </p>
          </div>
        </div>
        <IsAuthorize>
          <div className="pointer d-inline d-flex align-items-center">
            <span
              style={{ display: "flex", paddingLeft: "12px" }}
              className={` ${level === 0 ? " " : "d-none"}`}
            >
              <CgAdd
                onClick={() => handelTreeClick("a")}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add Step"
              />
              <BiEditAlt
                onClick={() => handelTreeClick("e")}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit Inspection Plan"
                className="me-1 ms-2"
              />
            </span>

            <span
              className={`d-flex align-items-center ${
                level === 1 ? " " : "d-none"
              }`}
            >
              <CgAdd
                onClick={() => handelTreeClick("a")}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add QC Point"
              />
              <BiEditAlt
                onClick={() => handelTreeClick("e")}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit Step"
                className="mx-1"
              />
              <RiDeleteBin6Line
                onClick={() => handelTreeClick("r")}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete Step"
                className="me-1"
                // color="#AA4A44"
              />
            </span>

            <span
              className={`d-flex align-items-center ${
                level === 2 ? " " : "d-none"
              }`}
            >
              <BiEditAlt
                onClick={() => handelTreeClick("e")}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit QC Point"
                className="me-1"
                // color="#0000001A"
              />
              <RiDeleteBin6Line
                onClick={() => handelTreeClick("r")}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete QC Point"
                className="me-1"
                // color="#AA4A44"
              />
            </span>
          </div>
        </IsAuthorize>
        {/* <span className={`${hasChildren ? "d-none" : ""} pointer float-end`}>
        <FaEdit
          onClick={() => handelTreeClick("e")}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="edit"
          className="ms-2"
          color="#0000001A"
        />
      </span> */}
      </div>
    </div>
  );
};

export default Node;
// {/* <i className='bx bx-chevron-right'></i> */ }

// {/* <i className='bx bx-chevron-down' ></i> */}
