import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MdTableView } from "react-icons/md";

import { RiDeleteBin6Line } from "react-icons/ri";
import TableLoading from "../../../common/SkeltonLoading/TableLoading";
import { useDispatch, useSelector } from "react-redux";
import MainBreadcrum from "../../../layout/MainBreadcrum";
import DeletePopup from "../../../common/DeletePopup";
import DataTableWithExcel from "../../../common/DataTableWithExcel";
import { BsEyeFill } from "react-icons/bs";
import IsAuthorize from "../../../common/IsAuthorize";
import { Button } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { fetchInspectionReleaseLog } from "../../../../Redux/features/ReleaseInspectionLogSlice";
import moment from "moment/moment";

const InspectionLog = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [getId, setId] = useState(null);
  const [classVal,setClassVal] = useState("");
  const [reasjectionReason, setRejectionReason] = useState([]);

  const dispatch = useDispatch();

  const {allReasons} = useSelector((state) => state.ReleaseInspectionLog);
  const { permissions, isFreeRegister } = useSelector((state) => state.Auth);

  console.log("Releasss",allReasons);
  useEffect(() => {
    dispatch(fetchInspectionReleaseLog());
  }, [dispatch]);

//   useEffect(() => {
//     setRejectionReason(allReasons?.filter((reason) => reason.parent == ""));
//   }, [allReasons]);

  const ref = useRef();
  const makeFullscreen = () => {
    let elem = ref?.current;
    console.log("hello",elem);
    
    if (elem?.requestFullscreen) {
        elem?.requestFullscreen();
        setClassVal("card-body full-screen")
    } else if (elem?.webkitRequestFullscreen) {
        /* Safari */
        elem?.webkitRequestFullscreen();
        setClassVal("card-body full-screen")
    } else if (elem?.msRequestFullscreen) {
        /* IE11 */
        elem?.msRequestFullscreen();
        setClassVal("card-body full-screen")
    }
    else
    {
      setClassVal("card-body");
    }
  }

  // console.log(row.original?.id);
  const columnsData = React.useMemo(
    () => [
      {
        Header: "Lot id",
        disableFilters: true,
        accessor: "inspection_lot_id",
      },
      {
        Header: "Inspection Plan",
        disableFilters: true,
        accessor:(row) => row?.inspection_lot?.plan?.title,
      },
      {
        Header: "Inspector",
        disableFilters: true,
        accessor:(row) => row?.inspection_lot?.lotUsers?.map((item,index) => {
            return (
            item?.user?.username
            )
            // `${item?.user?.first_name}-${item?.user?.last_name}`
        }),
      },
      {
        Header: "Reason",
        disableFilters: true,
        accessor: "reason",
      },
      {
        Header: "Created At",
        disableFilters: true,
        accessor: (row) => moment(row?.created_at).format("DD-MM-YYYY hh:mm A"),
      },
      {
        Header: "Updated At",
        disableFilters: true,
        accessor: (row) => moment(row?.updated_at).format("DD-MM-YYYY hh:mm A"),
      }
    ],
    []
  );

  return (
    <>
      <div className="row">
        <MainBreadcrum
          // label="create"
          // permis="RejectionReason-Create"
          // path="/rejection-reasons/create"
          name="Release Inspection Log"
          subName=""
          links={[
            { path: "", title: "Admin",isParentLabel: true },
            { path: "", title: "Settings", isParentLabel: true },
            { path: "", title: "Release Inspection Log", activeLink: true },
          ]}
        //   links={
        //     isFreeRegister
        //       ? [
        //           {
        //             path: "",
        //             title: "Foundation",
        //             isParentLabel: true,
        //           },
        //           { path: "", title: "Rejection Reason", activeLink: true },
        //         ]
        //       : [
        //           {
        //             path: "",
        //             title: "Admin",
        //             isParentLabel: true,
        //           },
        //           { path: "", title: "Settings", isParentLabel: true },
        //           { path: "", title: "Rejection Reason", activeLink: true },
        //         ]
        //   }
        />
      </div>
      <div className="mt-3">
        <div className="mb-5">
          <div className="card">
              <Button
                  sx={{
                    width: "1px !important",
                    height: "1% !important",
                    marginLeft: "93% !important",
                    marginBottom: "1% !important",
                    marginTop: "1% !important",
                    color: "#0d488b !important",
                    minWidth : "30px"
                  }}
                  onClick={makeFullscreen}
                  >
                    <OpenInFullIcon />
              </Button>
                <div ref={ref} className={ref ? classVal : "card-body"}>
                <div className="">
                <DataTableWithExcel
                  columns={columnsData}
                  data={allReasons}
                //status={status}
                  pageName="inspectionLog"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InspectionLog;
