import * as React from 'react';

// import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

// export const data = {
//     labels,
//     datasets: [
//       {
//         type: 'line' ,
//         label: 'Dataset 1',
//         borderColor: 'rgb(255, 99, 132)',
//         borderWidth: 2,
//         fill: false,
//         data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       },
//       {
//         type: 'bar' ,
//         label: 'Dataset 2',
//         backgroundColor: 'rgb(75, 192, 192)',
//         data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//         borderColor: 'white',
//         borderWidth: 2,
//       },
//       {
//         type: 'bar' ,
//         label: 'Dataset 3',
//         backgroundColor: 'rgb(53, 162, 235)',
//         data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       },
//     ],
//   };

// export const data = {
//   labels,
//   datasets: [
//     {
//         type: 'scatter' ,
//         label: 'UCL',
//         backgroundColor: 'rgb(255, 99, 132)',
//         data: labels.map(() => faker.number.int({ min: 800, max: 1000 })),
//     },    
//     {
//         type: 'line' ,
//         label: 'Value',
//         borderColor: 'rgb(250, 222, 42)',
//         backgroundColor: 'rgb(250, 222, 42)',
//         borderWidth: 2,
//         fill: false,
//         data: labels.map(() => faker.number.int({ min: 500, max: 800 })),
//     },
//     {
//         type: 'scatter' ,
//         label: 'LCL',
//         backgroundColor: 'rgb(255, 99, 132)',
//         data: labels.map(() => faker.number.int({ min: 200, max: 500 })),
//     },
//   ],
// };
// export const labels = ['January'];

    export const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    export default function QCControlLimit(props) {
    console.log("control limit", props);
    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [controlData, setControlData] = React.useState({
        labels,
        datasets: [
          {
              type: 'line' ,
              label: 'UCL',
              backgroundColor: 'rgb(255, 99, 132)',
              // color: 'rgb(204, 204, 220)',
            //   data: props?.data?.ucl ? props?.data?.ucl : 0
                data: props?.data?.ucl ? labels.map((item,index) => props?.data?.ucl[index] ) : 0

            //   data: labels.map(() => faker.datatype.number({ min: 700, max: 700 })),
          },    
          {
            type: 'line' ,
            label: 'Value',
            borderColor: 'rgb(250, 222, 42)',
            backgroundColor: 'rgb(250, 222, 42)',
            borderWidth: 2,
            fill: false,
            // data: props?.data?.val ? props?.data?.val : 0
            data: props?.data?.val ? labels.map((item,index) => props?.data?.val[index] ) : 0

            // data: labels.map(() => faker.datatype.number({ min: 400, max: 600 })),
          },
          {
            type: 'line' ,
            label: 'LCL',
            backgroundColor: 'rgb(255, 99, 132)',
            //   color: 'rgb(204, 204, 220)',
            //   data: props?.data?.lcl ? props?.data?.lcl : 0
            data: props?.data?.lcl ? labels.map((item,index) => props?.data?.lcl[index] ) : 0

            // data: labels.map(() => faker.datatype.number({ min: 200, max: 300 })),
          },
        ]
    });

    // const data = {
    //     labels,
    //     datasets: [
    //       {
    //           type: 'line' ,
    //           label: 'UCL',
    //           backgroundColor: 'rgb(255, 99, 132)',
    //           // color: 'rgb(204, 204, 220)',
    //           data: props?.data?.ucl ? props?.data?.ucl : 0
    //         //   data: labels.map(() => faker.datatype.number({ min: 700, max: 700 })),
    //       },    
    //       {
    //         type: 'line' ,
    //         label: 'Value',
    //         borderColor: 'rgb(250, 222, 42)',
    //         backgroundColor: 'rgb(250, 222, 42)',
    //         borderWidth: 2,
    //         fill: false,
    //         data: props?.data?.val ? props?.data?.val : 0
    //         // data: labels.map(() => faker.datatype.number({ min: 400, max: 600 })),
    //       },
    //       {
    //         type: 'line' ,
    //         label: 'LCL',
    //         backgroundColor: 'rgb(255, 99, 132)',
    //       //   color: 'rgb(204, 204, 220)',
    //       data: props?.data?.lcl ? props?.data?.lcl : 0
    //         //   data: labels.map(() => faker.datatype.number({ min: 200, max: 300 })),
    //       },
    //     ],
    //   };

      React.useEffect(() => {
        setControlData({
            
                labels,
                datasets: [
                  {
                      type: 'line' ,
                      label: 'UCL',
                      backgroundColor: 'rgb(255, 99, 132)',
                      // color: 'rgb(204, 204, 220)',
                      data: props?.data?.ucl ? labels.map((item,index) => props?.data?.ucl[index]) : 0
                    //   data: labels.map(() => faker.datatype.number({ min: 700, max: 700 })),
                  },    
                  {
                    type: 'line' ,
                    label: 'Value',
                    borderColor: 'rgb(250, 222, 42)',
                    backgroundColor: 'rgb(250, 222, 42)',
                    borderWidth: 2,
                    fill: false,
                    data: props?.data?.val ? labels.map((item,index) => props?.data?.val[index] ) : 0
                    // data: labels.map(() => faker.datatype.number({ min: 400, max: 600 })),
                  },
                  {
                    type: 'line' ,
                    label: 'LCL',
                    backgroundColor: 'rgb(255, 99, 132)',
                    //   color: 'rgb(204, 204, 220)',
                    data: props?.data?.lcl ? labels.map((item,index) => props?.data?.lcl[index] ) : 0
                    //   data: labels.map(() => faker.datatype.number({ min: 200, max: 300 })),
                  },
                ],
              }
        )
      },[props])
  return (
    <>
    <div className="row align-items-center">
            <div className="col-md-12">
                <div className="d-flex justify-content-around">
                    <h5
                    className="fs-4"
                    style={{ fontFamily: 'Rubik, sans-serif' }}
                    >
                        Qc Control limit
                    </h5>
                </div>
            </div>
            {/* <Chart type='bar' data={controlData} /> */}
            {console.log("print",controlData)}
            {controlData?.datasets?.length > 0 ? (
            // <div className="col-md-12 text-center">
                <div>
            <Chart
                // style={{ overflowX: 'auto', overflowY: 'auto' }}
                data={controlData}
                type="bar"
                // height={300}
                // width="100%"
                />
            </div>
            ) : (
            // null
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "85%" }}
                >
                <h2>No Results Found</h2>
                </div>
            )}
        </div>
    {/* <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor:"rgb(17, 18, 23)", marginTop:"5%" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead >
            <TableRow >
              {columns.map((column) => (
                <TableCell
                sx={{backgroundColor:"rgb(17, 18, 23)",color:"rgb(204, 204, 220)"}}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow  tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell sx={{color:"rgb(204, 204, 220)"}} key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{color:"rgb(204, 204, 220)"}}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper> */}
    </>
  );
}

