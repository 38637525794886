import React,{useState, useEffect} from 'react'
import ReactApexChart from 'react-apexcharts';

const HorizontalBarChart = (props) => {
  console.log("ar cart",props);
  // const [chartData, setChartData] = useState(
  //   {
  //         titles: props?.data?.Titles,
  //         count: props?.data?.LotCount 
  //         }
  // );

    const [barData, setBarData ]= useState({
        series: [{
          data: props?.data?.LotCount ? props?.data?.LotCount : 0
          // data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
            horizontal: 'true',
              dataLabels: {
                position: 'top'
              },
            },
            },
            dataLabels: {
              enabled: true,
              offsetX: 28,
              style: {
                  colors: ['#333']
              },
            },
          
          xaxis: {
            categories: props?.data?.Titles ? props?.data?.Titles : "",
            // categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
            //   'United States', 'China', 'Germany'
            // ],
          }
        },  
    });

    useEffect(() => {
        // setChartData({
        // titles: props?.data?.Titles,
        // count: props?.data?.LotCount 
        // });
        setBarData({
          series: [{
            data: props?.data?.LotCount ? props?.data?.LotCount : 0
            // data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
          }],
          options: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
              horizontal: 'true',
                dataLabels: {
                  position: 'top'
                },
              },
              },
              dataLabels: {
                enabled: true,
                offsetX: 28,
                style: {
                    colors: ['#333']
                },
              },
            
            xaxis: {
              categories: props?.data?.Titles ? props?.data?.Titles : "",
              // categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
              //   'United States', 'China', 'Germany'
              // ],
            }
          },  
      })
    },[props])

  return (
        <div className="row align-items-center">
            <div className="col-md-12">
                <div className="d-flex justify-content-around">
                    <h5
                    className="fs-4"
                    style={{ fontFamily: 'Rubik, sans-serif' }}
                    >
                      {props?.chart === 1 ? "Top 5 plans with maximum inspection lots" :
                      props?.chart === 2 ? "Inspectors for Material" :
                      "Top 10 Rejected Lots by Inspection Plan"}
                    
                    </h5>
                </div>
            </div>
            {barData?.series[0]?.data?.length > 0 ? (
            <div className="col-md-12 text-center" id="chart">
            <ReactApexChart
                style={{ overflowX: 'auto', overflowY: 'auto' }}
                options={barData?.options}
                series={barData?.series}
                type="bar"
                height={300}
                width="100%"
                />
            </div>
            ) : (
            null
            )}
        </div>
    )
};

export default HorizontalBarChart;