import React, { useState } from 'react';
import Node from '../Node';

const Branch = ({ item, level, index, isInitialRender }) => {
  const [selected, setSelected] = useState(isInitialRender && item?.type === 'step' ? false: item.selected  ?? true);
  const hasChildren = item.children && item.children.length !== 0;

  const renderBranches = () => {
    if (hasChildren) {
      let newLevel = 1;
      if (item?.type === 'qc') {
        newLevel = 3;
      } else if (item?.type === 'step') {
        newLevel = 2;
      }
      console.log("QC point test -->", item?.children, item, hasChildren)
      return item.children.map((child, index) => {
        return <Branch key={child.id} item={child} level={newLevel} index={index} isInitialRender={true} />;
      });
    }

    return null;
  };

  const toggleSelected = () => {
    setSelected((prev) => !prev);
  };

  return (
    <>
      {/* it will show in small letter step and qc points */}
      <p style={{ color: "#858484", fontSize: "11px", paddingLeft: `${level * 10}px` }}> {item.type === "step" ? `Step ${index + 1}` : item.type === 'qc' && index === 0 ? 'QC Points' : ""}</p>
      
      <Node
        item={item}
        selected={selected}
        hasChildren={hasChildren}
        level={level}
        onToggle={toggleSelected}
      />

      {selected && renderBranches()}
    </>
  );
};

export default Branch;
