import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../../Redux/features/GraphsSlice";

const InspectionLotChart = (props) => {
  const dispatch = useDispatch();
  const {
    Graphs: { dashboard },
  } = useSelector((state) => state);

  const [pieData, setPieData] = useState({
    series: [44, 55, 13, 43],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: [],
      colors: ["#00ff00", "#e06666", "#6d9eeb", "#ffd966", "#A020F0"],
      legend: {
        show: true,
      floating: false,
      position: 'bottom',
      //   markers: {
      //     width: 12,
      //     height: 12,
      //     strokeWidth: 0,
      //     strokeColor: '#000000',
      //     fillColors: undefined,
      //     radius: 12,
      //     customHTML: undefined,
      //     onClick: undefined,
      //     offsetX: 0,
      //     offsetY: 0
      // }
      // hover: false,
      },
      plotOptions: {
        pie: {
          // donut: {
          //   labels: {
          //     show: true,
          //     name: { show: true },
          //   },
          // },
          total: {
            show: true,
          },
        },
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  });

  const yesterday = new Date();
  const ymm = String(yesterday.getMonth() + 1).padStart(2, "0"); //January is 0!
  const Yyyyy = yesterday.getFullYear();
  const YesterdayDate = Yyyyy + "-" + ymm;
  const YesterdayDateY = Yyyyy + "-" + "01";
  const [startDate, setStartDate] = useState("");
  const firstMDate = Yyyyy + "-" + ymm;
  const firstYDate = Yyyyy + "-" + "01";
  // //console.log("date", YesterdayDate);
  useEffect(() => {
    setStartDate(!props.MTD ? firstYDate : firstMDate);
  }, [props.MTD]);
  useEffect(() => {
    setPieData({
      ...pieData,
      series: Object.values(dashboard)
        .map((item) => item)
        .slice(0, -1),
      options: {
        ...pieData?.options,
        labels: Object.keys(dashboard)
          .map((item) => item)
          .slice(0, -1),
        // colors: inspectionLot?.data?.pieColors,
      },
    });
  }, [dashboard]);

  // useEffect(() => {
  //   console.log('startDate1', startDate);
  //   if (startDate !== '') {
  //     dispatch(getDashboardData(startDate));
  //   }
  // }, [startDate]);
  useLayoutEffect(() => {
    //  console.log('startDate1', startDate);
    //  if (startDate !== '') {
    if (props.MTD) {
      dispatch(getDashboardData(YesterdayDate));
    } else dispatch(getDashboardData(YesterdayDateY));
    //  }
  }, []);
  return (
    // <div style={{ height: "300px" }}>
    <div className="row align-items-center">
        <div className="col-md-12">
            <div className="d-flex justify-content-around">
                <h5
                className="fs-3"
                style={{ fontFamily: 'Rubik, sans-serif' }}
                >
                    Inspection Lots : All Materials
                </h5>
            </div>
        </div>
      {pieData?.series?.filter((el) => el === 0)?.length !==
      pieData?.series?.length ? (
        <div className="col-md-12 text-center" id="chart">
        <ReactApexChart
          options={pieData.options}
          series={pieData.series}
          type="pie"
          width="100%"
          height="400"
        />
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "85%" }}
        >
          <h2>No Results Found</h2>
        </div>
      )}
    </div>
  );
};

export default InspectionLotChart;