import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import ReactApexChart from 'react-apexcharts';

const DefectInspectionModal = ({ handleClose, open, barData }) => {
  // //console.log(id);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #69809a',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={style} className="rounded bg-white">
          <Typography
            className="text-center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Defected Rejected Lots
          </Typography>
          {/* <div className="text-center" id="chart"> */}
          <ReactApexChart
            style={{ overflowX: 'auto', overflowY: 'hidden' }}
            options={barData?.options}
            series={barData?.series}
            height={550}
            type="line"
            width="100%"
          />
          {/* </div> */}
        </Box>
      </Fade>
    </Modal>
  );
};

export default DefectInspectionModal;
