import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
// import { getInsoectionLotData } from '../../../../../Redux/features/GraphsSlice';
import { getInsoectionLotData } from '../../../../../Redux/features/GraphsSlice';
import DataFilters from './components/DateFilters';


const InspectionLot = (props) => {
  const dispatch = useDispatch();
  const {
    Graphs: { inspectionLot },
  } = useSelector((state) => state);

  const [pieData, setPieData] = useState({
    series: [44, 55, 13, 43],
    chartOptions: {
      chart: {
        type: 'donut',
      },
      labels: [],
      colors: ['#00ff00', '#e06666', '#6d9eeb', '#ffd966', '#A020F0'],
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: { show: true },
            },
          },
          total: {
            show: true,
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });

  const date = useRef();
  const yDate = useRef();
  const [createGraphData, setcreateGraphData] = useState({
    startDate: '',
    endDate: '',
    materialId: '',
  });

  useEffect(() => {
    // console.log('pieData test', pieData);
    setPieData({
      ...pieData,
      // exclude last element from array and set it in series
      series: Object.values(inspectionLot)
        .map((item) => item)
        .slice(0, -1),
      chartOptions: {
        ...pieData?.chartOptions,
        labels: Object.keys(inspectionLot)
          .map((item) => item)
          .slice(0, -1),
      },
    });
  }, [inspectionLot?.data?.pieValue, inspectionLot?.data?.pielabel]);

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  const todayDate = yyyy + '-' + mm + '-' + dd;

  const yesterday = new Date();
  const ydd = String(today.getDate()).padStart(2, '0');
  const ymm = String(today.getMonth()).padStart(2, '0'); //January is 0!
  const Yyyyy = yesterday.getFullYear();
  const YesterdayDate = Yyyyy + '-' + ymm + '-' + ydd;
  const [startDate, setStartDate] = useState('');
  const firstMDate = Yyyyy + '-' + mm + '-' + '01';
  const firstYDate = Yyyyy + '-' + '01' + '-' + '01';
  const newData = {};
  // console.log('todate', todayDate);
  useEffect(() => {
    setStartDate(!props.MTD ? firstYDate : firstMDate);
  }, [props.MTD]);
  useEffect(() => {
    // console.log('StartDate', startDate);
    if (startDate !== '') {
      dispatch(
        getInsoectionLotData({
          startDate: startDate,
          endDate: todayDate,
          materialId: '',
        })
      );
      // YesterdayDate = startDate;
    }
  }, [startDate]);

  const handleSubmit = () => {
    const finalData = {
      ...createGraphData,
      startDate: date.current.value,
      endDate: yDate.current.value,
    };
    dispatch(getInsoectionLotData(finalData));
    // console.log();
  };

  return (
    <div className="row align-items-center">
      <div className="col-md-4">
        <h2 className="mb-4 fs-3" style={{ fontFamily: 'Rubik, sans-serif' }}>
          Inspection Lot
        </h2>
        <DataFilters
          startDate={startDate}
          dates={createGraphData}
          tDate={date}
          yDate={yDate}
          setDates={setcreateGraphData}
          inspector={false}
          material={true}
        />
        <div className={`col-12 align-self-end`}>
          <button onClick={handleSubmit} className="btn btn-primary">
            Apply
          </button>
        </div>
      </div>
      {/* {console.log('check material', pieData)} */}
      <div className="col-md-8 text-center" id="chart">
        <ReactApexChart
          options={pieData?.chartOptions}
          series={pieData?.series}
          // labels={pieData?.labels}
          type="donut"
          width="100%"
          height={300}
        />
      </div>
      {/* <div
          style={{ height: "100%", overflow: "auto" }}
          className="card p-3 col-md-6 mt-4"
        >
          <table className="table table-responsive text-nowrap ">
            <thead>
              <th>#</th>
              <th>Label</th>
              <th>Value</th>
            </thead>
            <tbody className="">
              {inspectionLot?.pielabel?.map((l, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{l}</td>
                  <td>{inspectionLot?.pieData[l]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}

      {/* <span>{JSON.stringify(inspectionLot)}</span> */}
    </div>
  );
};

export default InspectionLot;
