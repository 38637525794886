import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const customId = "custom-id-yes";

const toaster = (message, type) => {
    if (type === 'success') {
        return toast.success(
            message,
            {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                toastId: customId,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        )
    }
    if (type === 'info') {
        toast.info(
            message,
            {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                toastId: customId,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        )
    }
    if (type === 'warning') {
        toast.warn(
            message,
            {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                toastId: customId,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        )
    }
    if (type === 'dark') {
        toast.dark(
            message,
            {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                toastId: customId,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        )
    }
    if (type === 'error') {
        toast.error(
            message,
            {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                toastId: customId,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        )
    }
    return toast(
        message,
        {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            toastId: customId,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        }
    )

}
export default toaster
