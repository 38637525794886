import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API } from "../../config";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/toastMessages";
import { ERROR_MESS } from "../../constant";
const initialState = {
  myAllGroups: [],
  allGroups: [],
  oneGroup: { groupUsers: [], name: "" },
  paginationData: {},
  updateData: { users: [], name: "" },
  isDisable: true,
  error: null,
  status: null,
};

export const fetchMyAllGroups = createAsyncThunk(
  "get/workflow-approval-group-user",
  async () => {
    try {
      const response = await API.WorkFlowGroup.getMy();

      // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data?.message);
      return error.response.data;
    }
  }
);

export const fetchAllGroups = createAsyncThunk(
  "get/workflow-approval-group",
  async () => {
    try {
      const response = await API.WorkFlowGroup.getAll();
      // // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data?.message);
      return error.response.data;
    }
  }
);

export const createGroup = createAsyncThunk(
  "post/WorkFlowGroup",
  async (formData) => {
    try {
      // //console.log("data from body => ", data.data);
      const response = await API.WorkFlowGroup.add(formData.updateData);
      const { success, message, data } = response.data;
      formData?.navigate("/group/list");
      SHOW_SUCCESS(success, "Approval Group created successfully.");

      return {
        success,
        data,
        code: 201,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateOneGroup = createAsyncThunk(
  "update/oneGroup",
  async (formdData) => {
    try {
      // // // //console.log("from slice :", data);
      const response = await API.WorkFlowGroup.update(
        formdData.id,
        formdData.updatedGroup
      );
      formdData?.navigate("/group/list");
      const { success, message, data } = response.data;

      SHOW_SUCCESS(success, "Approval Group updated successfully.");

      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const deleteGroup = createAsyncThunk("delete/oneGroup", async (data) => {
  try {
    const response = await API.Groups.delete(data);
    // // // //console.log(response.data);
    if (response.data.success) {
      toast.success("Group deleted successfully !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return data;
  } catch (error) {
    // // // //console.log(error.response.data.message);
    if (error) {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return error.response.data;
  }
});

export const deleteWorkFlowApprovalGroup = createAsyncThunk(
  "delete/workflow-approval-group",
  async (data) => {
    //console.log("data", data);
    try {
      const response = await API.WorkFlowGroup.delete(data);
      // // //console.log(response.data);
      if (response.data.success) {
        toast.success("Approval Group deleted successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // onResponse?.(response.data.data);
        return data;
      }
    } catch (error) {
      // // //console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      // console.log("error => ", error.response.data);
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 404) {
        SHOW_ERROR(true, message);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return error.response.data;
    }
  }
);

export const fetchOneGroupData = createAsyncThunk(
  "get/aproval_group",
  async (id) => {
    try {
      const response = await API.WorkFlowGroup.get(id);
      const { success, message, data } = response.data;

      // SHOW_SUCCESS(success, message);

      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const GroupSlice = createSlice({
  name: "Group",
  initialState,
  reducers: {
    addToUserApprover: (state, action) => {
      // let groupData = [...state.oneGroup.groupUsers];
      // let newGroupUser

      console.log("Ascu07070ac", action);
      state.isDisable = false;

      state?.oneGroup?.groupUsers?.push(action?.payload);

      let newGroupUsers = state.oneGroup.groupUsers.map((GU, idx, arr) => {
        if (arr.length - 1 === idx) {
          return { ...GU, serialNo: idx + 1, role: "A" };
        } else {
          return { ...GU, serialNo: idx + 1, role: "R" };
        }
      });
      state.oneGroup.groupUsers = newGroupUsers;

      let users = state.oneGroup.groupUsers.map((GU, idx, arr) => {
        if (arr.length - 1 === idx) {
          return { userId: GU.user_id, serialNo: idx + 1, role: "A" };
        } else {
          return { userId: GU.user_id, serialNo: idx + 1, role: "R" };
        }
      });

      state.updateData = { name: state.oneGroup.name, users };
    },
    removeToUserApprover: (state, action) => {
      // let groupData = [...state.oneGroup.groupUsers];
      // //console.log("groupData => ", groupData);

      state.oneGroup.groupUsers = state.oneGroup.groupUsers.filter(
        (GU) => GU.user_id != action.payload
      );

      let newGroupUsers = state.oneGroup.groupUsers.map((GU, idx, arr) => {
        if (arr.length - 1 === idx) {
          return { ...GU, serialNo: idx + 1, role: "A" };
        } else {
          return { ...GU, serialNo: idx + 1, role: "R" };
        }
      });

      state.oneGroup.groupUsers = newGroupUsers;

      let users = state.oneGroup.groupUsers.map((GU, idx, arr) => {
        if (arr.length - 1 === idx) {
          return { userId: GU.user_id, serialNo: idx + 1, role: "A" };
        } else {
          return { userId: GU.user_id, serialNo: idx + 1, role: "R" };
        }
      });
      if (state.oneGroup.groupUsers?.length === 0) {
        state.isDisable = true;
      } else {
        state.isDisable = false;
      }
      state.updateData = { name: state.oneGroup.name, users };
    },

    updateGroupName: (state, action) => {
      state.isDisable = false;
      state.oneGroup.name = action.payload;
      state.updateData.name = action.payload;
    },
    UPDATE_USERS_IN_GROUP: (state, action) => {
      const newGroupUsers = action.payload.map((user, index) => {
        return {
          groupId: user.groupId,
          id: user.id,
          serialNo: user.serialNo,
          role: user.role,
          user: user.user,
          user_id: user.user_id,
        };
      });
      state.oneGroup.groupUsers = newGroupUsers;
      const users = newGroupUsers.map((user, index) => ({
        userId: user.user_id,
        serialNo: user.serialNo,
        role: user.role,
      }));
      state.isDisable = false;
      state.updateData = { name: action.payload[0].groupName, users };
    },
    EMPTY_GROUP_USERS: (state) => {
      state.oneGroup.groupUsers = [];
      state.updateData = { name: "", users: [] };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createGroup.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        const { code, data, success } = action.payload;
        if (success && code === 201) {
          state.status = "succeeded";
          state.isDisable = initialState.isDisable;
          state.oneGroup = initialState.oneGroup;
          state.updateData = initialState.updateData;
        } else {
          state.status = "failed";
        }
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(fetchAllGroups.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAllGroups.fulfilled, (state, action) => {
        state.allGroups = action.payload;
        state.oneGroup.groupUsers = [];
        state.status = "succeeded";
      })
      .addCase(fetchAllGroups.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(fetchMyAllGroups.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMyAllGroups.fulfilled, (state, action) => {
        state.myAllGroups = action.payload;
        // state.oneGroup.groupUsers = [];
        state.status = "succeeded";
      })
      .addCase(fetchMyAllGroups.rejected, (state, action) => {
        state.status = "failed";
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(deleteWorkFlowApprovalGroup.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteWorkFlowApprovalGroup.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allGroups = state.allGroups.filter(
          (group) => group.id !== action.payload
        );
      })
      .addCase(deleteWorkFlowApprovalGroup.rejected, (state, action) => {
        state.status = "failed";
        // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(fetchOneGroupData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOneGroupData.fulfilled, (state, action) => {
        const { code, data, success } = action.payload;

        if (success && code === 200) {
          state.status = "succeeded";
          state.oneGroup = data;

          let users = data.groupUsers.map((GU, idx, arr) => {
            if (arr.length - 1 === idx) {
              return { userId: GU.user_id, serialNo: idx + 1, role: "A" };
            } else {
              return { userId: GU.user_id, serialNo: idx + 1, role: "R" };
            }
          });

          state.updateData = { name: data.name, users };
          // //console.log("group data => ", data);
        } else {
          state.status = "failed";
        }
      })
      .addCase(fetchOneGroupData.rejected, (state, action) => {
        state.status = "failed";
        // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(updateOneGroup.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateOneGroup.fulfilled, (state, action) => {
        const { code, data, success } = action.payload;

        if (success && code === 200) {
          state.status = "succeeded";
          state.oneGroup = data;
          state.isDisable = true;
        } else {
          state.status = "failed";
        }
      })
      .addCase(updateOneGroup.rejected, (state, action) => {
        state.status = "failed";
        // // //console.log(action.error);
        state.error = action.payload;
      });
  },
});

export const {
  addToUserApprover,
  removeToUserApprover,
  updateGroupName,
  UPDATE_USERS_IN_GROUP,
  EMPTY_GROUP_USERS,
} = GroupSlice.actions;

export default GroupSlice.reducer;
