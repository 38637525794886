import React from "react";
import { API } from "../../config";
import DataTableWithExcel from "../common/DataTableWithExcel";
import IconButton from "@mui/material/IconButton";
import { BsEyeFill } from "react-icons/bs";
import FormDetailModal from "./FormDetailModal";
import MainBreadcrum from "../layout/MainBreadcrum";
import { useState, useRef } from "react";
import { Button } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const USerFilledForm = () => {
  const [data, setData] = React.useState([]);
  const [info, setInfo] = React.useState();
  const [open, setOpen] = React.useState();
  const [status, setstatus] = React.useState("loading");
  const [classVal,setClassVal] = useState("");

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handle = (e) => {
    handleOpen();
    setInfo(e);
  };

  const columnsData = React.useMemo(
    () => [
      {
        Header: "Id",
        disableFilters: true,
        accessor: "id",
      },
      {
        Header: "Title",
        disableFilters: true,
        accessor: "title",
      },
      {
        Header: "Filled By",
        disableFilters: true,
        Cell: ({ row }) => row?.original?.from_user?.username,
      },
      {
        Header: "CREATED AT",
        disableFilters: true,
        accessor: "created_at",
      },
      {
        Header: "UPDATED AT",
        disableFilters: true,
        accessor: "updated_at",
      },
      {
        Header: "Action",
        id: "action",
        disableFilters: true,
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              handle(row?.original);
            }}
          >
            <BsEyeFill />
          </IconButton>
        ),
      },
    ],
    []
  );

  const fullScreenIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ marginBottom: "1px" }} className="bi bi-fullscreen full_screen_icon" viewBox="0 0 16 16">
        <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
    </svg>

  const ref = useRef();
  const makeFullscreen = () => {
    let elem = ref?.current;
    console.log("hello",elem);
    
    if (elem?.requestFullscreen) {
        elem?.requestFullscreen();
        setClassVal("card-body full-screen")
    } else if (elem?.webkitRequestFullscreen) {
        /* Safari */
        elem?.webkitRequestFullscreen();
        setClassVal("card-body full-screen")
    } else if (elem?.msRequestFullscreen) {
        /* IE11 */
        elem?.msRequestFullscreen();
        setClassVal("card-body full-screen")
    }
    else
    {
      setClassVal("card-body");
    }
  }

  const GetallForm = async () => {
    setstatus("loading");
    const res = await API.userFilledForm.getAll();
    setstatus("succeeded");
    setData(res?.data?.data);
  };

  React.useEffect(() => {
    GetallForm();
  }, []);

  return (
    <div className="row">
        <MainBreadcrum
          name="User Filled Form"
          subName="Reports"
          links={[
            { path: "/", title: "" },
            {
              path: "/report/data-entry-form",
              title: "User Filled Form ",
              activeLink: true,
            },
          ]}
        />
        <div className="mt-3">
        <div className="mb-5">
          <div className="card">
                <Button
                  sx={{
                    width: "1px !important",
                    height: "1% !important",
                    marginLeft: "93% !important",
                    marginBottom: "1% !important",
                    marginTop: "1% !important",
                    color: "#0d488b !important",
                    minWidth : "30px"
                  }}
                  onClick={makeFullscreen}
                  >
                    <OpenInFullIcon />
              </Button>
                <div ref={ref} className={ref ? classVal : "card-body"}>
                <DataTableWithExcel status={status} columns={columnsData} data={data} />{" "}
                <FormDetailModal open={open} response={info} handleClose={handleClose} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default USerFilledForm;
