import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; //
import FormControlLabel from "@mui/material/FormControlLabel";
import { ImCross } from "react-icons/im";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import { useNavigate } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { fade, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import filterStyles from "./Filter.css";
import { Button } from "@mui/material";

import { ThreeDots } from "react-loader-spinner";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import { useRef } from "react";
import makeData from "./makeData";
import generateExcel from "zipcelx";
import { key } from "../../config";
import { Box, Typography } from "@mui/material";
import { truncateText } from "../../utils";
// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 0px 6px 16px", // <-- arbitrary value
    },
  },
});

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  handleMenuOpen,
  trueButton,
}) {
  const count = preGlobalFilteredRows?.length;
  const [value, setValue] = useState();
  const onChange = useAsyncDebounce((value) => {
    console.log("search val", value);
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="row mb-2 d-flex align-items-center">
      <div className="col-md-8 col-sm-6 col-1">
        <div></div>
      </div>
      <div className="col-md-4 col-sm-6 col-11">
        <div className="input-group rounded">
          <input
            type="search"
            className="form-control rounded"
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
          />
          <span
            className="input-group-text border-0 pointer"
            id="search-addon"
            data-toggle="tooltip"
            data-placement="top"
            title="Search Record"
          >
            <i className="fas fa-search"></i>
          </span>
          <div>
            {trueButton === true ? (
              <IconButton onClick={handleMenuOpen}>
                <FilterListIcon />
              </IconButton>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

const StyledInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 14,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <StyledInput
      margin="none"
      variant="outlined"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
      placeholder={`Search ${count} records...`}
    />
  );
}

function DataTableWithExcel({
  columns,
  data,
  permis,
  pageName,
  trueButton,
  userRole,
  path,
  status,
  isSimpleTable,
  lotReport,
  genForm,
  steps,
  name,
  isShowToolTip,
  toolTipData,
  preDefindToolTipData,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // const ref = useRef();

  // const makeFullscreen = () => {
  //   let elem = ref?.current;
  //   console.log("hello",elem);
  //   if (elem?.requestFullscreen) {
  //       elem?.requestFullscreen();
  //   } else if (elem?.webkitRequestFullscreen) {
  //       /* Safari */
  //       elem?.webkitRequestFullscreen();
  //   } else if (elem?.msRequestFullscreen) {
  //       /* IE11 */
  //       elem?.msRequestFullscreen();
  //   }
  // }
  const classes = useStyles();
  const { paginations } = useSelector((state) => state.CommonOperations);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const uniquePlantNamesMap = {};
  const uniqueMaterialMap = {};

  // Loop through the data array and add plant names to the object
  data.forEach((item) => {
    if (item.plant && item.plant.plantName) {
      uniquePlantNamesMap[item.plant.plantName] = true;
    }
  });
  data.forEach((item) => {
    if (item.material && item.material.materialText) {
      uniqueMaterialMap[item.material.materialText] = true;
    }
  });

  // Convert object keys to an array to get distinct plant names
  const distinctPlantNames = Object.keys(uniquePlantNamesMap);
  const distinctMaterialNames = Object.keys(uniqueMaterialMap);

  const uniqueUsernamesMap = {};

  data.forEach((item) => {
    if (item.assignUsers && item.assignUsers.length > 0) {
      const user = item.assignUsers[0]; // Assuming there's only one user in the array
      if (user.username) {
        uniqueUsernamesMap[user.username] = true;
      }
    }
  });

  // Convert object keys to an array to get distinct usernames
  const distinctUsernames = Object.keys(uniqueUsernamesMap);
  const uniqueUsageDecisionPrintsMap = {};

  data.forEach((item) => {
    const usageDecisionPrint =
      item.usageDecisionPrint || "Unknown Usage Decision";
    uniqueUsageDecisionPrintsMap[usageDecisionPrint] = true;
  });

  const distinctUsageDecisionPrints = Object.keys(uniqueUsageDecisionPrintsMap);

  const uniqueStatusMap = {};
  const uniqueFilledByMap = {};
  const uniqueFormNamesMap = {};

  data.forEach((item) => {
    // Collect distinct statuses
    if (item.status) {
      uniqueStatusMap[item.status] = true;
    }

    // Collect distinct filled by usernames
    if (item.user && item.user.username) {
      uniqueFilledByMap[item.user.username] = true;
    }

    // Collect distinct form names
    if (item.form && item.form.name) {
      uniqueFormNamesMap[item.form.name] = true;
    }
  });

  const distinctStatuses = Object.keys(uniqueStatusMap);
  const distinctFilledByUsernames = Object.keys(uniqueFilledByMap);
  const distinctFormNames = Object.keys(uniqueFormNamesMap);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function yourUsageDecisionFilterFunction(row, selectedUsageDecision) {
    const usageDecisionPrint =
      row.usageDecisionPrint || "Unknown Usage Decision";
    return usageDecisionPrint === selectedUsageDecision;
  }

  function yourMaterialFilterFunction(row, selectedMaterial) {
    const materialText = row.material?.materialText || "Unknown Material";
    return materialText === selectedMaterial;
  }
  function yourPlantFilterFunction(row, selectedPlant) {
    const plantName = row.plant?.plantName || "Unknown Plant";
    return plantName === selectedPlant;
  }
  function flowFormStatusFilterFunction(row, status) {
    const usageDecisionPrint = row?.status || "Unknown Usage Decision";
    return usageDecisionPrint === status;
  }
  function flowFormNameFilterFunction(row, name) {
    const FormName = row?.form?.name || "Unknown Name";
    return FormName === name;
  }
  function flowFormUserFilterFunction(row, username) {
    const user = row?.user?.username || "Unknown UserName";
    return user === username;
  }
  const [Status, setStatus] = React.useState("");
  const [Plant, setPlant] = React.useState("");
  const [User, setUser] = React.useState("");
  const [Material, setMaterial] = React.useState("");
  const [Review, setReview] = React.useState("");
  const [General, setGeneral] = React.useState("");
  const [FilledBy, setFilledBy] = React.useState("");
  const [FormName, setFormName] = React.useState("");

  const [StatusFlowForm, setStatusFlowForm] = React.useState("");
  const [isUpdatedDateRange, setIsUpdatedDateRange] = React.useState(true);
  const [isUpdatedDateSelected, setIsUpdatedDateSelected] =
    React.useState(false);

  const [updatedDate, setUpdatedDate] = useState([
    {
      startDate: null,
      endDate: new Date(""),
      key: "selection",
    },
  ]);

  const handleChangeFilledBy = (event) => {
    setFilledBy(event.target.value);
  };
  const handleChangeStatusFlowForm = (event) => {
    setStatusFlowForm(event.target.value);
  };
  const handleChangeFormName = (event) => {
    setFormName(event.target.value);
  };

  //filterforInspectionlotreport
  const handleChangesetStatus = (event) => {
    setStatus(event.target.value);
  };
  const handleChangesetPlant = (event) => {
    setPlant(event.target.value);
  };
  const handleChangesetUser = (event) => {
    setUser(event.target.value);
  };
  const handleChangesetMaterial = (event) => {
    setMaterial(event.target.value);
  };
  const handleChangesetReview = (event) => {
    setReview(event.target.checked ? "Reviews" : "");
  };

  const handleChangesetGeneral = (event) => {
    setGeneral(event.target.checked ? "General" : "");
  };
  const closeUpdatedCalendar = () => {
    if (isUpdatedDateRange) {
      setIsUpdatedDateRange(true);
      setIsUpdatedDateSelected(false);
      setUpdatedDate([
        {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
      ]);
    } else {
      setIsUpdatedDateRange(true);
    }
  };
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    rows,
    // pagination stuff
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data: Array.isArray(data) ? data : [],
      defaultColumn, // Be sure to pass the defaultColumn option
      // filterTypes,
      initialState: { pageIndex: 0 },
      disableMultiSort: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      Plant ||
      User ||
      Status ||
      Material ||
      Review ||
      General ||
      StatusFlowForm ||
      FormName ||
      FilledBy ||
      updatedDate[0].startDate
    ) {
      setPageSize(data?.length);
    } else {
      setPageSize(paginations[pageName] ? paginations[pageName] : 10);
    }
  }, [
    Plant,
    User,
    Status,
    Material,
    Review,
    General,
    StatusFlowForm,
    FormName,
    FilledBy,
    updatedDate[0].startDate,
  ]);

  const { id, flag } = useParams();
  const navigate = useNavigate();

  // Render the UI for your table
  let isDataExist = false;

  return (
    <Paper style={{ boxShadow: "none" }}>
      {/* {data.length > 0 ? (
        <> */}

      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        handleMenuOpen={handleMenuOpen}
        trueButton={trueButton}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        component="div"
      >
        {pageName !== "showForms" ? (
          <MenuItem
            onClick={(event) => event.preventDefault()}
            component="div"
            disableRipple
            sx={{ padding: 0, backgroundColor: "transparent" }}
          >
            <Box>
              <Box
                sx={{
                  paddingLeft: "18px",
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <IconButton onClick={() => handleMenuClose()}>
                  <CancelIcon />
                </IconButton>
              </Box>
              <Box
                style={{
                  paddingLeft: "18px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Reviews"
                    onChange={handleChangesetReview}
                  />

                  <FormControlLabel
                    control={<Checkbox />}
                    label="General Form"
                    onChange={handleChangesetGeneral}
                  />
                </Box>
              </Box>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <FormControl sx={{ m: 1, width: 300 }} size="small">
                  <InputLabel id="demo-select-small-label">User</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={User}
                    label="Users"
                    onChange={handleChangesetUser}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {distinctUsernames.map((username) => (
                      <MenuItem key={username} value={username}>
                        {username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                  <InputLabel id="demo-select-small-label">Material</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={Material}
                    label="Material"
                    onChange={handleChangesetMaterial}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {distinctMaterialNames.map((material) => (
                      <MenuItem key={material} value={material}>
                        {material}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                  <InputLabel id="demo-select-small-label">Plant</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={Plant}
                    label="Plant"
                    onChange={handleChangesetPlant}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {distinctPlantNames.map((plant) => (
                      <MenuItem key={plant} value={plant}>
                        {plant}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    UserDecision
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={Status}
                    label="UserDecision"
                    onChange={handleChangesetStatus}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {distinctUsageDecisionPrints.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </MenuItem>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "330px",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <div
                style={{
                  display: "flex",
                  paddingTop: "14px",
                  paddingLeft: "10px",
                }}
              >
                <InputLabel id="status_id" className={filterStyles.textLabel}>
                  Filled on
                </InputLabel>
                <Box className={filterStyles.dateBoxStyle}>
                  <div className={filterStyles.date_fields}>
                    <span className={filterStyles.single_field}>
                      {isUpdatedDateSelected
                        ? moment(updatedDate[0]?.startDate).format("YYYY-MM-DD")
                        : "--"}
                    </span>{" "}
                    -{" "}
                    <span className={filterStyles.single_field}>
                      {isUpdatedDateSelected
                        ? moment(updatedDate[0]?.endDate).format("YYYY-MM-DD")
                        : "--"}
                    </span>{" "}
                  </div>
                </Box>

                <DateRangeIcon sx={{ color: "Black", wdith: "50px" }} />
                      </div> */}

              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <OutlinedInput
                  disabled
                  id="outlined-adornment-weight"
                  defaultValue="  Filled On"
                  endAdornment={
                    <InputAdornment position="end">
                      <DateRangeIcon sx={{ color: "Black" }} />
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>

              {isUpdatedDateRange ? (
                <div>
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => {
                      if (item.selection.startDate && item.selection.endDate) {
                        setUpdatedDate([item.selection]);
                      } else {
                        setUpdatedDate([]);
                      }
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={updatedDate}
                  />

                  <Button
                    sx={{
                      fontSize: "10px",
                      padding: "5px 0px",
                      minWidth: "45px !important",
                      marginRight: "10px",
                    }}
                    variant="outlined"
                    onClick={closeUpdatedCalendar}
                  >
                    Clear
                  </Button>
                  {/*     <Button
                sx={{
                  fontSize: "10px",
                  padding: "5px 0px",
                  minWidth: "45px !important",
                }}
                variant="contained"
                onClick={() => {
                  setIsUpdatedDateRange(false);
                  setIsUpdatedDateSelected(true);
                }}
              >
                Add
              </Button> */}
                </div>
              ) : null}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <IconButton
                  type="button"
                  sx={{ float: "rigth" }}
                  onClick={() => handleMenuClose()}
                >
                  <ImCross className="mx-1" size={"1rem"} />
                </IconButton>
              </div>
              <FormControl sx={{ m: 1, width: 300 }} size="small">
                <InputLabel id="demo-select-small-label">
                  StatusFlowForm
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={StatusFlowForm}
                  label="StatusFlowForm"
                  onChange={handleChangeStatusFlowForm}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {distinctStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                <InputLabel id="demo-select-small-label">FilledBy</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={FilledBy}
                  label="FilledBy"
                  onChange={handleChangeFilledBy}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {distinctFilledByUsernames.map((FilledBy) => (
                    <MenuItem key={FilledBy} value={FilledBy}>
                      {FilledBy}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                <InputLabel id="demo-select-small-label">FormName</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={FormName}
                  label="FormName"
                  onChange={handleChangeFormName}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {distinctFormNames.map((FormNames) => (
                    <MenuItem key={FormNames} value={FormNames}>
                      {FormNames}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
          </div>
        )}
      </Menu>

      <div className="data_table">
        <MaUTable
          className="table"
          // ref={ref}
          classes={{ root: classes.customTable }}
          {...getTableProps()}
          size="small" // dense table sizes
        >
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index1) => {
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  return (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      title={null}
                      key={index1}
                    >
                      {column.id !== "Action" ? column.render("Header") : null}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                      {/* Render the columns filter UI */}
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {/* {page?.map((row, index) => { */}
            {status === "loading" ? (
              <Box
                sx={{
                  position: "fixed !important",
                  top: "50% !important",
                  left: "55% !important",
                }}
              >
                <ThreeDots color="#000" />
              </Box>
            ) : page?.length > 0 ? (
              page
                ?.filter(
                  (row) =>
                    (!Plant || yourPlantFilterFunction(row.original, Plant)) &&
                    (!User ||
                      row.original.assignUsers.some(
                        (user) => user.username === User
                      )) &&
                    (!Status ||
                      yourUsageDecisionFilterFunction(row.original, Status)) &&
                    (!Material ||
                      yourMaterialFilterFunction(row.original, Material)) &&
                    (Review === "" || row.original.reviews.length > 0) &&
                    (General === "" || row.original.generalForms.length > 0) &&
                    (!StatusFlowForm ||
                      flowFormStatusFilterFunction(
                        row.original,
                        StatusFlowForm
                      )) &&
                    (!FormName ||
                      flowFormNameFilterFunction(row.original, FormName)) &&
                    (!FilledBy ||
                      flowFormUserFilterFunction(row.original, FilledBy)) &&
                    (!updatedDate[0].startDate ||
                      (new Date(row.original.createdAt).toLocaleDateString() >=
                        updatedDate[0].startDate.toLocaleDateString() &&
                        new Date(row.original.createdAt).toLocaleDateString() <=
                          updatedDate[0].endDate.toLocaleDateString()) ||
                      new Date(row.original.createdAt).toLocaleDateString() ===
                        updatedDate[0].startDate.toLocaleDateString())
                )

                .map((row, index) => {
                  const updatedData = [];

                  if (isShowToolTip) {
                    toolTipData?.map((el, keyIndex2) => {
                      if (row?.original[el.key]) {
                        isDataExist = true;
                        const value = row?.original[el.key];

                        updatedData.push({ key: el?.label, value: value });
                      }
                    });
                  }

                  let toolTipValues = updatedData.filter(
                    (item) => item.key.trim().toLowerCase() !== "lot quatity"
                  );

                  prepareRow(row);
                  return (
                    <TableRow
                      className={isShowToolTip ? "data_table_tooltip" : ""}
                      style={{ height: "40px" }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell, keyIndex3) => {
                        return (
                          <TableCell
                            key={keyIndex3}
                            onClick={() => {
                              if (
                                cell.column.Header === "Action" ||
                                cell.column.Header === "General Form" ||
                                cell.column.Header === "Reviews"
                              ) {
                                return null;
                              } else if (isSimpleTable) {
                                return null;
                              } else {
                                if (lotReport) {
                                  return navigate(
                                    path +
                                      `${row?.original.id}/${row?.original?.eventFlag}`
                                  );
                                } else if (steps) {
                                  return navigate(
                                    `/qc-report/${id}/steps/${row?.original?.id}`
                                  );
                                } else if (genForm) {
                                  return navigate(
                                    `/report/data-entry-form/filled/${row?.original?.id}`
                                  );
                                } else if (path) {
                                  if (
                                    row?.original?.userRole ===
                                      key?.defaultRoles?.Admin &&
                                    userRole ===
                                      key?.defaultRoles?.AdminCoordinator
                                  ) {
                                    return null;
                                  }
                                  return navigate(path + `${row.original.id}`);
                                }
                              }
                            }}
                            title={cell.value !== undefined ? cell?.value : ""}
                            // sx={{ height: '50px !important' }}
                            {...cell.getCellProps()}
                          >
                            {/* {cell.render("Cell")} */}
                            {console.log("row cell", cell.render("Cell"))}
                            {truncateText(
                              cell?.value !== undefined
                                ? cell?.value
                                : cell.render("Cell"),
                              30
                            )}
                          </TableCell>
                        );
                      })}

                      {isShowToolTip ? (
                        <div class="data_table_tooltip_text">
                          {toolTipValues?.map((el) => {
                            return (
                              <p className="pb-0 mb-0">
                                {" "}
                                {el.key} : {el.value}
                              </p>
                            );
                          })}
                          {!isDataExist
                            ? toolTipData?.slice(0, 3)?.map((el) => {
                                return (
                                  <p className="pb-0 mb-0"> {el.label} :</p>
                                );
                              })
                            : null}
                        </div>
                      ) : null}
                      {preDefindToolTipData &&
                      preDefindToolTipData?.length !== 0 ? (
                        <div class="data_table_tooltip_text">
                          {preDefindToolTipData[index]?.map((el) => {
                            if (!el.value) return;
                            isDataExist = true;
                            return (
                              <p className="pb-0 mb-0">
                                {" "}
                                {el.label} : {el.value}
                              </p>
                            );
                          })}
                          {!isDataExist
                            ? preDefindToolTipData[index]
                                ?.slice(0, 3)
                                ?.map((el) => {
                                  return (
                                    <p className="pb-0 mb-0"> {el.label} :</p>
                                  );
                                })
                            : null}
                        </div>
                      ) : null}
                    </TableRow>
                  );
                  // })}
                })
            ) : (
              <TableRow>
                <TableCell colspan={columns?.length}>
                  <Box
                    my={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="h4">No Results Found</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={
                  Plant ||
                  User ||
                  Status ||
                  Material ||
                  Review ||
                  General ||
                  StatusFlowForm ||
                  FormName ||
                  FilledBy ||
                  updatedDate[0].startDate
                    ? [rows.length]
                    : [10, 20, 30, 40, 50]
                }
                component="td"
                count={rows.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(e, p) => {
                  gotoPage(p);
                }}
                onRowsPerPageChange={(e) => {
                  if (pageName) {
                    dispatch({
                      type: "CHANGE_PER_PAGE_RECORD",
                      payload: {
                        page: pageName,
                        perPageRecord: Number(e.target.value),
                      },
                    });
                  }
                  setPageSize(Number(e.target.value));
                }}
                ActionsComponent={() => (
                  <TablePaginationActions
                    {...{
                      previousPage,
                      nextPage,
                      gotoPage,
                      canPreviousPage,
                      canNextPage,
                      pageCount,
                    }}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        {/* <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            min={1}
            max={pageOptions.length}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div> */}
      </div>
      {/* </>
      ) : null} */}
    </Paper>
  );
}

function TablePaginationActions({
  previousPage,
  nextPage,
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
}) {
  const handleFirstPageButtonClick = () => {
    gotoPage(0);
  };

  const handleBackButtonClick = () => {
    previousPage();
  };

  const handleNextButtonClick = () => {
    nextPage();
  };

  const handleLastPageButtonClick = () => {
    gotoPage(pageCount - 1);
  };

  return (
    <div style={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={!canPreviousPage}
        aria-label="first page"
        data-toggle="tooltip"
        data-placement="top"
        title="First Page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={!canPreviousPage}
        aria-label="previous page"
        data-toggle="tooltip"
        data-placement="top"
        title="Previous Page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!canNextPage}
        aria-label="next page"
        data-toggle="tooltip"
        data-placement="top"
        title="Next Page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={!canNextPage}
        aria-label="last page"
        data-toggle="tooltip"
        data-placement="top"
        title="Last Page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

export default DataTableWithExcel;
