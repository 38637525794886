import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { BsEyeFill } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";

import TableLoading from "../../../common/SkeltonLoading/TableLoading";
import { useDispatch, useSelector } from "react-redux";
import MainBreadcrum from "../../../layout/MainBreadcrum";
import {
  getChilrenCatogries,
  deleteChildCategory,
} from "../../../../Redux/features/CategorySlice";
import DataTableWithExcel from "../../../common/DataTableWithExcel";
import DeletePopup from "../../../common/DeletePopup";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdTableView } from "react-icons/md";
import UpdateApprovalCategories from "./UpdateApprovalCategories";
import IsAuthorize from "../../../common/IsAuthorize";

const ApprovalCategoriesChild = () => {
  const { Id } = useParams();
  const [getId, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [isEditPopup, setIsEditPopup] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();
  const isButtonDisabled = location.pathname.startsWith(
    "/approval-categories/child/"
  );

  console.log("acpacjpaojc", isButtonDisabled);

  const {
    Catogries: { childCategories, status },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getChilrenCatogries(Id));
  }, [dispatch]);

  console.log("child categories", childCategories);

  //   setRejectionReason((reasjectionReason) => [
  //     ...reasjectionReason,
  //     reason,
  //   ]);
  // }

  // const handlePageClick = async (data) => {
  //   // // //console.log(data.selected);
  //   // scroll to the top
  //   //window.scrollTo(0, 0)
  // };

  const columnsData = [
    {
      Header: "Id",
      disableFilters: true,
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
      disableFilters: true,
    },
    {
      id: "Action",
      Header: "Action",
      disableFilters: true,
      Cell: ({ row }) => (
        <IsAuthorize>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="dropdown-item hover-blue p-4 text-center me-4 rounded pointer"
              onClick={() => {
                setEditData(row?.original);
                setIsEditPopup(true);
              }}
            >
              <BiEditAlt size={"1rem"} />
            </button>

            <span
              className="dropdown-item hover-danger p-4 text-center me-4 rounded pointer"
              data-toggle="tooltip"
              data-placement="top"
              title="Delete"
              onClick={() => {
                setOpen(true);
                setId(row.original.id);
              }}
            >
              <RiDeleteBin6Line size={"1rem"} />
            </span>
          </div>
        </IsAuthorize>
      ),
    },
  ];
  return (
    <>
      <div className="row">
        <MainBreadcrum
          nav={true}
          path="/approval-categories/create"
          label="create"
          name="Approval Category"
          isButtonDisabled
          subName=""
          links={[
            { path: "", title: "Forms and Flows", isParentLabel: true },
            { path: "/approval-categories", title: "Approval Category" },
            { path: "", title: "Child", activeLink: true },
          ]}
        />
        {/* <div className="col-4 align-self-center text-end">
          <Link to="/approval-categories/create">
          <button type="button" className="btn btn-primary">
            Create
          </button>
          </Link>
        </div> */}
      </div>
      <div className="mt-3">
        <div className="mb-5">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2"></div>
              {status === "loading" ? (
                <TableLoading />
              ) : (
                <DataTableWithExcel
                  columns={columnsData}
                  data={childCategories}
                  pageName="approvalCategoriesChild"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <DeletePopup
        handleClose={() => setOpen(false)}
        open={open}
        deleteFun={deleteChildCategory}
        id={getId}
      />
      <UpdateApprovalCategories
        Id={Id}
        editData={editData}
        isEditPopup={isEditPopup}
        closePopup={() => setIsEditPopup(false)}
      />
    </>
  );
};

export default ApprovalCategoriesChild;
