import { Routes, Route, useNavigate, useRoutes } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import moment from "moment/moment";
// local imports
import { setgCurrentForm } from '../Redux/features/AppUtilsSlice';
import { PrivateRoutes, PublicRoutes } from './routes';
import Spinner from '../components/common/Spinner';
import FallBackLoading from '../components/common/FallBackLoading';
import {
  getCompantId,
  getToken,
  setTempCompantId,
  verifyToken,
} from '../utils';

import { getUserData, logoutUser } from '../Redux/features/AuthSlice';
import { API, key } from '../config';
import TableLoading from '../components/common/SkeltonLoading/TableLoading';

function AppRoutes() {
  const { pathname } = useLocation(); // <-- get current location being accessed
  const dispatch = useDispatch();

  const {
    Auth: { Authenticate, currentUser, userRole },
    CompanyManagement: { company },
  } = useSelector((state) => state);

  useEffect(() => {
    if (pathname?.match('inspection-plan')) {
      dispatch(setgCurrentForm('/inspection-plan/view/'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const navigate = useNavigate();
  const companyId = getCompantId();
  const TOKEN = getToken();

  useEffect(() => {
    if (verifyToken()) {
      setTempCompantId(companyId, TOKEN);
      var { exp } = jwt_decode(TOKEN);
      if (Date.now() >= exp * 1000) {
        dispatch(logoutUser());
      }
      if (Object.keys(currentUser).length === 0) {
        dispatch(getUserData({ token: TOKEN, navigate, from: pathname }));
      }
    }

    if (!verifyToken()) {
      dispatch(logoutUser());
      // // if (pathname.match('/register')) {
      // //   navigate('/register');
      // // } else 
      // if (pathname.match('/forgot')) {
      //   navigate('/forgot');
      // } else if (pathname.match('/compReg')) {
      //   navigate('/compReg');
      // } else if (pathname.match('/newPass')) {
      //   navigate('/newPass');
      // } else {
      //   navigate('/login', {
      //     state: { from: pathname, authKey: 'token-none' },
      //   });
      // }
    }

    //check if company?.plan?.[0]?.renewSubscriptionAt is less than today's date
    // console.log(company?.plans?.[0]?.renewSubscriptionAt > todayDate);
    //reverse company?.plan array
    let arr;
    if (company?.plans?.length > 0) {
      arr = [...company?.plans]?.reverse();
    }
    let todayDate = moment();
    let expiryDate = moment(arr?.[0]?.renewSubscriptionAt, "DD-MM-YYYY");

    // if (expiryDate < todayDate) {
    //
    //   if (pathname.match("/renew-plan")) {
    //     navigate("/renew-plan");
    //   } else {
    //     navigate("/plan-expired");
    //   }
    //
    // }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Authenticate, pathname, company]);

  useEffect(() => {
    API.settings.companyId();
    return () => { };
  }, [companyId]);

  useEffect(() => {
    if (pathname?.match('inspection-plan')) {
      dispatch(setgCurrentForm('/inspection-plan/view/'));
    }
  }, [pathname]);
  
  if (!Authenticate) {
    return <PublicRoutesRender />
  }

  // if (userRole === key.defaultRoles?.ReportViewer) {
  //  return <ReportViewerRoutesRender />
  // }

  return <PrivateRoutesRender />
}

export default AppRoutes;


function PublicRoutesRender() {
  return (
    <Routes>
      {PublicRoutes?.map((route) => {
        return <Route key={route?.path} exact path={route?.path} element={<Suspense fallback={<Spinner />}>{route.element}</Suspense>} />
      })}
    </Routes>
  )
}
function PrivateRoutesRender() {
  return (
    <Routes>
      {PrivateRoutes?.map((route) => {
        if (route?.children) {
          return (
            <Route key={route?.path} exact path={route?.path} element={<Suspense fallback={<TableLoading />}>{route.element}</Suspense>} >
              {route?.children?.length > 0 ? route?.children?.map((nestedRoute) => {
                return <Route
                  key={nestedRoute?.path}
                  path={nestedRoute.path}
                  index={nestedRoute.index}
                  element={<Suspense fallback={<TableLoading />}>{nestedRoute.element}</Suspense>} />
              }) : null}
            </Route>
          )
        } else {
          return <Route path={route?.path}
            element={<Suspense fallback={<h1>Loading...</h1>}>{route.element}</Suspense>} />
        }
      })}
    </Routes>
  )
}
