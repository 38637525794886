import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import LinkItem from "./LinkItem";
import SubMenuLinkItem from "./SubMenuLinkItem";
import { FaAngleRight, FaLock, FaAngleDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { key } from "../../../config";
import StaticBlurLink from "./StaticBlurLink";

const SubLinkItem = ({ link, label, icon, subMenu }) => {
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const { isFreeRegister, userRole, userPermissions } = useSelector(
    (state) => state.Auth
  );

  const renderLink = (sub) => {
    let isBlur = false;
    let isHideUserLink = false;
    // In free case, hide user link from Setting tab
    if (isFreeRegister && label === "Settings" && sub.label === "Users") {
      isHideUserLink = true;
    }

    // In standard case, hide user link from Foundation tab
    if (!isFreeRegister && label === "Foundation" && sub.label === "Users") {
      isHideUserLink = true;
    }

    // Hide approval form for manager
    if (
      userRole === key?.defaultRoles?.Manager &&
      sub.label === "Approval Form"
    ) {
      isHideUserLink = true;
    }

    // Blur Link if user has no permission

    if (
      !userPermissions?.includes(sub?.permission) &&
      sub?.permission !== "ApprovalForm"
    ) {
      isBlur = true;
    }

    //blur link if user role is 'flow and forms'
    if (
      !isFreeRegister &&
      userRole === key.defaultRoles?.FormsAndFlow &&
      (sub.label == "Approval Group" || sub.label === "Approval Category")
    ) {
      isBlur = true;
    }

    if (isHideUserLink) return null;
    if (isBlur) {
      return (
        <StaticBlurLink link={sub.link} label={sub.label} icon={sub.icon} />
      );
    }
    return (
      // <NavLink
      //   className='activeHeight'
      //   onClick={(e) => e.preventDefault}
      //   to={link}
      //   activeclassname="active"
      // >
      <LinkItem
        link={sub.link}
        label={sub.label}
        icon={sub.icon}
        parentLabel={label}
      />
      // </NavLink>
    );
  };

  let isShowLockIcon = false;
  let isBlur = false;
  if (
    isFreeRegister &&
    label !== "Dashboard" &&
    label !== "Foundation" &&
    label !== "Analytics"
  ) {
    isShowLockIcon = true;
  }

  //blur links if user role is 'report viewer'
  if (
    !isFreeRegister &&
    userRole === key.defaultRoles?.ReportViewer &&
    label !== "Analytics" &&
    label !== "Forms and Flows"
  ) {
    isBlur = true;
  }

  //blur links if user role is 'flow and forms'
  if (
    !isFreeRegister &&
    userRole === key.defaultRoles?.FormsAndFlow &&
    label !== "Forms and Flows" &&
    label !== "Analytics"
  ) {
    isBlur = true;
  }

  return (
    <>
      <div
        className={`menu-link align-middle ${isBlur ? "blur_text" : null}`}
        data-bs-toggle="collapse"
        onClick={() => setOpen(!open)}
      >
        {icon.includes("svg") ? (
          <img
            className="menu-icon tf-icons svg-icon"
            src={icon}
            alt={`${label} icon`}
          />
        ) : (
          <i className={icon} />
        )}

        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <span className="d-flex align-items-center">
            {label}
            {isShowLockIcon ? (
              <FaLock style={{ fontSize: "11px", marginLeft: "5px" }} />
            ) : null}
          </span>{" "}
          {open ? <FaAngleDown /> : <FaAngleRight />}{" "}
        </span>
      </div>

      {!isBlur ? (
        <Collapse in={open}>
          <div>
            <ul className="nav flex-column ms-4" id={link}>
              {subMenu?.map((sub, index) => {
                return (
                  <React.Fragment key={index}>
                    {sub?.subMenuItems?.length > 0 ? (
                      userPermissions?.includes(sub?.permission) ? (
                        <li
                          className="menu-item"
                          onClick={() => setOpenSubMenu(!openSubMenu)}
                        >
                          <SubMenuLinkItem
                            link={sub.link}
                            label={sub.label}
                            icon={sub.icon}
                            subMenu={sub.subMenuItems}
                            permission={sub?.permission}
                          />
                        </li>
                      ) : (
                        <StaticBlurLink
                          link={sub.link}
                          label={sub.label}
                          icon={sub.icon}
                          isSubMenuExist={true}
                        />
                      )
                    ) : (
                      renderLink(sub)
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </Collapse>
      ) : null}
    </>
  );
};

export default SubLinkItem;
