import { ERROR_MESS } from "../constant";
import { toasterContants } from "../constant/toasterContants"
import toaster from "./toaster"



export const ErrorHandlingMessage = (serverError) => {
    console.log("error", serverError)
    const { code, success, message, errors } = serverError?.response?.data;
    console.log('values',code,success,message)
    if(serverError?.response?.status === 422){
        return toaster(message, toasterContants.error)
    }

    if (!success && (code === 400 || code === 404 )) {
        return toaster(message, toasterContants.error)
    }

    if (!success && code === 500) {
        return toaster('Internal Server Error', toasterContants.error)
    }

    if (!success && code === 429) {
        return toaster('Too Many Requests', toasterContants.error)
    }

    return toaster(ERROR_MESS, toasterContants.error)

}
