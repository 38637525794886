import React from "react";
import { Link } from "react-router-dom";
import BreadcrumHomeLink from "./BreadcrumHomeLink";

const BreadcrumLink = ({ path, title, activeLink, isParentLabel }) => {
  return (
    <div>
      {path === "/" ? (
        <BreadcrumHomeLink path={path} />
      ) : (
        <div className={`  ${activeLink ? "area" : "home-arrow"}`}>
          {!activeLink ? (
            isParentLabel ? (
              <h6 className="text-capitalize ms-2 mb-0">
                {" "}
                <u>{`${title}`}</u> {" > "}{" "}
              </h6>
            ) : (
              <Link to={path}>
                <h6 className="text-capitalize ms-2 mb-0">
                  <u>{`${title}`}</u> {" > "}{" "}
                </h6>
              </Link>
            )
          ) : (
            <span> {`${decodeURI(title).toUpperCase()}`}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default BreadcrumLink;

// <div className={` arrow text-white text-center col-5 ms-n4`}>
//   <p className="pt-1">{title}</p>
// </div>
