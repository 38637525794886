import React,{useState, useEffect} from 'react'
import ReactApexChart from 'react-apexcharts'

export default function GroupedBarChart(props) {
    console.log("bar da",props);
    // const [chartData, setChartData] = useState(
    //     {
    //       titles : [],
    //       accepted: [],
    //       rejected: []
    //     }
    //   );
    
    const [barData , setBarData] = useState ({
            series: [
                {
                name: 'Acceptance Rate',
                type: 'bar',
                data: props?.data?.Accepted ? props?.data?.Accepted : 0  
                // {`${props?.data?.Accepted}`}
                // data: [44, 55, 41, 64, 22, 43, 21]
              }, {
                name: 'Rejection Rate',
                type: 'bar',
                data: props?.data?.Rejected ? props?.data?.Rejected : 0  
                // data: [53, 32, 33, 52, 13, 44, 32]
            }],  
            options: {
                chart: {
                type: 'bar',
                height: 430
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    position: 'top',
                  },
                }
              },
              dataLabels: {
                enabled: true,
                offsetX: 0,
                offsetY: -15,
                style: {
                  fontSize: '12px',
                  colors: ['#000']
                }
              },
              stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
              },
              tooltip: {
                shared: true,
                intersect: false,
              },
              xaxis: {
                categories:props?.data?.InspectorsName ? props?.data?.InspectorsName : "" 
                // categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
              },
            //   customLegendItems: [AcceptanceRate, RejectionRate]
            }
        }  
    )

    // const barData = {
    //     series: [
    //         {
    //         name: 'Acceptance Rate',
    //         type: 'bar',
    //         data: chartData?.accepted ? chartData?.accepted : 0  
    //         // data: [44, 55, 41, 64, 22, 43, 21]
    //       }, {
    //         name: 'Rejection Rate',
    //         type: 'bar',
    //         data: chartData?.rejected ? chartData?.rejected : 0  
    //         // data: [53, 32, 33, 52, 13, 44, 32]
    //     }],  
    //     options: {
    //         chart: {
    //         type: 'bar',
    //         height: 430
    //       },
    //       plotOptions: {
    //         bar: {
    //           horizontal: false,
    //           dataLabels: {
    //             position: 'top',
    //           },
    //         }
    //       },
    //       dataLabels: {
    //         enabled: true,
    //         offsetX: 0,
    //         offsetY: -15,
    //         style: {
    //           fontSize: '12px',
    //           colors: ['#000']
    //         }
    //       },
    //       stroke: {
    //         show: true,
    //         width: 1,
    //         colors: ['#fff']
    //       },
    //       tooltip: {
    //         shared: true,
    //         intersect: false,
    //       },
    //       xaxis: {
    //         categories:chartData?.titles ? chartData?.titles : "" 
    //         // categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
    //       },
    //     //   customLegendItems: [AcceptanceRate, RejectionRate]
    //     }
    // };


    // useEffect(() => {
    //     setChartData({
    //     titles: props?.data?.InspectorsName,
    //     accepted: props?.data?.Accepted,
    //     rejected: props?.data?.Rejected
    //     });
    // },[props?.chart])

        useEffect(() => {
            setBarData({
                series: [
                    {
                    name: 'Acceptance Rate',
                    type: 'bar',
                    data: props?.data?.Accepted ? props?.data?.Accepted : 0  
                    // data: [44, 55, 41, 64, 22, 43, 21]
                  }, {
                    name: 'Rejection Rate',
                    type: 'bar',
                    data: props?.data?.Rejected ? props?.data?.Rejected : 0  
                    // data: [53, 32, 33, 52, 13, 44, 32]
                }],  
                options: {
                    chart: {
                    type: 'bar',
                    height: 430
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      dataLabels: {
                        position: 'top',
                      },
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    offsetX: 0,
                    offsetY: -15,
                    style: {
                      fontSize: '12px',
                      colors: ['#000']
                    }
                  },
                  stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                  },
                  tooltip: {
                    shared: true,
                    intersect: false,
                  },
                  xaxis: {
                    categories:props?.data?.InspectorsName ? props?.data?.InspectorsName : "" 
                    // categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
                  },
                //   customLegendItems: [AcceptanceRate, RejectionRate]
                }
            }
        )
    },[props])
  return (
        <div className="row align-items-center">
            <div className="col-md-12">
                <div className="d-flex justify-content-around">
                    <h5
                    className="fs-3"
                    style={{ fontFamily: 'Rubik, sans-serif' }}
                    >
                        Acceptance vs Rejection Rate For Material : All
                    </h5>
                </div>
            </div>
            {barData?.series[0]?.data?.length > 0 ? (
            <div className="col-md-12 text-center" id="chart">
            <ReactApexChart
                style={{ overflowX: 'auto', overflowY: 'auto' }}
                options={barData?.options}
                series={barData?.series}
                type="bar"
                height="300"
                width="100%"
                />
            </div>
            ) : (
            null
            )}
        </div>
    )
}
