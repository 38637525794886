import React from "react";
import { API } from "../../../../config";
import DataTableWithExcel from "../../../common/DataTableWithExcel";
import IconButton from "@mui/material/IconButton";
import { BsEyeFill } from "react-icons/bs";
import MainBreadcrum from "../../../layout/MainBreadcrum";

const FEMAreport = () => {
  const [data, setData] = React.useState([]);
  const [info, setInfo] = React.useState();
  const [open, setOpen] = React.useState();
  const [status, setstatus] = React.useState("loading");

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handle = (e) => {
    handleOpen();
    setInfo(e);
  };

  console.log("asc979asc", data);

  const columnsData = React.useMemo(
    () => [
      {
        Header: "Id",
        disableFilters: true,
        accessor: "id",
      },

      {
        Header: "CREATED AT",
        disableFilters: true,
        accessor: "created_at",
      },
      {
        Header: "UPDATED AT",
        disableFilters: true,
        accessor: "updated_at",
      },
      {
        Header: "Link",
        disableFilters: true,
        accessor: "report_link",
        Cell: ({ value }) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            <BsEyeFill />
          </a>
        ),
      },
      /*  {
        Header: "Action",
        id: "action",
        disableFilters: true,
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              handle(row?.original);
            }}
          >
            <BsEyeFill />
          </IconButton>
        ),
      }, */
    ],
    []
  );

  const GetallForm = async () => {
    setstatus("loading");
    const res = await API.FEMA.getAll();
    setstatus("succeeded");
    setData(res?.data?.data);
  };

  React.useEffect(() => {
    GetallForm();
  }, []);
  return (
    <div>
      <div className="row">
        <MainBreadcrum
          name="User Filled Form"
          subName="Reports"
          links={[
            { path: "/", title: "" },
            {
              path: "/report/data-entry-form",
              title: "User Filled Form ",
              activeLink: true,
            },
          ]}
        />
      </div>
      <div className="mt-3">
        <div className="mb-5">
          <div className="card">
            <div className="card-body">
              <DataTableWithExcel
                status={status}
                columns={columnsData}
                data={data}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FEMAreport;
