import React from 'react';
import { NavLink } from 'react-router-dom';
import { ImTree } from 'react-icons/im';
import '../../../assets/css/main.css';

const StaticBlurLink = ({ link, label, icon, isSubMenuExist }) => {
  return (
    <NavLink
      onClick={(e) => e.preventDefault()}
      activeclassname="active"
      to={link}
      className={`menu-link blur_text ${isSubMenuExist ? 'align-middle menu-toggle' : null}`}
    >
      {icon.includes('svg') ? (
        <img
          src={icon}
          className="menu-icon tf-icons svg-icon"
          alt="sidebar menu icon"
        />
      ) : label.includes('Build Area') ? (
        <ImTree className="menu-icon tf-icons " />
      ) : (
        <i className={icon} />
      )}

      <span>{label}</span>
    </NavLink>
  );
};

export default StaticBlurLink;
