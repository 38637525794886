import axios from "axios";
// const axios = require("axios");
import { getCompantId, getToken, verifyToken } from "../utils";
// import axios from 'axios';
// axios.defaults.baseURL = 'http://localhost:1010/'
// axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
// export default axios;
class EAPI {
  constructor({ BaseUrl }) {
    this._BaseUrl = BaseUrl;
    if (verifyToken()) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${getToken()}`,
        companyId: getCompantId(),
      };
    }
    // //console.log("from service axios header : ", axios.defaults.headers.common);
  }

  settings = {
    companyId() {
      axios.defaults.headers.common = {
        companyId: getCompantId(),
        Authorization: `Bearer ${getToken()}`,
      };
      // //console.log(
      //   "from service axios header : ",
      //   axios.defaults.headers.common
      // );
    },
    setToken() {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${getToken()}`,
      };
      // // //console.log(
      //   "from service axios header : ",
      //   axios.defaults.headers.common
      // );
    },
  };
  userFilledForm = {
    getAll: async () =>
      await axios.get(`${this._BaseUrl}/user-approval-form`, {}),
  };

  FEMA = {
    getAll: async () =>
      await axios.get(`${this._BaseUrl}/email/report/fmea`, {}),
  };
  fiveWhy = {
    getAll: async () =>
      await axios.get(`${this._BaseUrl}/email/report/fivewhy`, {}),
  };

  print() {
    // // //console.log(this._BaseUrl);
  }

  inspectionPlan = {
    getOne: (id) => axios.get(`${this._BaseUrl}/inspection-plans/${id}`),

    getAll: ({ key, value }) => {
      let params;
      if (key === "page") {
        // // //console.log("key :", key);
        params = `?${key}=${value}`;
      } else {
        params = `?all=true`;
      }
      return axios.get(`${this._BaseUrl}/inspection-plans${params}`);
    },
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/inspection-plans`,
        data: formData,
      }),

    update: (id, formData) =>
      axios.put(`${this._BaseUrl}/inspection-plans/${id}`, formData),

    delete: (id) => axios.delete(`${this._BaseUrl}/inspection-plans/${id}`),
  };

  BuildArea = {
    getOne: (id) =>
      axios.get(`${this._BaseUrl}/build-area?buildMasterId=${id}`),
    getAll: ({ key, value }) => {
      let params;
      if (key === "page") {
        // // //console.log("key :", key);
        params = `?${key}=${value}`;
      } else {
        params = `?all=true`;
      }
      return axios.get(`${this._BaseUrl}/build-area-master${params}`);
    },
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/build-area`,
        data: formData,
      }),
    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/build-area/${id}`, toUpdate),

    addMaster: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/build-area-master`,
        data: formData,
      }),

    removeBuildAreaFile: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/buildarearemovemainnodefile`,
        data: formData,
      }),

    delete: (id) => axios.delete(`${this._BaseUrl}/build-area-master/${id}`),

    nodeDelete: (id) => axios.delete(`${this._BaseUrl}/build-area/${id}`),

    addBuildCondition: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/build-area-master`,
        data: formData,
      }),
  };

  step = {
    getOne: (id) => axios.get(`${this._BaseUrl}/steps/${id}`, {}),
    getAll: () => axios.get(`${this._BaseUrl}/steps`, {}),
    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/steps/${id}`, toUpdate),
    // update: (id, toUpdate, token) =>
    //   axios.put(`${this._BaseUrl}/vehicle/${id}`, toUpdate, {
    //     headers: { Authorization: `Bearer ${token}` },
    //   }),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/steps`,
        data: formData,
      }),
    delete: (id) => axios.delete(`${this._BaseUrl}/steps/${id}`),
  };

  QCPoint = {
    getOne: (id) => axios.get(`${this._BaseUrl}/qc-points/${id}`, {}),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/qc-points`,
        data: formData,
      }),

    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/qc-points/${id}`, toUpdate),

    updateCondition: async (id, toUpdate) =>
      await axios.put(
        `${this._BaseUrl}/qc-point/alert-condition/${id} `,
        toUpdate
      ),

    delete: (id) => axios.delete(`${this._BaseUrl}/qc-points/${id}`),

    addCheckCondition: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/qc-point/alert-condition`,
        data: formData,
      }),

    deleteCheckCondition: (id) =>
      axios.delete(`${this._BaseUrl}/qc-point/alert-condition/${id}`),
    getAllCheckConditions: (qcId) =>
      axios.get(
        `${this._BaseUrl}/qc-point/alert-condition?qcPointId=${qcId}`,
        {}
      ),
    reportEmails: () => axios.get(`${this._BaseUrl}/report-emails`),
  };

  workstations = {
    getOne: (id) => axios.get(`${this._BaseUrl}/workstations/${id}`, {}),
    getAll: () => axios.get(`${this._BaseUrl}/workstations`, {}),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/workstations`,
        data: formData,
      }),
    delete: (id) => axios.delete(`${this._BaseUrl}/workstations/${id}`, {}),

    update: (id, toUpdate) =>
      // // // //console.log("from api lib :", toUpdate);
      axios.put(`${this._BaseUrl}/workstations/${id}`, toUpdate, {}),
  };

  MaterialMaster = {
    getOne: (id) => axios.get(`${this._BaseUrl}/material-masters/${id}`, {}),
    getAll: ({ key, value }) => {
      let params;
      if (key === "page") {
        // // //console.log("key :", key);
        params = `?${key}=${value}`;
      } else {
        params = `?all=true`;
      }
      return axios.get(`${this._BaseUrl}/material-masters${params}`, {});
    },
    getAllPlants: () => {
      return axios.get(`${this._BaseUrl}/plant-materials`, {});
    },
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/material-masters`,
        data: formData,
      }),
    delete: (id) => axios.delete(`${this._BaseUrl}/material-masters/${id}`, {}),
    update: (id, toUpdate) =>
      // // // //console.log("from api lib :", toUpdate);
      axios.put(`${this._BaseUrl}/material-masters/${id}`, toUpdate, {}),
    syncMaterial: () => axios.get(`${this._BaseUrl}/material-masters/sync`, {}),
    // export: (id) =>
    //   // axios.get(`${this._BaseUrl}/sap/_excel/export/material-master`, {
    //     axios.get(`${this._BaseUrl}/export/material-master/${id}`, {
    //     headers: {
    //       "Content-Type":
    //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     },
    //     responseType: "arraybuffer",
    //   }),
    templateExport: () =>
      axios.get(`${this._BaseUrl}/sap/_excel/export/material-master-template`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      }),

    // templateExport: () =>
    //  fetch(`${this._BaseUrl}/sap/_excel/export/material-master-template`,{method: "GET", responseType: "arraybuffer"}).then((response) => {
    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }
    //   return response.arrayBuffer();
    // })
    // .then((data) => {
    //   // Convert the arrayBuffer to a Blob with the appropriate content type for Excel (.xlsx)
    //   const fileBlob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //   // Create a download link
    //   const downloadLink = document.createElement('a');
    //   downloadLink.href = URL.createObjectURL(fileBlob);
    //   // Replace 'your_file_name.xlsx' with the desired file name and extension (.xlsx)
    //   downloadLink.download = 'your_file_name.xlsx';
    //   // Append the link to the DOM
    //   document.body.appendChild(downloadLink);
    //   // Trigger the download
    //   downloadLink.click();
    //   // Clean up: remove the link from the DOM and revoke the Blob URL
    //   document.body.removeChild(downloadLink);
    //   URL.revokeObjectURL(downloadLink.href);
    // })
    // .catch((error) => {
    //   console.error('Error downloading Excel file:', error);
    // }),

    templateImport: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/sap/_excel/import/material-master`,
        data: formData,
      }),
  };

  Stripe = {
    getIntent: () => axios.get(`${this._BaseUrl}/stripe/setup-intent`),
    StripeCharge: (formdata) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/stripe/charge`,
        data: formdata,
      }),
    StripeChargeUpgrade: (formdata) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/stripe/chargeupgrade`,
        data: formdata,
      }),

    getPaymentMethods: () => axios.get(`${this._BaseUrl}/payment-methods`),
    addPaymentMethod: (formdata) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/payment-methods`,
        data: formdata,
      }),
    defaultPaymentMethod: (id) =>
      axios({
        method: "put",
        url: `${this._BaseUrl}/payment-methods/set-default/${id}`,
      }),
    CreateFreeUser: (formdata) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/stripe/charge/free`,
        data: formdata,
      }),
    // getPaymentMethods: () => axios.get(`${this._BaseUrl}/payment-methods`),
    // addPaymentMethod: (formdata) =>
    //   axios({
    //     method: "post",
    //     url: `${this._BaseUrl}/payment-methods`,
    //     data: formdata,
    //   }),
    // defaultPaymentMethod: (id) =>
    //   axios({
    //     method: "put",
    //     // url: `${this._BaseUrl}/payment-methods/set-default/${id}`,
    //     url: `${this._BaseUrl}/payment-methods/set-default-card/${id}`,
    //   }),
    renewSubscription: (data) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/subscriptions`,
        data: data,
      }),
    buySeats: (data) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/subscriptions/buy-extra-seats`,
        data: data,
      }),
    createCompany: (formdata) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/create-company`,
        data: formdata,
      }),
    verifyEmail: (email) =>
      axios({
        url: `${this._BaseUrl}/verify-company-email`,
        method: "post",
        data: email,
      }),
    // verifyEmail: (email) =>
    //   axios({
    //     method: "post",
    //     url: `${this._BaseUrl}/verify-company-email`,
    //     data: { contactPersonEmail: email },
    //   }),
  };
  OTP = {
    sentOTP: (email) => axios.get(`${this._BaseUrl}/otp?email=${email}`),
    verifyOTP: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/otp`,
        data: formData,
      }),
  };
  plant = {
    getOne: (id) => axios.get(`${this._BaseUrl}/plants/${id}`, {}),
    getAll: ({ key, value }) => {
      let params;
      if (key === "page") {
        // // //console.log("key :", key);
        params = `?${key}=${value}`;
      } else {
        params = `?all=true`;
      }
      return axios.get(`${this._BaseUrl}/plants${params}`, {});
    },

    update: (id, toUpdate, token) =>
      axios.put(`${this._BaseUrl}/plants/${id}`, toUpdate, {}),
    add: (formData, token) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/plants`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer ${key.token}`,
          // companyId: key.compnyId,
        },
      }),
    delete: (id) => axios.delete(`${this._BaseUrl}/plants/${id}`, {}),
  };

  subscriptionPlan = {
    getOne: (id) => axios.get(`${this._BaseUrl}/subscriptions/${id}`, {}),
    getAll: ({ key, value }) => {
      let params;
      if (key === "page") {
        // // //console.log("key :", key);
        params = `?${key}=${value}`;
      } else {
        params = `?all=true`;
      }
      return axios.get(`${this._BaseUrl}/subscriptions${params}`, {});
    },
    update: (id, toUpdate, token) =>
      axios.put(`${this._BaseUrl}/subscriptions/${id}`, toUpdate, {
        // headers: { Authorization: `Bearer ${token}` },
      }),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/subscriptions`,
        data: formData,
      }),

    delete: (id) => axios.delete(`${this._BaseUrl}/subscriptions/${id}`, {}),
  };

  file = {
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/file`,
        data: formData,
      }),
    upload: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/file`,
        data: formData,
      }),
  };

  plans = {
    getOne: (id) => axios.get(`${this._BaseUrl}/plans/${id}`, {}),
    getAll: (page) => axios.get(`${this._BaseUrl}/plans?page=${page}`, {}),

    update: (id, toUpdate, token) =>
      axios.put(`${this._BaseUrl}/plans/${id}`, toUpdate, {}),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/plans`,
        data: formData,
      }),

    delete: (id) => axios.delete(`${this._BaseUrl}/plans/${id}`, {}),
  };

  companyPlans = {
    getOne: (id) => axios.get(`${this._BaseUrl}/company-plans/${id}`),
    getAll: () => axios.get(`${this._BaseUrl}/company-plans`),
  };

  MaterialNoConfig = {
    getOne: (id) => axios.get(`${this._BaseUrl}/material-no-configs/${id}`, {}),
    getAll: ({ key, value }) => {
      let params;
      if (key === "page") {
        // // //console.log("key :", key);
        params = `?${key}=${value}`;
      } else {
        params = `?all=true`;
      }
      return axios.get(`${this._BaseUrl}/material-no-configs${params}`, {});
    },
    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/material-no-configs/${id}`, toUpdate, {}),

    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/material-no-configs`,
        data: formData,
      }),

    delete: (id) =>
      axios.delete(`${this._BaseUrl}/material-no-configs/${id}`, {}),
  };

  generalFormApi = {
    getgeneralFormApi: (data) =>
      axios({
        method: "post",
        url: "https://dev-backend.processintel.com/api/general-form/user-filled-detail ",
        data: data,
      }),
    getgeneralFormAttachment: (data) =>
      axios({
        method: "post",
        url: "https://dev-backend.processintel.com/api/general-form/form-media",
        data: data,
      }),
  };

  InspectionLot = {
    getOne: (id) => axios.get(`${this._BaseUrl}/inspection-lots/${id}`, {}),
    getInspectionPlan: (mid, pid, eflag) =>
      axios.get(
        `${this._BaseUrl}/getinspectionplanforiplot?MID=${mid}&PID=${pid}&EFLAG=${eflag}`,
        {}
      ),
    getAll: ({ key, value }) => {
      let params;
      if (key === "page") {
        // // //console.log("key :", key);
        params = `?${key}=${value}`;
      } else {
        params = `?all=true`;
      }

      return axios.get(`${this._BaseUrl}/inspection-lots${params}`, {});
    },
    getAllMaterial: (e) => axios.get(`${this._BaseUrl}/inspectionplans/${e}`),
    getAllInspectors: () => axios.get(`${this._BaseUrl}/getallinspectors`, {}),
    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/inspection-lots/${id}`, toUpdate, {}),

    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/inspection-lots`,
        data: formData,
      }),

    delete: (id) => axios.delete(`${this._BaseUrl}/inspection-lots/${id}`, {}),
    syncLots: () => axios.get(`${this._BaseUrl}/inspection-lots/sync`),
  };

  Roles = {
    getOne: (id) => axios.get(`${this._BaseUrl}/role/${id}`, {}),
    getAll: () => axios.get(`${this._BaseUrl}/role`, {}),

    update: (id, toUpdate) =>
      axios.put(
        `${this._BaseUrl}/role/${id}`,
        toUpdate
        // {
        //   headers: { Authorization: `Bearer ${key.token}`, companyId: 100000 },
        // }
      ),

    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/role`,
        data: formData,
      }),

    delete: (id) => axios.delete(`${this._BaseUrl}/role/${id}`, {}),
  };

  companyRoles = {
    getOne: (id) => axios.get(`${this._BaseUrl}/role/${id}`),
    getAll: () => axios.get(`${this._BaseUrl}/company/role`),

    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/company/role/${id}`, toUpdate),

    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/company/role`,
        data: formData,
      }),

    delete: (id) => axios.delete(`${this._BaseUrl}/company/role/${id}`),
  };

  Users = {
    getOne: (id) => axios.get(`${this._BaseUrl}/users/${id}`, {}),

    getAll: ({ key, value }) => {
      let params;
      if (key === "page") {
        // // //console.log("key :", key);
        params = `?${key}=${value}`;
      } else {
        params = `?all=true`;
      }
      return axios.get(`${this._BaseUrl}/users${params}`, {});
    },

    update: (id, toUpdate) => {
      console.log("aca0008c", id, toUpdate);
      axios.put(
        `${this._BaseUrl}/users/${id}`,
        toUpdate,

        {}
      );
    },

    passwordUpdate: (formdata) =>
      axios.post(`${this._BaseUrl}/company-user-password-update`, formdata),

    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/users`,
        data: formData,
      }),

    delete: (id) => axios.delete(`${this._BaseUrl}/users/${id}`, {}),
  };

  Permissions = {
    getAll: () => axios.get(`${this._BaseUrl}/permissions`),
    getAllAssigned: (id) =>
      axios.get(`${this._BaseUrl}/permissions/roles/${id}`),
    // getAllAssigned: (id) => axios.get(`${this._BaseUrl}/${id}/permissions`),

    assignNew: (id, formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/${id}/assign-permissions`,
        data: formData,
      }),
  };
  auth = {
    login: (data) => axios.post(`${this._BaseUrl}/login`, data),

    user: (token) => {
      return axios.get(`${this._BaseUrl}/auth-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    getallRoles: () => axios.get(`${this._BaseUrl}/userroles`, {}),

    passwordUpdate: (data) =>
      axios.post(`${this._BaseUrl}/auth-user-password-update`, data),

    forgotPassword: (data) =>
      axios.post(`${this._BaseUrl}/forgot-password`, data),

    verifyResetCode: (data) =>
      axios.post(`${this._BaseUrl}/verify-reset-code`, data),

    resetPassword: (data) =>
      axios.post(`${this._BaseUrl}/reset-password`, data),
  };

  CompanyManagement = {
    getOne: (id) => axios.get(`${this._BaseUrl}/companies/${id}`),
    getAll: (page) => axios.get(`${this._BaseUrl}/companies?page=${page}`, {}),

    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/companies/${id}`, toUpdate, {}),

    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/companies`,
        data: formData,
      }),

    delete: (id) => axios.delete(`${this._BaseUrl}/companies/${id}`, {}),
  };

  rejectedReasons = {
    getOne: (id) => axios.get(`${this._BaseUrl}/rejected-reasons/${id}`, {}),
    getAll: () => axios.get(`${this._BaseUrl}/rejected-reasons`, {}),
    getAllChilds: () => axios.get(`${this._BaseUrl}/child-rejected-reasons`),

    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/rejected-reasons/${id}`, toUpdate, {}),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/rejected-reasons`,
        data: formData,
      }),
    delete: (id) => axios.delete(`${this._BaseUrl}/rejected-reasons/${id}`, {}),
  };

  releaseLog = {
    getAll: () =>
      axios.get(`${this._BaseUrl}/app/all-inspection-lot-inspector-lot`, {}),
  };

  Groups = {
    getOne: (id) => axios.get(`${this._BaseUrl}/groups/${id}`, {}),
    getAll: () => axios.get(`${this._BaseUrl}/groups`, {}),

    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/groups/${id}`, toUpdate, {}),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/groups`,
        data: formData,
      }),
    delete: (id) => axios.delete(`${this._BaseUrl}/groups/${id}`, {}),
  };

  WorkFlowGroup = {
    add: (data) => axios.post(`${this._BaseUrl}/workflow-approval-group`, data),
    getAll: (data) =>
      axios.get(`${this._BaseUrl}/workflow-approval-group`, data),
    getMy: () => axios.get(`${this._BaseUrl}/workflow-approval-group-user`, {}),
    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/workflow-approval-group/${id}`, toUpdate),
    get: (Id) => axios.get(`${this._BaseUrl}/workflow-approval-group/${Id}`),
    delete: (id) =>
      axios.delete(`${this._BaseUrl}/workflow-approval-group/${id}`),
  };

  Editform = {
    update: async (toUpdate) =>
      await axios.post(
        `${this._BaseUrl}/update-workflow-form-builder`,
        toUpdate
      ),
  };
  form = {
    getAllFormsApproval: () => axios.get(`${this._BaseUrl}/approval-form`, {}),
    getAllForms: () => axios.get(`${this._BaseUrl}/workflow-form-builders`, {}),

    delete: (id) => {
      // //console.log("id is", id);
      return axios.delete(`${this._BaseUrl}/workflow-form-builders/${id}`, {});
    },

    add: (data) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/workflow-form-builders`,
        data: data,
      }),
    getAll: () =>
      axios({
        method: "get",
        url: `${this._BaseUrl}/workflow-approval-group`,
      }),
    getEnablesForms: () =>
      axios({
        method: "get",
        url: `${this._BaseUrl}/enabled-forms`,
      }),

    getFixForms: () =>
      axios({
        method: "get",
        url: `${this._BaseUrl}/fix-forms`,
      }),

    approvalTrail: {
      get: (Id) => axios.get(`${this._BaseUrl}/workflow-approval/${Id}`),
    },

    getEnablesFormsDetails: (id) => {
      let url = `${this._BaseUrl}/enabled-forms/detail/${id}`;
      // //console.log("hahahha ---  ", url);
      return axios({
        method: "get",
        url,
      });
    },

    AddFormFields: (data) => {
      let url = `${this._BaseUrl}/workflow-form-builder-fields`;
      return axios({
        method: "post",
        url,
        data: data,
      });
    },
    getAllFormFields: (id) => {
      let url = `${this._BaseUrl}/workflow-form-builder-fields?${id}&workflowFormBuilderId=${id}`;
      return axios({
        method: "get",
        url,
      });
    },

    deleteFormField: (id) => {
      let url = `${this._BaseUrl}/workflow-form-builder-fields/${id}`;
      // //console.log("");
      return axios({
        method: "delete",
        url,
      });
    },

    updateFormField: (data, id) => {
      let url = `${this._BaseUrl}/workflow-form-builder-fields/${id}`;
      // //console.log("");
      return axios({
        method: "put",
        url,
        data: data,
      });
    },

    updateForm: (data, id) => {
      let url = `${this._BaseUrl}/workflow-form-builders/${id}`;
      // //console.log("");
      return axios({
        method: "put",
        url,
        data: data,
      });
    },

    workFlowApprovalForm: (data) => {
      let url = `${this._BaseUrl}/workflow-approval`;
      return axios({
        method: "post",
        url,
        data: data,
      });
    },

    saveEnableForm: (data) => {
      let url = `${this._BaseUrl}/workflow-save-form`;
      return axios({
        method: "post",
        url,
        data: data,
      });
    },
    saveEntryForm: (data) => {
      return axios.post(`${this._BaseUrl}/app/general-form/save-form`, data);
    },
  };

  report = {
    fema: {
      getAll: (id) => axios.get(`${this._BaseUrl}/reports/fema/${id}`),

      update: (id, toUpdate) =>
        axios.put(`${this._BaseUrl}/reports/fema/${id}`, toUpdate),
    },

    getFishBone: (num) =>
      axios.get(`${this._BaseUrl}/reports/fish-bone?${num}`),
    getFishBoneMaterial: () =>
      axios.get(`${this._BaseUrl}/reports/fish-bone/materials`),
    fiveWhy: {
      getAll: (id) => axios.get(`${this._BaseUrl}/reports/five-why/${id}`),
      update: (id, toUpdate) =>
        axios.put(`${this._BaseUrl}/reports/five-why/${id}`, toUpdate),
    },
  };

  Measurement = {
    getOne: (id) => axios.get(`${this._BaseUrl}/measurement-units/${id}`, {}),
    getAll: () => axios.get(`${this._BaseUrl}/measurement-units`, {}),

    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/measurement-units/${id}`, toUpdate, {}),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/measurement-units`,
        data: formData,
      }),
    delete: (id) =>
      axios.delete(`${this._BaseUrl}/measurement-units/${id}`, {}),
  };

  SensorType = {
    getOne: (id) => axios.get(`${this._BaseUrl}/sensor-types/${id}`, {}),
    getAll: () => axios.get(`${this._BaseUrl}/sensor-types`, {}),

    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/sensor-types/${id}`, toUpdate, {}),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/sensor-types`,
        data: formData,
      }),
    delete: (id) => axios.delete(`${this._BaseUrl}/sensor-types/${id}`, {}),
  };

  Sensor = {
    getOne: (id) => axios.get(`${this._BaseUrl}/sensors/${id}`, {}),
    getAll: () => axios.get(`${this._BaseUrl}/sensors`, {}),

    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/sensors/${id}`, toUpdate, {}),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/sensors`,
        data: formData,
      }),
    delete: (id) => axios.delete(`${this._BaseUrl}/sensors/${id}`, {}),
  };

  WorkflowGroup = {
    getOne: (id) =>
      axios.get(`${this._BaseUrl}/workflow-approval-group/${id}`, {}),
    getAll: () => axios.get(`${this._BaseUrl}/workflow-approval-group`, {}),

    update: (id, toUpdate) =>
      axios.put(`${this._BaseUrl}/workflow-approval-group/${id}`, toUpdate, {}),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/workflow-approval-group`,
        data: formData,
      }),
  };

  WorkflowApproval = {
    getOne: (id, userId) =>
      axios.get(`${this._BaseUrl}/workflow-approval/${id}?.${userId}`, {}),
    getAll: (userId) =>
      axios.get(`${this._BaseUrl}/workflow-approval?.${userId}`, {}),

    update: (id, toUpdate) =>
      axios.put(
        `${this._BaseUrl}/update-workflow-approval-status/${id}`,
        toUpdate,
        {}
      ),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/workflow-approval`,
        data: formData,
      }),
  };

  WorkflowBuilder = {
    getOne: (id) =>
      axios.get(`${this._BaseUrl}/workflow-form-builders/${id}`, {}),
    getAll: () =>
      axios.get(
        `${this._BaseUrl}/workflow-form-builders?isWorkflowEnabled=0`,
        {}
      ),

    update: (id, toUpdate) =>
      axios.put(
        `${this._BaseUrl}/update-workflow-approval-status/${id}`,
        toUpdate,
        {}
      ),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/workflow-form-builders`,
        data: formData,
      }),
    delete: (id) =>
      axios.delete(`${this._BaseUrl}/workflow-form-builders/${id}`, {}),
  };
  RegistrationConsent = {
    updateAndCreate: (toUpdate) =>
      axios.post(`${this._BaseUrl}/registration-consent`, toUpdate),
    get: () => axios.get(`${this._BaseUrl}/registration-consent`),
  };
  catogries = {
    getAll: () => axios.get(`${this._BaseUrl}/workflow-approval-category`),
    delete: (id) =>
      axios.delete(`${this._BaseUrl}/workflow-approval-category/${id}`),
    deleteChild: (id) =>
      axios.get(`${this._BaseUrl}/workflow-approval-category/child/${id}`),
    getChildren: (id) =>
      axios.get(`${this._BaseUrl}/workflow-approval-category/${id}`),
    add: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/workflow-approval-category`,
        data: formData,
      }),
    update: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/workflow-approval-category/childupdate/${formData?.id}`,
        data: formData,
      }),
    updateCat1: async (formData) =>
      axios({
        method: "put",
        url: `${this._BaseUrl}/workflow-approval-category/${formData?.id}`,
        data: await formData,
      }),
  };

  AdminLogs = {
    getAll: (page) =>
      axios.get(`${this._BaseUrl}/login-logs?page=${page}?userId=10`),
  };

  generalForm = {
    getAllForms: async () => await axios.get(`${this._BaseUrl}/general-form`),
    getAllFormsFilledBy: (id) =>
      axios.get(`${this._BaseUrl}/general-form/data/${id}`),
    getFormDetail: (id) =>
      axios.get(`${this._BaseUrl}/general-form/detail/${id}`),
    postGeneralFoam: async (formData) => {
      await axios({
        method: "post",
        url: `${this._BaseUrl}/general-form/user-filled-detail`,
        data: formData,
      });
    },
  };

  inspectionLotReport = {
    getInspectionLotReport: () =>
      axios.get(`${this._BaseUrl}/reports/inspection-lot?all=true`),
    getStepsReport: (id, flag) =>
      axios.get(
        `${this._BaseUrl}/reports/inspection-lot/${id}/event/${flag}/steps`
      ),
    getQcPointReport: (id, stepId) =>
      axios.get(
        `${this._BaseUrl}/reports/inspection-lot/${id}/step/${stepId}/qc-points`
      ),
  };

  graphs = {
    stepData: (formData) =>
      axios({
        method: "post",
        url: `${this._BaseUrl}/graph/steps`,
        data: formData,
      }),
    histogram: {
      qualitative: (formData) =>
        axios({
          method: "post",
          url: `${this._BaseUrl}/graph/qualitative`,
          data: formData,
        }),
      quantitative: (formData) =>
        axios({
          method: "post",
          url: `${this._BaseUrl}/graph/quantitative`,
          data: formData,
        }),
    },

    dashboard: (date) => axios.get(`${this._BaseUrl}/dashboard?month=${date}`),

    steps: (id) => axios.get(`${this._BaseUrl}/steps/inspection-plan/${id}`),
    inspectionLot: (data) =>
      axios.post(`${this._BaseUrl}/graph/inspection-lot`, data),
    qcPoints: (id, boo) =>
      axios.get(`${this._BaseUrl}/qc-points/step/${id}?quantitative=${boo}`),
    inspectors: () => axios.get(`${this._BaseUrl}/user/inspector`),
    inspectionLotGraph: (data) =>
      axios.post(`${this._BaseUrl}/graph/inspection-lot`, data),
    defectInspectionPlan: (data) =>
      axios.post(`${this._BaseUrl}/graph/defects`, data),

    //new dashboard api's

    //top 5 inspectors
    topFiveInspetors: (data) =>
      axios.post(`${this._BaseUrl}/material-top-inspectors`, data),

    // Top 5 plans with maximum inspection lots
    topFivePlansWithMaxLots: () => axios.get(`${this._BaseUrl}/top-five-il`),

    //Inspectors for Material and Status
    topFivePlans: (data) =>
      axios.post(`${this._BaseUrl}/top-five-il-for-meterial`, data),

    //Top 10 Rejected Lots by Inspection Plan
    topTenRejectedLots: (data) =>
      axios.post(`${this._BaseUrl}/top-rejected-lots`, data),

    //Acceptance vs Rejection Rate For Material
    acceptanceRejectionRate: (data) =>
      axios.post(`${this._BaseUrl}/accepted-vs-rejected`, data),

    //Inspection Lot Status
    inspectionLotStatus: (data) =>
      axios.post(`${this._BaseUrl}/inspection-lot-status`, data),

    //control limit
    controlChart: (data) => axios.post(`${this._BaseUrl}/control-chart`, data),
  };

  TransactionHistory = {
    list: () => axios.get(`${this._BaseUrl}/gateway-payments?all=true`),
    // getStepsReport: (id, flag) =>
    //   axios.get(
    //     `${this._BaseUrl}/reports/inspection-lot/${id}/event/${flag}/steps`
    //   ),
    // getQcPointReport: (id, stepId) =>
    //   axios.get(
    //     `${this._BaseUrl}/reports/inspection-lot/${id}/step/${stepId}/qc-points`
    //   ),
  };
}

export default EAPI;

// drive = {
//   getAll: () => axios.get(`${this._BaseUrl}/drive`),
//   add: (Data, token) =>
//     axios.post(`${this._BaseUrl}/drive`, Data, {
//       headers: { Authorization: `Bearer ${token}` },
//     }),
//   delete: (id, token) =>
//     axios.delete(`${this._BaseUrl}/drive/${id}`, {
//       headers: { Authorization: `Bearer ${token}` },
//     }),
// };

// auth = {
//   login: (data) => axios.post(`${this._BaseUrl}/auth/login`, data),
//   register: (data) => axios.post(`${this._BaseUrl}/auth/register`, data),
//   forgotPassword: (data) => axios.post(`${this._BaseUrl}/auth/forgot`, data),
//   resetpasswod: (data) =>
//     axios.post(`${this._BaseUrl}/auth/resetpasswod`, data),
// };

// state = {
//   getAll: (token) =>
//     axios.get(`${this._BaseUrl}/stat`, {
//       headers: { Authorization: `Bearer ${token}` },
//     }),
// };
