import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllPlans } from "../../../../Redux/features/PlansSlice";
import {
  buyExtraSeats,
  getPaymentMethods,
  renewPlan,
} from "../../../../Redux/features/StripeSlice";
import { getCompantId } from "../../../../utils";

const RenewPlan = () => {
  let totalAmount = 0;
  const [values, setValues] = useState({
    planId: "101",
    planName: "Standard",
    planType: "monthly",
    noOfUsers: "1",
    amount: totalAmount,
  });

  const dispatch = useDispatch();
  //get value from url query
  const urlParams = new URLSearchParams(window.location.search);
  const seats = urlParams.get("seats");

  const { Plans, status } = useSelector((state) => state.Plan);

  useEffect(() => {
    dispatch(getAllPlans());
  }, [dispatch]);

  //sort Plans alphabetically by name
  const sortedPlans = Plans.filter((plan) => plan.name === "Standard");
  const companyId = getCompantId();
  const navigate = useNavigate();

  useEffect(() => {
    setValues({
      ...values,
      planId: sortedPlans?.[0]?.id,
      planName: sortedPlans?.[0]?.name,
      amount: totalAmount,
    });
  }, [status, values.noOfUsers]);

  useEffect(() => {
    dispatch(getPaymentMethods(navigate));
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (seats) {
      const data = {
        noOfUsers: values.noOfUsers,
        amount: totalAmount,
      };
      dispatch(buyExtraSeats(data));
    } else {
      dispatch(renewPlan({ data: values, Id: companyId }));
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center container-p-y">
        <div className="py-4">
          <div style={{ width: "550px" }} className="card px-5">
            <div className="card-body text-center">
              <div className="app-brand justify-content-center mb-0">
                <a
                  tabIndex="8"
                  href="index.html"
                  className="app-brand-link gap-2"
                >
                  <span
                    style={{ height: "70px" }}
                    className="app-brand-logo demo w-100"
                  >
                    <img
                      height={"120rem"}
                      width={"240rem"}
                      src="\assets\img\Process-Intel.svg"
                      alt="Logo"
                    />
                  </span>
                  {/* <span className="app-brand-text demo h3 mb-0 fw-bold">
                    Frest
                  </span> */}
                </a>
              </div>
              <p
                style={{ fontSize: "20px" }}
                className="text-primary text-center fw-bold mt-4 mb-5"
              >
                Plan Renew Form
              </p>
              {!seats ? (
                <div className="d-flex justify-content-end align-items-start">
                  <div
                    className={`mt-1 ${
                      values.planType === "monthly" ? "fw-bold" : ""
                    }`}
                  >
                    Monthly
                  </div>
                  <label className="switch switch-lg text-center mb-2 me-4 pe-5 ms-2">
                    <input
                      type="checkbox"
                      className="switch-input switch-input2"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          planType: e.target.checked ? "yearly" : "monthly",
                        });
                      }}
                      checked={values.planType === "yearly" ? true : false}
                    />
                    <span
                      style={{ width: "60px", height: "30px" }}
                      className="switch-toggle-slider"
                    ></span>
                  </label>
                  <div
                    className={`mt-1 ${
                      values.planType !== "monthly" ? "fw-bold" : ""
                    }`}
                  >
                    Annual
                  </div>
                </div>
              ) : null}
              <br />
              <form
                className="my-3  fv-plugins-bootstrap5 fv-plugins-framework text-start"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="mb-3 fv-plugins-icon-container">
                  <div
                    style={{ marginTop: "-2rem" }}
                    className="row ms-1 align-items-center border border-primary rounded-3"
                  >
                    <div className="col text-center">
                      <p style={{ fontSize: "15px" }} className="mb-0">
                        <span>
                          {values.planType === "monthly"
                            ? `$${sortedPlans?.[0]?.userPricePerMonth}/m x ` +
                              values.noOfUsers
                            : `$${sortedPlans?.[0]?.userPricePerYear}/m x 12 x ` +
                              values.noOfUsers}{" "}
                          = $
                          {values.planType === "monthly"
                            ? sortedPlans?.[0]?.userPricePerMonth *
                              parseInt(values.noOfUsers)
                              ? Math.round(
                                  sortedPlans?.[0]?.userPricePerMonth *
                                    parseInt(values.noOfUsers) *
                                    100
                                ) / 100
                              : 0
                            : sortedPlans?.[0]?.userPricePerYear *
                              parseInt(values.noOfUsers)
                            ? (Math.round(
                                sortedPlans?.[0]?.userPricePerYear *
                                  parseInt(values.noOfUsers) *
                                  100
                              ) /
                                100) *
                              12
                            : 0}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="fv-plugins-icon-container">
                      <label
                        tabIndex={-1}
                        htmlFor="NoOfUsers"
                        className="form-label fw-bold text-capitalize"
                      >
                        No. of Users
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="NoOfUsers"
                        name="noOfUsers"
                        min="1"
                        value={values.noOfUsers}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            noOfUsers: e.target.value,
                          });
                        }}
                        placeholder="Enter Users"
                      />
                      <div className="fv-plugins-message-container invalid-feedback" />
                    </div>
                  </div>
                </div>
                <div className="mb-3 d-flex justify-content-end">
                  <button className="btn btn-primary d-grid px-5" type="submit">
                    Buy
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenewPlan;
