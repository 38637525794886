import React from "react";
import Modal from "react-bootstrap/Modal";

export default function ModalPopup(props) {
  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        size={props.size !== "" ? props.size : "lx"}
        fullscreen={props?.isFullScreen ? props?.isFullScreen : false}
        show={props?.isShow}
        dialogClassName={`common_popup_content `}
        onHide={props?.closePopup}
        centered
        aria-labelledby="common_popup"
        contentClassName={props?.contentClassess}
        // animation={false}
      >
        <Modal.Header
          closeButton
          className={`border-bottom pb-1 ${props?.headerClasses}`}
        >
          <Modal.Title id="common_popup">
            {props?.title ? props?.title : "Modal"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={`d-flex align-items-center justify-content-center w-auto ${props?.bodyClasses}`}
        >
          {props?.children}
        </Modal.Body>
      </Modal>
    </>
  );
}
