import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { handelState } from "../../../../utils/handelStates";
import Spinner from "../../../common/Spinner";
import MainBreadcrum from "../../../layout/MainBreadcrum";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import {
  addCategory,
  getAllCatogries,
} from "../../../../Redux/features/CategorySlice";
import ButtonLoader from "../../../common/ButtonLoader";

// import ToggleButton from "react-toggle-button";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";

const CreateApprovalCategories = () => {
  // const navigate = useNavigate();

  // const initState = {
  //   name: "",
  //   parentId: null,
  // };

  // const [rejectionData, setRejectionData] = useState({
  //   code: "",
  //   reason: "",
  //   parentId: null,
  // });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initState = {
    name: "",
    parentId: null,

    childName: [""], // Add a new state for dynamic fields
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Category Name is required"),
    childName: Yup.array().of(
      Yup.string().required("Dynamic Field is required", (fields) => {
        return fields.some((field) => field.trim() !== "");
      })
    ),
  });

  const addDynamicField = (values) => {
    const updatedFields = [...values.childName, ""];

    return { ...values, childName: updatedFields };
  };
  console.log("acac8008ac", initState.childName);

  const {
    Catogries: { allCategories, status, parentCategoryID },
  } = useSelector((state) => state);
  useEffect(() => {
    dispatch(getAllCatogries());
  }, [dispatch]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(addRejectedReasonsData(rejectionData));
  //   navigate("/rejection-reasons");
  // };

  // if (status === "loading") {
  //   return <Spinner />;
  // }

  return (
    <>
      <div className="row">
        <MainBreadcrum
          name="Approval Category"
          subName=""
          links={[
            { path: "", title: "Forms and Flows", isParentLabel: true },
            { path: "/approval-categories", title: "Approval Category" },
            { path: "", title: "Create", activeLink: true },
          ]}
        />
      </div>
      {status === "loading" ? (
        <Spinner />
      ) : (
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <Formik
                  initialValues={initState}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    dispatch(
                      addCategory({
                        values: {
                          ...values,
                          parentId: parentCategoryID,
                        },
                        navigate,
                      })
                    );
                  }}
                >
                  {({ errors, values, touched, handleBlur, setFieldValue }) => (
                    <Form>
                      <div className="mb-3 row">
                        <div
                          className="col-lg-2 col-sm-12 col-md-12"
                          style={{ display: "flex", whiteSpace: "nowrap" }}
                        >
                          <div>
                            <label
                              className="col-form-label col-sm-2"
                              htmlFor="name"
                              style={{
                                paddingTop: "0%",
                                paddingRight: "1px",
                              }}
                            >
                              Category Name:
                            </label>
                          </div>
                          <div>
                            <span className="text-danger"> *</span>
                          </div>
                        </div>

                        <div className="col-lg-10 col-sm-12 col-md-12">
                          <Field
                            name="name"
                            type="text"
                            className={`form-control ${
                              errors.name && touched.name ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            placeholder="Categories"
                          />
                          <ErrorMessage
                            name="name"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                        <br />
                        <br />
                        <br />
                        <div>
                          {/* Render dynamic fields */}
                          {values.childName.map((field, index) => (
                            <div key={index} className="row mb-3">
                              <div
                                className="col-lg-2 col-sm-12 col-md-12"
                                style={{
                                  display: "flex",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <label
                                  className="col-form-label col-sm-2"
                                  htmlFor={`childName[${index}]`}
                                  style={{
                                    paddingTop: "0%",
                                    paddingRight: "1px",
                                  }}
                                >
                                  SubCategory{index + 1}:
                                </label>
                                <span className="text-danger"> *</span>
                              </div>

                              <div className="col-lg-9 col-sm-12 col-md-12">
                                <Field
                                  name={`childName[${index}]`}
                                  type="text"
                                  className={`form-control ${
                                    errors.childName &&
                                    errors.childName[index] &&
                                    touched.childName &&
                                    touched.childName[index]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onBlur={handleBlur}
                                  placeholder={`Sub-Category Field ${
                                    index + 1
                                  }`}
                                />
                                <ErrorMessage
                                  name={`childName[${index}]`}
                                  component="span"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-lg-1 col-sm-12 col-md-12">
                                {/*  // <button
                                //   onClick={() =>
                                //     setFieldValue(
                                //       "childName",
                                //       values.childName.filter(
                                //         (_, i) => i !== index
                                //       )
                                //     )
                                //   }
                                // >
                                //   <DeleteIcon />
                                // </button> */}
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    setFieldValue(
                                      "childName",
                                      values.childName.filter(
                                        (_, i) => i !== index
                                      )
                                    )
                                  }
                                  disabled={values?.childName?.length === 1}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </div>
                          ))}
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                              setFieldValue(
                                "childName",
                                addDynamicField(values).childName
                              )
                            }
                          >
                            Add Another Sub-Category
                          </button>
                        </div>
                      </div>

                      {/* The rest of your form content */}
                      <div className="row">
                        <div className="col-12 align-self-center text-center">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={status === "loading"}
                          >
                            {status === "loading" ? <ButtonLoader /> : "Save"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* <div className="col-md-6"></div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateApprovalCategories;
