import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "95%",
  overflow: "scroll",
};
const FormDetailModal = ({ open, handleClose, response }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton aria-label="close">
              <CancelIcon onClick={handleClose} />
            </IconButton>
          </Box>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3 row">
                    <div
                      className="col-lg-3 col-sm-12 col-md-12"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      <div>
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="title"
                          style={{ paddingTop: "0%", paddingRight: "1px" }}
                        >
                          From
                        </label>
                      </div>
                      <div>
                        <span className="fs-6 text-danger"> * </span>
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-12 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded"
                        id="title"
                        readOnly
                        value={response?.from_user?.username}
                        placeholder="From"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3 row">
                    <div
                      className="col-lg-3 col-sm-12 col-md-12"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      <div>
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="title"
                          style={{ paddingTop: "0%", paddingRight: "1px" }}
                        >
                          To
                        </label>
                      </div>
                      <div>
                        <span className="fs-6 text-danger"> * </span>
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-12 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded"
                        id="title"
                        readOnly
                        value={response?.to}
                        placeholder="From"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3 row">
                    <div
                      className="col-lg-3 col-sm-12 col-md-12"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      <div>
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="title"
                          style={{ paddingTop: "0%", paddingRight: "1px" }}
                        >
                          Title
                        </label>
                      </div>
                      <div>
                        <span className="fs-6 text-danger"> * </span>
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-12 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded"
                        id="title"
                        readOnly
                        value={response?.title}
                        placeholder="From"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3 row">
                    <div
                      className="col-lg-3 col-sm-12 col-md-12"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      <div>
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="title"
                          style={{ paddingTop: "0%", paddingRight: "1px" }}
                        >
                          Amount
                        </label>
                      </div>
                      <div>
                        <span className="fs-6 text-danger"> * </span>
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-12 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded"
                        id="title"
                        readOnly
                        value={response?.amount}
                        placeholder="Amount"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3 row">
                    <div
                      className="col-lg-3 col-sm-12 col-md-12"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      <div>
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="title"
                          style={{ paddingTop: "0%", paddingRight: "1px" }}
                        >
                          Unit
                        </label>
                      </div>
                      <div>
                        <span className="fs-6 text-danger"> * </span>
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-12 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded"
                        id="title"
                        readOnly
                        value={response?.unit_amount}
                        placeholder="Unit"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3 row">
                    <div
                      className="col-lg-3 col-sm-12 col-md-12"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      <div>
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="title"
                          style={{ paddingTop: "0%", paddingRight: "1px" }}
                        >
                          Approval Category
                        </label>
                      </div>
                      <div>
                        <span className="fs-6 text-danger"> * </span>
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-12 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded"
                        id="title"
                        readOnly
                        value={response?.workflow_approval_category?.name}
                        placeholder="From"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3 row">
                    <div
                      className="col-lg-3 col-sm-12 col-md-12"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      <div>
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="title"
                          style={{ paddingTop: "0%", paddingRight: "1px" }}
                        >
                          Approval Group
                        </label>
                      </div>
                      <div>
                        <span className="fs-6 text-danger"> * </span>
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-12 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded"
                        id="title"
                        readOnly
                        value={response?.workflow_approval_group?.name}
                        placeholder="From"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3 row">
                    <div
                      className="col-lg-3 col-sm-12 col-md-12"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      <div>
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="title"
                          style={{ paddingTop: "0%", paddingRight: "1px" }}
                        >
                          Approval Group
                        </label>
                      </div>
                      <div>
                        <span className="fs-6 text-danger"> * </span>
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-12 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded"
                        id="title"
                        readOnly
                        value={response?.workflow_approval_group?.name}
                        placeholder="From"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3 row">
                    <div
                      className="col-lg-3 col-sm-12 col-md-12"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      <div>
                        <label
                          className="col-form-label col-sm-2"
                          htmlFor="title"
                          style={{ paddingTop: "0%", paddingRight: "1px" }}
                        >
                          Details
                        </label>
                      </div>
                      <div>
                        <span className="fs-6 text-danger"> * </span>
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-12 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded"
                        id="title"
                        readOnly
                        value={response?.detail}
                        placeholder="Details"
                      />
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {response?.workflow_approval_documents?.map((item, index) => (
                    <div key={index} style={{ margin: "5px", width: "130px" }}>
                      {item.extension === "png" || item.extension === "jpg" ? (
                        <img
                          src={item.url}
                          alt={`Images ${index}`}
                          style={{ height: "130px", width: "120px" }}
                        />
                      ) : (
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.name}
                        </a>
                      )}
                    </div>
                  ))}
                </div>

                <div>
                  <br />

                  <a
                    href={`${process.env.REACT_APP_BASE_API_URL}/download-user-approval-form/${response?.id}`}
                    className="btn btn-primary"
                    download
                  >
                    Export
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FormDetailModal;
